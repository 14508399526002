export const BASE_URL = 'https://chat-widget.stringee.com';
// 'http://localhost:3001'
export const API_STRINGEE_COM_BASE_URL = 'https://api.stringee.com';
// 'http://api.stringee.local';
export const API_STRINGEEX_COM_BASE_URL = 'https://api.stringeex.com';
// 'http://api.stringeex.local';
// StringeeClient lấy server addresses
// từ api.stringee.com không cấu hình tĩnh địa chỉ stringee server nữa
export const STRINGEE_SERVER_ADDRS = [];

export const STRINGEEX_URL_DOMAIN = 'https://stringeex.com';
// 'https://stringeex.com';
export const DEFAULT_AGENT_AVATAR = 'https://v2.stringee.com/stringeexchat/sdk/product/css/assets/avatar.svg';
// 'https://v2.stringee.com/stringeexchat/sdk/product/css/assets/avatar.svg';
