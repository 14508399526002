import {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CircularProgress, Container, Grid} from "@mui/material";
import {selectAuthUser, setAuthUser} from "../../store/chatAuthUser";
import {selectStringeeClient, setStringeeClient} from "../../store/chatStringeeClient";
import {setChatCustomerAccessToken, setPublicApiStringee} from "../../store/chatGeneral";
import {CHAT_CUSTOMER_WIDGET_KEY} from "../../common/sample";
import {apiStringeeX} from "../../api/ApiStringeeX";
import {setConnected} from "../../store/chatbox";
import InfiniteScroll from "react-infinite-scroll-component";
import ConversationMessageList from "../../common_components/chats/messages/ConversationMessageList";
import {selectConversationMessageList, setConversationMessageList} from "../../store/conversationMessageList";
import {selectChatConversationSelected, setConversationSelected} from "../../store/chatConversationSelected";

export default function PastChat(props) {
    const dispatch = useDispatch();
    const authUser = useSelector(selectAuthUser);
    const conversationMessageList = useSelector(selectConversationMessageList);
    const chatConversationSelected = useSelector(selectChatConversationSelected);
    const client = useSelector(selectStringeeClient);

    const scrollableDivContentConvRef = useRef(null);
    const chatConversationSelectedIdRef = useRef(null);
    const participantListMap = useRef({});
    const [hasMore, setHasMore] = useState(true);

    const StringeeChatWidget = window.parent.StringeeChatWidget || {};
    const StringeeServiceType = window.StringeeServiceType;
    const StringeeChat2 = window.StringeeChat2;
    const msgLimit = 50;

    useEffect(() => {
        if (StringeeChatWidget.client) {
            console.log('++++++ StringeeClient được truyền vào', StringeeChatWidget.client);
            dispatch(setStringeeClient(StringeeChatWidget.client));
            dispatch(setAuthUser(StringeeChatWidget.accountInfo));
            dispatch(setConnected(true));
            apiStringeeX.setAuthToken(StringeeChatWidget._XStringeeAuth);
        }

        // fix tạm lỗi StringeeServiceType trên sdk
        window.StringeeServiceType = StringeeServiceType;
    }, [dispatch]);

    useEffect(() => {
        if (authUser && (authUser.id || authUser.userId)) {
            apiStringeeX.getChatCustomerData({
                key: StringeeChatWidget.widgetKey || CHAT_CUSTOMER_WIDGET_KEY,
                userId: authUser.id || authUser.userId,
                username: authUser.displayName || authUser.name
            })
                .then(function(data) {
                    console.log("+++ getTokenRestApi res", data);
                    if (data.r === 0) {
                        dispatch(setChatCustomerAccessToken(data.access_token));
                        dispatch(setPublicApiStringee(data.public_api_stringee));
                    }
                })
                .catch((err) => {
                    console.log("+++ getTokenRestApi error", err);
                });
        }
    }, [authUser, dispatch]);

    useEffect(() => {
        if (props.convId) {
            StringeeChat2.getLiveChatConversationsInfo(client, [props.convId])
                .then((res) => {
                    if (res.r === 0 && res.listConvs && res.listConvs.length) {
                        const conversationInfo = res.listConvs[0];
                        dispatch(setConversationSelected(conversationInfo));
                    }
                });
        }
    }, [props.convId, client, StringeeChat2, dispatch]);

    const fetchSenderInfo = useCallback((messages, isLoadMore = false) => {
        if (messages && messages.length) {
            const userIdsNotFound = [];
            messages.map((msg) => {
                if (typeof participantListMap.current[msg.sender] !== "undefined") {
                    const p = participantListMap.current[msg.sender];
                    msg.user_name = p.name;
                    msg.avatar_url = p.avatar;
                } else {
                    if (!userIdsNotFound.includes(msg.sender)) {
                        userIdsNotFound.push(msg.sender);
                    }
                }

                return msg;
            });

            if (userIdsNotFound.length) {
                StringeeChat2.getUsersInfo(client, userIdsNotFound, function(status, code, message, users) {
                    if (users && users.length) {
                        users.forEach((user) => {
                            participantListMap.current[user.userId] = user;
                        });

                        messages.map((msg) => {
                            if (typeof msg.user_name === 'undefined') {
                                if (typeof participantListMap.current[msg.sender] !== "undefined") {
                                    const p = participantListMap.current[msg.sender];
                                    msg.user_name = p.name;
                                    msg.avatar_url = p.avatar;
                                }
                            }

                            return msg;
                        });

                        if (isLoadMore) {
                            dispatch(setConversationMessageList(conversationMessageList.concat(messages)));
                        } else {
                            dispatch(setConversationMessageList([...messages]));
                        }
                    }
                });
            } else {
                if (isLoadMore) {
                    dispatch(setConversationMessageList(conversationMessageList.concat(messages)));
                } else {
                    dispatch(setConversationMessageList([...messages]));
                }
            }
        } else {
            if (!isLoadMore) {
                dispatch(setConversationMessageList([]));
            }
        }
    }, [dispatch, StringeeChat2, client, conversationMessageList]);

    const showMessages = useCallback(() => {
        const conv = chatConversationSelected;
        dispatch(setConversationMessageList([]));

        const postBody = {
            seqGreater: 0,
            seqSmaller: Number.MAX_SAFE_INTEGER,
            limit: msgLimit,
            order: 'DESC'
        };

        conv.getLiveChatMessages(postBody)
            .then(function(messages) {
                console.log('messages list: ', messages);

                if (messages && messages.length) {
                    fetchSenderInfo(messages);
                    // dispatch(setConversationMessageList(messages));

                    // danh dau da doc (seen): truyen len msg co seq lon nhat
                    console.log('messages last: ', messages[0]);
                    const lastMsg = messages[0];
                    conv.markRead(lastMsg);
                }
            })
            .catch(function(err) {
                console.log(err);
            });
    }, [dispatch, chatConversationSelected, fetchSenderInfo]);

    useEffect(() => {
        if (
            chatConversationSelected && chatConversationSelected.id &&
            chatConversationSelected.id !== chatConversationSelectedIdRef.current
        ) {
            chatConversationSelectedIdRef.current = chatConversationSelected.id;

            if (chatConversationSelected.participants) {
                chatConversationSelected.participants.forEach((participant) => {
                    participantListMap.current[participant.user] = participant;
                });
            }

            showMessages();
        }
    }, [chatConversationSelected, showMessages]);

    useEffect(() => {
        if (chatConversationSelected && chatConversationSelected.id) {
            if (conversationMessageList && conversationMessageList.length >= msgLimit) {
                setHasMore(true);
            } else {
                setHasMore(false);
            }
        } else {
            setHasMore(false);
        }
    }, [conversationMessageList, chatConversationSelected]);

    const loadMoreMessages = useCallback(() => {
        if (!chatConversationSelected || !chatConversationSelected.id) {
            return false;
        }

        let seqSmaller = Number.MAX_SAFE_INTEGER;
        if (conversationMessageList && conversationMessageList.length) {
            seqSmaller = conversationMessageList[conversationMessageList.length - 1].seq;
        }
        console.log("seqSmaller: ", seqSmaller);

        if (seqSmaller <= 1) {
            setHasMore(false);
            return true;
        }

        const postBody = {
            seqGreater: 0,
            seqSmaller: seqSmaller,
            limit: msgLimit,
            order: 'DESC'
        };

        chatConversationSelected
            .getLiveChatMessages(postBody)
            .then(function(messages) {
                console.log('messages list load more: ', messages);

                setTimeout(() => {
                    fetchSenderInfo(messages, true);
                    // dispatch(setConversationMessageList(conversationMessageList.concat(messages)));

                    setHasMore(false);
                }, 500);
            })
            .catch(function(err) {
                console.log(err);
            });
    }, [chatConversationSelected, conversationMessageList, fetchSenderInfo]);

    return (
        <Container maxWidth={false} className="stringee-chat__container">
            <Grid container spacing={3} className="stringee-chat__grid">
                <Grid item xs>
                    <div
                        id="scrollableDivContentConv"
                        className={"conversation-message-list hide-input-message"}
                        style={{height: "calc(100vh - 170px)"}}
                        ref={scrollableDivContentConvRef}
                    >
                        <InfiniteScroll
                            dataLength={conversationMessageList.length}
                            next={loadMoreMessages}
                            style={{display: 'flex', flexDirection: 'column-reverse'}}
                            inverse={true}
                            hasMore={hasMore}
                            loader={
                                <div style={{textAlign: "center"}}><CircularProgress /></div>
                            }
                            scrollableTarget="scrollableDivContentConv"
                        >
                            <ConversationMessageList scrollableDivContentConvRef={scrollableDivContentConvRef.current}/>
                        </InfiniteScroll>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}
