import {createSlice} from "@reduxjs/toolkit";
import {cloneObjectWithPrototype, getChatCreatorData} from "../common/common";
import {chatStatusDefine} from "../common/constants";

// Redux toolkit cho phép định nghĩa state (VD: "pageIsError"), định nghĩa các reducers/action (VD: setPageIsError)
// ngay trong 1 file JS, cho phép code dễ bảo trì hơn

// định nghĩa giá trị khởi tạo của state
// các state chứa dữ liệu lấy từ API (server) về thì cần có state.status:
// init (-1): mới khởi tạo; success (0): lấy thành công dữ liệu từ API; error (1): có lỗi lấy dữ liệu
// state.data: dữ liệu lấy từ server về (nếu thành công)
const initialState = {
    conversationList: [],
    isLoadingConvList: true
};

// định nghĩa state name, reducers, actions
const slice = createSlice({
    name: "chatConversationList",
    initialState,
    reducers: {
        setChatConversationList: (state, action) => {
            const convs = action.payload;

            if (convs && convs.length) {
                convs.forEach((conv) => {
                    getChatCreatorData(conv);
                });

                state.conversationList = convs;
            } else {
                state.conversationList = [];
            }
        },
        unshiftItemToChatConversationList: (state, action) => {
            const conv = action.payload;
            getChatCreatorData(conv);

            state.conversationList = [conv, ...state.conversationList];
        },
        updateChatConvListWhenEndChat: (state, action) => {
            const newConversation = action.payload;

            if (newConversation && newConversation.chatStatus === chatStatusDefine.ENDED) {
                const convListTemp = state.conversationList.map((conversation) => {
                    if (conversation.id === newConversation.id) {
                        const msgData = newConversation.lastMessage;

                        conversation.lastMessage = msgData;
                        conversation.lastUpdate = msgData.created;
                    }

                    return conversation;
                });
                convListTemp.sort(function(x, y) {
                    return x.id === newConversation.id ? -1 : y.id === newConversation.id ? 1 : 0;
                });

                state.conversationList = convListTemp;
            }
        },
        updateChatConvListWhenSendMsg: (state, action) => {
            const msgData = action.payload.msgData;
            const chatConversationSelectedId = action.payload.convSelectedId;

            const convListTemp = state.conversationList.map((conversation) => {
                if (conversation.id === chatConversationSelectedId) {
                    const conv = cloneObjectWithPrototype(conversation);
                    conv.lastMessage = msgData;
                    conv.lastUpdate = msgData.created;
                    return conv;
                }
                return conversation;
            });
            convListTemp.sort(function(x, y) {
                return x.id === chatConversationSelectedId ? -1 : y.id === chatConversationSelectedId ? 1 : 0;
            });

            state.conversationList = convListTemp;
        },
        setIsLoadingConvList: (state, action) => {
            state.isLoadingConvList = action.payload;
        }
    }
});

// export state, actions, reducer để các components sử dụng
// lưu ý: quy định đặt tên giống như sample này để dễ hiểu, dễ bảo trì code
export const selectChatConversationList = (state) => state.chatConversationList.conversationList;
export const selectIsLoadingConvList = (state) => state.chatConversationList.isLoadingConvList;
export const {
    setChatConversationList,
    unshiftItemToChatConversationList,
    updateChatConvListWhenEndChat,
    updateChatConvListWhenSendMsg,
    setIsLoadingConvList
} = slice.actions;
export default slice.reducer;
