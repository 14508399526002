import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {forwardRef, useImperativeHandle, useState} from "react";
import {validateEmail} from "../../../common/common";
import {useDispatch, useSelector} from "react-redux";
import {selectChatConversationSelected} from "../../../store/chatConversationSelected";
import {STRINGEEX_URL_DOMAIN} from "../../../common/config";
import {setNotifyMessage} from "../../../store/notifyMessage";
import {useTranslation} from "react-i18next";

const ModalSendEmailChat = forwardRef((props, ref) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [disableSendInput, setDisableSendInput] = useState(true);
    const [emailInput, setEmailInput] = useState(null);

    const dispatch = useDispatch();
    const chatConversationSelected = useSelector(selectChatConversationSelected);

    useImperativeHandle(ref, () => ({
        show() {
            setOpen(true);
        }
    }));

    const send = () => {
        chatConversationSelected.sendChatTranscriptTo(emailInput, STRINGEEX_URL_DOMAIN).then((res) => {
            console.log("sendEmailChat res", res);

            if (res.r === 0) {
                dispatch(setNotifyMessage(t('chatbox.email_send_success')));
                setOpen(false);
            }
        });
    };

    const onChangeEmailInput = (event) => {
        const email = event.target.value;

        setEmailInput(email);
        setDisableSendInput(!validateEmail(email));
    };

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle sx={{fontSize: "16px"}}>{t('chatbox.email_trans')}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        id="name"
                        label={t('chatbox.email_address')}
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={onChangeEmailInput}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t('chatbox.btn_cancel')}</Button>
                    <Button onClick={send} disabled={disableSendInput}>{t('chatbox.btn_submit')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default ModalSendEmailChat;
