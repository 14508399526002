import axios from "axios";
import ApiError from "./ApiError";

export default class ApiBase {
    constructor() {
        this.baseUrl = "";
        // todo: fix
        this.authToken = "";
    }

    httpPost(uri, data, headers = {}) {
        return this.httpRequest(uri, "post", data, headers);
    }

    httpPut(uri, data) {
        return this.httpRequest(uri, "put", data);
    }

    httpGet(uri) {
        return this.httpRequest(uri, "get", undefined);
    }

    httpDelete(uri) {
        return this.httpRequest(uri, "delete", undefined);
    }

    httpRequest(uri, method, data, headers = {}) {
        return new Promise((resolve, reject) => {
            headers["X-STRINGEE-AUTH"] = this.authToken;
            axios({
                method: method,
                url: this.baseUrl + uri,
                data: data,
                headers
            }).then((res) => {// HTTP status=2xx
                // console.log("http res", res);

                if (!res.data) {
                    reject(new ApiError(
                        -6,
                        "SERVER_RESPONSE_EMPTY",
                        ""
                    ));
                    return;
                }

                if (typeof res.data !== "object") {
                    reject(new ApiError(
                        -8,
                        "SERVER_NOT_RESPONSE_JSON",
                        "JSON from server: " + res.data
                    ));
                    return;
                }

                if (res.data && res.data.r === 0) {
                    resolve(res.data);
                    return;
                }

                reject(new ApiError(
                    res.data.r,
                    "SERVER_RESPONSE_ERROR",
                    res.data.msg + ", ErrorCode=" + res.data.r
                ));
            }).catch((axiosError) => {
                // console.log("http error", axiosError);

                if (!axiosError.response) {// server khong tra response: network error
                    reject(new ApiError(
                        -2,
                        "NETWORK_ERROR",
                        axiosError.message
                    ));
                    return;
                }

                if (!axiosError.response.data) {// server tra response.data rong: data from server is empty
                    reject(new ApiError(
                        -3,
                        "SERVER_RESPONSE_EMPTY",
                        axiosError.message
                    ));
                    return;
                }

                if (typeof axiosError.response.data === "object") {// server tra response.data la Object
                    reject(new ApiError(
                        axiosError.response.data.r,
                        "SERVER_RESPONSE_ERROR",
                        axiosError.response.data.msg + ", ErrorCode=" + axiosError.response.data.r
                    ));
                    return;
                }

                // server tra response.data la String
                reject(new ApiError(
                    -5,
                    "SERVER_NOT_RESPONSE_JSON",
                    axiosError.response.data
                ));
            });
        });
    }
}
