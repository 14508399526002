import {Box, Link} from "@mui/material";
import "./../../../styles/scss/system_message.scss";
import ModalChatReview from "../../chat_box/modals/ModalChatReview";
import {useRef} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectChatProfile} from "../../../store/chatbox";

function ActionMessage(props) {
    const {t} = useTranslation();
    const msgData = props.messageData || {};

    const chatProfile = useSelector(selectChatProfile);
    const modalChatReviewRef = useRef(null);

    const redirectReviewChat = () => {
        modalChatReviewRef.current?.show();
    };

    const renderMsgWithAction = (action) => {
        switch (action) {
            case 'AGENT_REQUEST_CHAT_RATE_COMMENT':
                return <Link href="#" onClick={() => redirectReviewChat()}>
                    {t("message.rate_comment_request")}
                </Link>;
            case 'REQUEST_CHAT_COMMENT':
                return <Link href="#" onClick={() => redirectReviewChat()}>
                    {t("message.btn_comment")}
                </Link>;
            case 'ALL_AGENT_BUSY':
                return <span className="action-message">
                    {t("chatbox.agent_busy")}
                </span>;
            case 'SHOW_WAIT_MESSAGE':
                if (chatProfile.wait_message) {
                    return <span className="action-message waiting-message">
                        {chatProfile.wait_message}
                    </span>;
                }

                return null;
            default:
                return null;
        }
    };

    return (
        <Box className="system-message-wrapper">
            {renderMsgWithAction(msgData.action)}
            <ModalChatReview ref={modalChatReviewRef}/>
        </Box>
    );
}

export default ActionMessage;
