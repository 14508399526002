import "./../../../styles/scss/user_message.scss";
import {useSelector} from "react-redux";
import {selectAuthUser} from "../../../store/chatAuthUser";
import {Avatar, Box, Tooltip} from "@mui/material";
import MessageBody from "./MessageBody";
import {stringAvatar} from "../../../common/common";

function UserMessage(props) {
    const msgData = props.messageData;

    const authUser = useSelector(selectAuthUser);
    const currentUserSendMsg = msgData && authUser && msgData.sender === (authUser.id || authUser.userId);

    return (
        <Box className="user-message-wrapper">
            <Box className={currentUserSendMsg ? "message-sent" : "incoming-message"}>
                <MessageBody messageData={msgData} currentUserSendMsg={currentUserSendMsg}/>

                <Tooltip
                    title={
                        currentUserSendMsg ?
                            (authUser.displayName || authUser.name || authUser.userId) :
                            (msgData.user_name || msgData.sender)
                    }
                    placement={currentUserSendMsg ? 'left' : 'right'}
                >
                    {currentUserSendMsg ?
                        (authUser.linkavatar ?
                            <Avatar src={authUser.linkavatar}/> :
                            <Avatar {...stringAvatar(authUser.displayName || authUser.name || authUser.userId)}/>
                        ) :
                        (msgData.avatar_url ?
                            <Avatar src={msgData.avatar_url}/> :
                            <Avatar {...stringAvatar(msgData.user_name || msgData.sender)}/>
                        )
                    }
                </Tooltip>
            </Box>
        </Box>
    );
}

export default UserMessage;
