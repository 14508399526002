import {TextField} from "@mui/material";
import {forwardRef, useCallback, useEffect, useImperativeHandle, useRef} from "react";
import {
    selectConversationMessageList,
    setScrollToBottomMessageList,
    unshiftItemToConversationMessageList
} from "../../../store/conversationMessageList";
import {
    updateChatConvListWhenSendMsg
} from "../../../store/chatConversationList";
import {useDispatch, useSelector} from "react-redux";
import {
    selectChatConversationSelected,
    selectIsEndChat, setConversationSelected
} from "../../../store/chatConversationSelected";
import {chatTypeDefine, ratingTypeDefine} from "../../../common/constants";
import {
    selectChatProfile,
    selectConvQueueId,
    selectIsChatBot,
    selectIsCustomer,
    setRating
} from "../../../store/chatbox";
import {selectStringeeClient} from "../../../store/chatStringeeClient";
import {selectMsgInput, selectSelectedFiles, setMsgInput, setSelectedFiles} from "../../../store/messageField";
import {useTranslation} from "react-i18next";
import {debounce} from "lodash";
import {selectShowTemplate} from "../../../store/chatTemplate";
import {setChatStorage} from "../../../common/common";
import {selectAuthUser} from "../../../store/chatAuthUser";

const ReplyBar = forwardRef(({handleSendFile, handlePasteInput}, ref) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const chatConversationSelected = useSelector(selectChatConversationSelected);
    const isCustomer = useSelector(selectIsCustomer);
    const client = useSelector(selectStringeeClient);
    const chatProfile = useSelector(selectChatProfile);
    const convQueueId = useSelector(selectConvQueueId);
    const isEndChat = useSelector(selectIsEndChat);
    const msgInput = useSelector(selectMsgInput);
    const selectedFiles = useSelector(selectSelectedFiles);
    const showTemplate = useSelector(selectShowTemplate);
    const isChatBot = useSelector(selectIsChatBot);
    const conversationMessageList = useSelector(selectConversationMessageList);
    const authUser = useSelector(selectAuthUser);

    const typingTimeout = useRef(null);

    const StringeeChat2 = window.StringeeChat2;

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        sendMsg: (e, checkKeyUp = true) => handleKeypress(e, checkKeyUp)
    }));
    const handleSendMessage = async function(messageObject) {
        try {
            const msgData = await chatConversationSelected.sendMessage(chatTypeDefine.TEXT, messageObject);
            console.log('sent success', msgData);

            dispatch(unshiftItemToConversationMessageList(msgData));
            dispatch(setScrollToBottomMessageList(true));

            dispatch(updateChatConvListWhenSendMsg({
                msgData,
                convSelectedId: chatConversationSelected.id
            }));
            chatConversationSelected.endTyping();
            return true;
        } catch (e) {
            console.log(e);

            if (e && (e.r === 90 || e.r === -210)) {
                const postMessageData = {
                    action: 'resSendMessage',
                    data: {
                        r: e.r,
                        msg: t('message_field.max_length_msg_2000')
                    }
                };
                window.parent.postMessage(postMessageData, "*");
            }
            return false;
        }
    };


    const handleKeypress = useCallback(async (e, checkKeyUp = true) => {
        const isEnterKey = e.key === 'Enter' || e.keyCode === 13;
        const isArrowKey = e.key === 'ArrowUp' || e.key === 'ArrowDown';

        if (showTemplate && (isArrowKey || isEnterKey)) {
            // ngăn chặn sự kiện khi đang chọn mẫu tin nhắn
            e.preventDefault();
        }

        if (!checkKeyUp || (isEnterKey && !e.shiftKey && !showTemplate)) {
            e.preventDefault();

            if (!chatConversationSelected || !chatConversationSelected.id) {
                alert(t('message_field.please_select_conversation'));
                return false;
            }

            const msgInputTrim = msgInput.trim();

            if (!msgInputTrim && (!selectedFiles || !selectedFiles.length)) {
                return false;
            }

            if (selectedFiles && selectedFiles.length && handleSendFile) {
                for (const file of selectedFiles) {
                    await handleSendFile(file);
                }

                dispatch(setSelectedFiles([]));
            }

            if (isChatBot && !isEndChat) {
                // nếu là chatbot và bot chưa trả lời thì không cho phép gửi tin nhắn
                if (conversationMessageList && conversationMessageList.length) {
                    const lastMsg = conversationMessageList[0];
                    const userId = authUser.userId || authUser.id;
                    if (lastMsg && lastMsg.sender === userId) {
                        return false;
                    }
                }
            }

            if (msgInputTrim) {
                const messageObject = {
                    content: msgInputTrim
                };
                dispatch(setMsgInput(''));
                if (isCustomer && isEndChat) {
                    StringeeChat2
                        .createLiveChatConversation(client, convQueueId, null, {serviceId: chatProfile.service_id})
                        .then((res) => {
                            console.log("createdConversation chatBox: ", res);

                            // send first message
                            res.sendMessage(chatTypeDefine.TEXT, messageObject)
                                .then((msg1) => {
                                    console.log('sent success', msg1);
                                })
                                .catch((e) => {
                                    console.log('sent msg err', e);
                                });
                            dispatch(setRating(ratingTypeDefine.NOT_RATING));
                            dispatch(setConversationSelected(res));
                            setChatStorage('ConvId', res.id);
                            setChatStorage('CusWaitMsg', true);
                        }).catch((e) => {
                            console.log("+++++++++++handleCreateConv err", e);
                        });
                    return;
                }
                await handleSendMessage(messageObject);
            }
        }
    }, [showTemplate, msgInput, selectedFiles, isEndChat, isCustomer, chatProfile, client, dispatch, convQueueId,
        chatConversationSelected, StringeeChat2, handleSendFile, t, handleSendMessage]);


    const handleInputChangeDebounced = useRef(
        debounce((value) => {
            if (value) {
                chatConversationSelected.beginTyping();
            } else {
                chatConversationSelected.endTyping();
            }
        }, 300)
    ).current;

    function onChangeMsgInput(e) {
        const text = e.target.value;

        dispatch(setMsgInput(text));

        if (text.trim()) {
            clearTimeout(typingTimeout.current);
            typingTimeout.current = setTimeout(() => {
                console.log('typingTimeout++++++');
                chatConversationSelected.endTyping();
            }, 5000); // 5 seconds timeout
        }

        handleInputChangeDebounced(text.trim());
    }

    useEffect(() => {
        return () => {
            handleInputChangeDebounced.cancel();
        };
    }, [handleInputChangeDebounced]);

    return (
        <TextField
            id="inputSendMessage"
            className="input-send-message"
            multiline
            fullWidth
            rows={4}
            placeholder={(isCustomer || client.isInternal) ?
                t("message_field.type_message") : t("message_field.reply_bar_chat_placeholder")}
            variant="filled"
            onChange={onChangeMsgInput}
            onKeyDown={handleKeypress}
            onPaste={handlePasteInput}
            value={msgInput}
        />
    );
});

export default ReplyBar;
