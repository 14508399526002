import React from 'react';
import './../../../styles/scss/loading_overlay.scss';

const LoadingOverlay = () => {
    return (
        <div className="loading-overlay">
            <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default LoadingOverlay;
