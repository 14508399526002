import {
    Box,
    Button,
    Dialog,
    IconButton,
    Link,
    List,
    ListItemButton,
    ListItemText,
    styled,
    Tooltip
} from "@mui/material";
import {ExitToApp, MoreHoriz, VolumeOff, VolumeUp} from "@mui/icons-material";

import * as React from 'react';
import Slide from '@mui/material/Slide';
import {useRef, useState} from "react";
import EmojiPicker from "emoji-picker-react";
import ModalSendEmailChat from "../../chat_box/modals/ModalSendEmailChat";
import {useDispatch, useSelector} from "react-redux";
import {selectAllowEndChat, selectIsEndChat, setConversationSelected} from "../../../store/chatConversationSelected";
import ModalUpdateUserInfo from "../../chat_box/modals/ModalUpdateUserInfo";
import ModalConfirmEndChat from "../../chat_box/modals/ModalConfirmEndChat";
import {selectIsChatBot, selectIsVolumeUp, setIsStartAgain, setIsVolumeUp, setRating} from "../../../store/chatbox";
import {ratingTypeDefine} from "../../../common/constants";
import {BASE_URL} from "../../../common/config";
import {useTranslation} from "react-i18next";
import ModalPreviewSelectedFiles from "../../chat_box/modals/ModalPreviewSelectedFiles";
import {removeChatStorage} from "../../../common/common";

const Transition = React.forwardRef(function Transition(
    props,
    ref,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialog-container': {
        alignItems: "flex-end"
    },
    '& .MuiPaper-root': {
        width: "100%",
        margin: "0 5% 65px",
        boxShadow: "0 5px 10px rgba(0, 0, 0, 0.2)"
    },
    '& .MuiList-root': {
        paddingTop: 0,
        paddingBottom: 0
    },
    '& .MuiListItemButton-root': {
        paddingTop: "12px",
        paddingBottom: "12px"
    }
}));

function MessageBottomChatBox(props) {
    const {t} = useTranslation();
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [openDialogAction, setOpenDialogAction] = useState(false);

    const modalSendEmailChatRef = useRef(null);
    const modalUpdateUserInfoRef = useRef(null);
    const modalConfirmEndChatRef = useRef(null);
    const modalPreviewSelectedFilesRef = useRef(null);

    const dispatch = useDispatch();
    const isEndChat = useSelector(selectIsEndChat);
    const allowEndChat = useSelector(selectAllowEndChat);
    const isVolumeUp = useSelector(selectIsVolumeUp);
    const isChatBot = useSelector(selectIsChatBot);

    const AttachFile = BASE_URL + "/assets/images/attach-file.svg";
    const EmojiIcon = BASE_URL + "/assets/images/emoji-icon.svg";

    const toggleVolume = () => {
        dispatch(setIsVolumeUp(!isVolumeUp));
    };

    const onShowModalSendEmailChat = () => {
        setOpenDialogAction(false);
        modalSendEmailChatRef.current?.show();
    };

    const onShowModalUpdateUserInfo = () => {
        setOpenDialogAction(false);
        modalUpdateUserInfoRef.current?.show();
    };
    const removeDataLocalStorage = () => {
        removeChatStorage("ConvId");
        removeChatStorage("ConvIdCF");
        removeChatStorage("CusUUID");
    };

    const handleStartAgain = () => {
        dispatch(setRating(ratingTypeDefine.NOT_RATING));
        dispatch(setConversationSelected(null));
        removeDataLocalStorage();
        dispatch(setIsStartAgain(true));
    };

    const handleEndChat = () => {
        setOpenDialogAction(false);
        modalConfirmEndChatRef.current?.show();
    };

    const handleSelectedFiles = (event) => {
        if (props.onFileSelected(event)) {
            modalPreviewSelectedFilesRef.current?.show();
        }
    };

    function dialogAction() {
        return <BootstrapDialog
            open={openDialogAction}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenDialogAction(false)}
            aria-describedby="alert-dialog-slide-description"
        >
            <List component="nav" aria-label="mailbox folders">
                <ListItemButton divider onClick={toggleVolume}>
                    <ListItemText primary={t('chatbox.sound')} sx={{marginTop: 0, marginBottom: 0}}/>
                    <IconButton sx={{padding: 0}}>
                        {isVolumeUp ? <VolumeUp/> : <VolumeOff/>}
                    </IconButton>
                </ListItemButton>
                <ListItemButton divider onClick={() => onShowModalSendEmailChat()}>
                    {t('message_field.email_chat_action')}
                </ListItemButton>
                <ListItemButton divider onClick={() => onShowModalUpdateUserInfo()}>
                    {t('message_field.update_user_info')}
                </ListItemButton>
                <ListItemButton divider onClick={() => handleStartAgain()} disabled={!isEndChat}>
                    {t('message_field.start_run_again')}
                </ListItemButton>
                <ListItemButton onClick={() => handleEndChat()} disabled={!allowEndChat}>
                    {t('message_field.end_chat')}
                </ListItemButton>
            </List>
        </BootstrapDialog>;
    }

    return <>
        <Box className="message-field-bottom">
            <Box className="message-field-bottom__left">
                <Link href="https://stringeex.com" target="_blank">
                    StringeeX
                </Link>
            </Box>
            <Box className="message-field-bottom__right">
                <Link
                    href="#"
                    onMouseEnter={() => setShowEmojiPicker(true)}
                    onMouseLeave={() => setShowEmojiPicker(false)}
                    className="message-emoji"
                >
                    <img src={EmojiIcon} alt=""/>
                    {showEmojiPicker && <span className="message-emoji__picker">
                        <EmojiPicker
                            onEmojiClick={props.onEmojiClick}
                            lazyLoadEmojis={true}
                            width="320"
                        />
                    </span>}
                </Link>
                <Button component="label" onClick={handleEndChat} disabled={!allowEndChat}>
                    <Tooltip title={t('message_field.end_chat')}>
                        <ExitToApp sx={{color: "#999999", opacity: !allowEndChat ? '0.38' : '1'}}/>
                    </Tooltip>
                </Button>
                <Button component="label" disabled={isEndChat || isChatBot}>
                    <img src={AttachFile} alt="" style={{opacity: (isEndChat || isChatBot) ? '0.38' : '1'}}/>
                    <input type="file" multiple hidden onChange={handleSelectedFiles}/>
                </Button>
                <Button component="label" onClick={() => setOpenDialogAction(true)}>
                    <MoreHoriz sx={{color: "#999999"}}/>
                </Button>
                {dialogAction()}
            </Box>
        </Box>

        <Box className="list-modal">
            <ModalSendEmailChat ref={modalSendEmailChatRef}/>
            <ModalUpdateUserInfo ref={modalUpdateUserInfoRef}/>
            <ModalConfirmEndChat ref={modalConfirmEndChatRef}/>
            <ModalPreviewSelectedFiles
                ref={modalPreviewSelectedFilesRef}
                handleSendFile={props.handleSendFile}
                handleSelectedFiles={handleSelectedFiles}
            />
        </Box>
    </>;
}

export default MessageBottomChatBox;
