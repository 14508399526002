import {useSelector} from "react-redux";
import {selectUserTypingIndicator} from "../../../store/chatGeneral";
import {Avatar, Box, Tooltip} from "@mui/material";
import {isEmpty, stringAvatar} from "../../../common/common";
import "./../../../styles/scss/typing_indicator.scss";

function TypingIndicator() {
    const userTypingData = useSelector(selectUserTypingIndicator);

    if (!userTypingData || isEmpty(userTypingData)) {
        return null;
    }

    return (
        <Box className="conversation-message-item">
            <Box className="user-message-wrapper">
                <Box className="incoming-message">
                    <span className="message-body">
                        <div className="typing-indicator">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </span>

                    <Tooltip
                        title={userTypingData.displayName}
                        placement='right'
                    >
                        <Avatar {...stringAvatar(userTypingData.displayName)}/>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
}

export default TypingIndicator;
