import {createSlice} from "@reduxjs/toolkit";

// Redux toolkit cho phép định nghĩa state (VD: "pageIsError"), định nghĩa các reducers/action (VD: setPageIsError)
// ngay trong 1 file JS, cho phép code dễ bảo trì hơn

// định nghĩa giá trị khởi tạo của state
// các state chứa dữ liệu lấy từ API (server) về thì cần có state.status:
// init (-1): mới khởi tạo; success (0): lấy thành công dữ liệu từ API; error (1): có lỗi lấy dữ liệu
// state.data: dữ liệu lấy từ server về (nếu thành công)
const initialState = {
    messageList: [],
    scrollToBottomMessageList: false,
    isMarkReadMsgByScroll: false
};

// định nghĩa state name, reducers, actions
const slice = createSlice({
    name: "conversationMessageList",
    initialState,
    reducers: {
        setConversationMessageList: (state, action) => {
            state.messageList = action.payload;
        },
        unshiftItemToConversationMessageList: (state, action) => {
            state.messageList.unshift(action.payload);
        },
        setScrollToBottomMessageList: (state, action) => {
            state.scrollToBottomMessageList = action.payload;
        },
        setIsMarkReadMsgByScroll: (state, action) => {
            state.isMarkReadMsgByScroll = action.payload;
        }
    }
});

// export state, actions, reducer để các components sử dụng
// lưu ý: quy định đặt tên giống như sample này để dễ hiểu, dễ bảo trì code
export const selectConversationMessageList = (state) => state.conversationMessageList.messageList;
export const selectScrollToBottomMessageList = (state) => state.conversationMessageList.scrollToBottomMessageList;
export const selectIsMarkReadMsgByScroll = (state) => state.conversationMessageList.isMarkReadMsgByScroll;
export const {
    setConversationMessageList,
    unshiftItemToConversationMessageList,
    setScrollToBottomMessageList,
    setIsMarkReadMsgByScroll
} = slice.actions;
export default slice.reducer;
