import {createSlice} from "@reduxjs/toolkit";
import {ratingTypeDefine} from "../common/constants";
import {getChatStorage, setChatStorage} from "../common/common";
import i18n from "i18next";

// Redux toolkit cho phép định nghĩa state (VD: "pageIsError"), định nghĩa các reducers/action (VD: setPageIsError)
// ngay trong 1 file JS, cho phép code dễ bảo trì hơn

// định nghĩa giá trị khởi tạo của state
// value=true tức là trang đang render có lỗi
// r/msg: thông tin chi tiết về lỗi
const initialState = {
    showChat: false,
    chatProfile: {},
    location: {},
    queues: [],
    customFields: [],
    rating: ratingTypeDefine.NOT_RATING,
    isCustomer: false,
    convQueueId: '',
    customerPhone: '',
    customerEmail: '',
    connected: false,
    isVolumeUp: getChatStorage('Noti') !== '0',
    agentReceivesConvName: i18n.t('chatbox.live_support'),
    isPopup: false,
    isCheckBusinessHour: true,
    isShowInnerBusinessHour: false,
    isStartAgain: false,
    isChatBot: false
};

// định nghĩa state name, reducers, actions
const slice = createSlice({
    name: "chatbox",
    initialState,
    reducers: {
        // action public ra để dùng là: setPageIsError(true/false)
        setShowChat: (state, action) => {
            state.showChat = action.payload;
        },
        setChatProfile: (state, action) => {
            const chatProfile = action.payload;

            if (chatProfile.language) {
                i18n.changeLanguage(chatProfile.language);
            }

            state.chatProfile = chatProfile;
        },
        setLocation: (state, action) => {
            state.location = action.payload;
        },
        setQueues: (state, action) => {
            state.queues = action.payload;
        },
        setCustomFields: (state, action) => {
            let customFields = action.payload;

            if (!customFields || !customFields.length) {
                customFields = [];
            }

            state.customFields = customFields;
        },
        setRating: (state, action) => {
            state.rating = action.payload;
        },
        setIsCustomer: (state, action) => {
            state.isCustomer = action.payload;
        },
        setConvQueueId: (state, action) => {
            state.convQueueId = action.payload;
        },
        setCustomerPhone: (state, action) => {
            state.customerPhone = action.payload;
        },
        setCustomerEmail: (state, action) => {
            state.customerEmail = action.payload;
        },
        setConnected: (state, action) => {
            state.connected = action.payload;
        },
        setIsVolumeUp: (state, action) => {
            const volumeUp = action.payload;
            state.isVolumeUp = volumeUp;
            setChatStorage('Noti', volumeUp ? 1 : 0);
        },
        setAgentReceivesConvName: (state, action) => {
            state.agentReceivesConvName = action.payload;
        },
        setIsPopup: (state, action) => {
            state.isPopup = action.payload;
        },
        setIsCheckBusinessHour: (state, action) => {
            state.isCheckBusinessHour = action.payload;
        },
        setIsShowInnerBusinessHour: (state, action) => {
            state.isShowInnerBusinessHour = action.payload;
        },
        setIsStartAgain: (state, action) => {
            state.isStartAgain = action.payload;
        },
        setIsChatBot: (state, action) => {
            state.isChatBot = action.payload;
        }
    }
});

// export state, actions, reducer để các components sử dụng
// lưu ý: quy định đặt tên giống như sample này để dễ hiểu, dễ bảo trì code
export const selectShowChat = (state) => state.chatbox.showChat;
export const selectChatProfile = (state) => state.chatbox.chatProfile;
export const selectLocation = (state) => state.chatbox.location;
export const selectQueues = (state) => state.chatbox.queues;
export const selectCustomFields = (state) => state.chatbox.customFields;
export const selectRating = (state) => state.chatbox.rating;
export const selectIsCustomer = (state) => state.chatbox.isCustomer;
export const selectConvQueueId = (state) => state.chatbox.convQueueId;
export const selectCustomerPhone = (state) => state.chatbox.customerPhone;
export const selectCustomerEmail = (state) => state.chatbox.customerEmail;
export const selectConnected = (state) => state.chatbox.connected;
export const selectIsVolumeUp = (state) => state.chatbox.isVolumeUp;
export const selectAgentReceivesConvName = (state) => state.chatbox.agentReceivesConvName;
export const selectIsPopup = (state) => state.chatbox.isPopup;
export const selectIsCheckBusinessHour = (state) => state.chatbox.isCheckBusinessHour;
export const selectIsShowInnerBusinessHour = (state) => state.chatbox.isShowInnerBusinessHour;
export const selectIsStartAgain = (state) => state.chatbox.isStartAgain;
export const selectIsChatBot = (state) => state.chatbox.isChatBot;
export const {
    setShowChat,
    setChatProfile,
    setLocation,
    setQueues,
    setCustomFields,
    setRating,
    setIsCustomer,
    setConvQueueId,
    setCustomerPhone,
    setCustomerEmail,
    setConnected,
    setIsVolumeUp,
    setAgentReceivesConvName,
    setIsPopup,
    setIsCheckBusinessHour,
    setIsShowInnerBusinessHour,
    setIsStartAgain,
    setIsChatBot
} = slice.actions;
export default slice.reducer;
