// import Chat from "./chats/Chat";
import {useCallback, useEffect, useRef, useState} from "react";
import ChatBox from "./chats/ChatBox";
import Chat from "./chats/Chat";
import Notification from "../common_components/chats/notifications/Notification";
import PastChat from "./stringee/PastChat";
import {getChatStorage} from "../common/common";

export default function Home() {
    const urlParams = useRef(getUrlParameter() || {
        isOldCustomer: false,
        popup: false,
        convId: '',
        key: '',
        queue: '',
        userId: '',
        email: '',
        phone: '',
        name: '',
        noti: true
    });
    const [pastConvId, setPastConvId] = useState(null);

    useEffect(() => {
        const StringeeChatWidget = window.parent.StringeeChatWidget || null;

        if (StringeeChatWidget && StringeeChatWidget.StringeeChat2) {
            window.StringeeChat2 = StringeeChatWidget.StringeeChat2;
        }
    }, []);

    function getUrlParameter() {
        try {
            const stringeeXChatWidget = window.parent.document.getElementById("stringeeXChatWidget");
            if (stringeeXChatWidget) {
                const scriptSrc = stringeeXChatWidget.src;
                const paramsStartIndex = scriptSrc.indexOf('?');

                if (paramsStartIndex !== -1) {
                    const queryString = scriptSrc.substring(paramsStartIndex + 1);
                    const params = new URLSearchParams(queryString);

                    const urlParams = {};
                    for (const [key, value] of params) {
                        urlParams[key] = value;
                    }
                    const conversationId = getChatStorage("ConvId");
                    const customerId = getChatStorage("CusUUID");
                    if (conversationId && customerId) {
                        urlParams.isOldCustomer = true;
                        urlParams.convId = conversationId;
                        urlParams.userId = customerId;
                        urlParams.queue = getChatStorage("QueueId");
                        urlParams.email = getChatStorage("CusEmail");
                        urlParams.phone = getChatStorage("CusPhone");
                        urlParams.name = getChatStorage("CusName");
                        urlParams.noti = getChatStorage('Noti');
                    }

                    return urlParams;
                }
            }

            return {};
        } catch (err) {
            console.log(err.message);
        }
    }

    const onMessageReceivedFromIframe = useCallback((event) => {
        const postMessageData = event.data;

        if (typeof event.data !== "object") {
            return false;
        }

        if (typeof postMessageData.action !== 'undefined') {
            if (postMessageData.action === 'onSelectPastConversation') {
                const convId = postMessageData.data;
                setPastConvId(convId);
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener("message", onMessageReceivedFromIframe);
        return () =>
            window.removeEventListener("message", onMessageReceivedFromIframe);
    }, [onMessageReceivedFromIframe]);

    if (pastConvId) {
        return <PastChat convId={pastConvId}/>;
    }

    return (
        <>
            <Notification/>
            {
                urlParams.current.key ?
                    <ChatBox params={urlParams.current}/> :
                    <Chat/>
            }
        </>
    );
}
