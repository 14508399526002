import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {forwardRef, useCallback, useImperativeHandle, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectStringeeClient} from "../../../store/chatStringeeClient";
import {selectAuthUser, setAuthUser} from "../../../store/chatAuthUser";
import {selectChatConversationSelected} from "../../../store/chatConversationSelected";
import {setChatStorage, validateEmail, validatePhone} from "../../../common/common";
import {
    selectChatProfile,
    selectCustomerEmail,
    selectCustomerPhone,
    selectLocation,
    setCustomerEmail, setCustomerPhone
} from "../../../store/chatbox";
import {setNotifyMessage} from "../../../store/notifyMessage";
import {useTranslation} from "react-i18next";

const ModalUpdateUserInfo = forwardRef((props, ref) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: ''
    });
    const [enableSendInput, setEnableSendInput] = useState(false);

    const client = useSelector(selectStringeeClient);
    const authUser = useSelector(selectAuthUser);
    const chatConversationSelected = useSelector(selectChatConversationSelected);
    const chatProfile = useSelector(selectChatProfile);
    const location = useSelector(selectLocation);
    const cusPhone = useSelector(selectCustomerPhone);
    const cusEmail = useSelector(selectCustomerEmail);
    const dispatch = useDispatch();

    const browserInfo = window.detect.parse(navigator.userAgent);
    const StringeeChat2 = window.StringeeChat2;

    useImperativeHandle(ref, () => ({
        show() {
            setFormData({
                name: authUser.name || authUser.displayName,
                email: cusEmail,
                phone: cusPhone
            });
            setOpen(true);
        }
    }));

    const save = async () => {
        const body = {
            avatar_url: "",
            browser: browserInfo.browser.family + " v" + browserInfo.browser.version,
            convId: chatConversationSelected.id,
            device: browserInfo.device.type,
            display_name: formData.name,
            email: formData.email,
            hostname: window.location.protocol + "//" + window.location.hostname,
            ipaddress: location.query,
            location: location.city + "," + location.country,
            phone: formData.phone,
            platform: browserInfo.os.family,
            useragent: window.navigator.userAgent
        };

        StringeeChat2.updateUserInfo(client, body).then((res) => {
            console.log("updateUserInfo res", res);

            if (res.r === 0) {
                dispatch(setNotifyMessage(t('chatbox.cont_update')));
                setOpen(false);

                // update auth user
                dispatch(setAuthUser({
                    ...authUser,
                    name: formData.name,
                    displayName: formData.name
                }));
                dispatch(setCustomerPhone(formData.phone));
                dispatch(setCustomerEmail(formData.email));

                setChatStorage('CusEmail', formData.email);
                setChatStorage('CusPhone', formData.phone);
                setChatStorage('CusName', formData.name);
            }
        });
    };

    const checkEnableSendInput = useCallback((formData) => {
        let enableSendInput = false;
        if (formData.name && formData.name !== (authUser.displayName || authUser.name)) {
            enableSendInput = true;
        }

        if (chatProfile.enable_email && formData.email &&
            formData.email !== cusEmail && validateEmail(formData.email)) {
            enableSendInput = true;
        }

        if (chatProfile.enable_phone && formData.phone &&
            formData.phone !== cusPhone && validatePhone(formData.phone)) {
            enableSendInput = true;
        }

        setEnableSendInput(enableSendInput);
    }, [cusEmail, cusPhone, authUser, chatProfile]);

    const onChangeInput = (event, key) => {
        const value = event.target.value;

        const newFormData = {...formData, [key]: value};
        setFormData(newFormData);
        checkEnableSendInput(newFormData);
    };

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle sx={{fontSize: "16px"}}>{t('chatbox.edit_cont_det')}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        id="name"
                        label={t('chatbox.your_name')}
                        type="name"
                        fullWidth
                        variant="standard"
                        onChange={(e) => onChangeInput(e, 'name')}
                        defaultValue={formData.name}
                    />
                    {chatProfile.enable_phone ? <TextField
                        id="phone"
                        label={t('chatbox.phone_number')}
                        type="phone"
                        fullWidth
                        variant="standard"
                        margin="dense"
                        onChange={(e) => onChangeInput(e, 'phone')}
                        defaultValue={formData.phone}
                    /> : null}
                    {chatProfile.enable_email ? <TextField
                        id="email"
                        label={t('chatbox.email_address')}
                        type="email"
                        fullWidth
                        variant="standard"
                        margin="dense"
                        onChange={(e) => onChangeInput(e, 'email')}
                        defaultValue={formData.email}
                    /> : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t('chatbox.btn_cancel')}</Button>
                    <Button onClick={save} disabled={!enableSendInput}>{t('chatbox.btn_save')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default ModalUpdateUserInfo;
