import React, {useCallback, useEffect, useMemo, useState} from "react";
import {apiStringeeX} from "../../api/ApiStringeeX";
import {Container, MenuItem, Select, TextField, Box} from "@mui/material";
import detect from "detect.js";
import {useDispatch, useSelector} from "react-redux";
import ContentConversation from "../../common_components/chats/ContentConversation";
import {
    selectChatConversationSelected,
    setConversationSelected
} from "../../store/chatConversationSelected";
import {setStringeeClient} from "../../store/chatStringeeClient";
import {chatTypeDefine} from "../../common/constants";
import {API_STRINGEE_COM_BASE_URL, STRINGEE_SERVER_ADDRS} from "../../common/config";
import {selectIsLoading, setChatCustomerAccessToken, setIsLoading, setPublicApiStringee} from "../../store/chatGeneral";
import {
    cloneObject,
    getChatStorage,
    setChatStorage,
    validateEmail,
    validatePhone
} from "../../common/common";
import {setAuthUser} from "../../store/chatAuthUser";
import {
    selectChatProfile, selectCustomFields, selectIsCheckBusinessHour, selectIsShowInnerBusinessHour,
    selectLocation, selectQueues,
    setConnected,
    setConvQueueId, setCustomerEmail, setCustomerPhone, setIsPopup, setIsShowInnerBusinessHour, setIsVolumeUp
} from "../../store/chatbox";
import ChatBoxHeader from "../../common_components/chat_box/ChatBoxHeader";
import {useTranslation} from "react-i18next";
import LoadingOverlay from "../../common_components/chats/chat_panel/LoadingOverlay";
import InnerBusinessHour from "../../common_components/chats/chat_panel/InnerBusinessHour";
import CustomFieldBox from "../../common_components/chats/chat_panel/CustomFieldBox";

const formDataDefault = {
    name: '',
    email: '',
    phone: '',
    queueId: '',
    message: ''
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        }
    }
};

function InitialForm(props) {
    const {t} = useTranslation();
    const params = useMemo(() => props.params || {}, [props.params]);

    const [formData, setFormData] = useState(cloneObject(formDataDefault));
    const [errorMessage, setErrorMessage] = useState({
        phone: '',
        email: ''
    });
    const [disableSubmitInput, setDisableSubmitInput] = useState(true);
    const [disableSubmitByCF, setDisableSubmitByCF] = useState(true);

    const dispatch = useDispatch();
    const selectedConversation = useSelector(selectChatConversationSelected);
    const chatProfile = useSelector(selectChatProfile);
    const location = useSelector(selectLocation);
    const queues = useSelector(selectQueues);
    const isLoading = useSelector(selectIsLoading);
    const isCheckBusinessHour = useSelector(selectIsCheckBusinessHour);
    const isShowInnerBusinessHour = useSelector(selectIsShowInnerBusinessHour);
    const customFields = useSelector(selectCustomFields);

    const StringeeClient = window.StringeeClient;
    const StringeeChat2 = window.StringeeChat2;

    useEffect(() => {
        let disabledByCF = false;

        if (customFields && customFields.length) {
            customFields.forEach((element) => {
                if (element.checkInvalid) {
                    disabledByCF = true;
                } else if (element.field_required) {
                    if (element.field_type === 'multi_select') {
                        if (!formData[element.id] || !formData[element.id].length) {
                            disabledByCF = true;
                        }
                    } else {
                        if (!formData[element.id]) {
                            disabledByCF = true;
                        }
                    }
                }
            });
        }

        setDisableSubmitByCF(disabledByCF);
    }, [customFields, formData]);

    const resetFormData = () => {
        setFormData(cloneObject(formDataDefault));
    };

    const handleCancel = () => {
        const postMessageData = {
            action: 'handleCancelForm'
        };
        window.parent.postMessage(postMessageData, "*");

        setTimeout(() => {
            resetFormData();
            props.hideChat();
        }, 200);
    };
    const saveDataLocalStorage = ({id, creator}) => {
        setChatStorage('ConvId', id);
        setChatStorage('CusUUID', creator);
        setChatStorage('QueueId', formData.queueId);
        setChatStorage('CusEmail', formData.email);
        setChatStorage('CusPhone', formData.phone);
        setChatStorage('CusName', formData.name);
        setChatStorage('CusWaitMsg', true);
    };

    async function updateCustomFields() {
        const convIdStorage = getChatStorage("ConvId");
        const convHasCustomField = getChatStorage("ConvIdCF");
        const fieldsData = {};
        for (const [fieldId, value] of Object.entries(formData)) {
            const fieldFound = customFields.find((customField) => {
                return customField.id === fieldId;
            });
            if (fieldFound) {
                if (fieldFound.field_type === 'cascader') {
                    const fieldOptions = fieldFound.options;
                    const treeValues = [];
                    function getAllOptionsParent(optionValue, options) {
                        const currentOption = options.find((option) => option.id === optionValue);
                        if (currentOption) {
                            treeValues.unshift(currentOption.id);
                            if (currentOption.custom_field_option_parent) {
                                getAllOptionsParent(currentOption.custom_field_option_parent, options);
                            }
                        }
                    }
                    getAllOptionsParent(value, fieldOptions);
                    fieldsData[fieldId] = treeValues;
                } else {
                    fieldsData[fieldId] = value;
                }
            }
        }
        if (convIdStorage && !convHasCustomField) {
            return apiStringeeX.updateCustomFields(convIdStorage, fieldsData);
        }
    }

    const handleSubmit = async () => {
        const isCheckBusinessHour = props.handleCheckBusinessHour(chatProfile);
        if (!isCheckBusinessHour) {
            dispatch(setIsShowInnerBusinessHour(true));

            if (formData.phone || formData.email) {
                await apiStringeeX.generateTicket({
                    key: params.key,
                    username: formData.name,
                    userId: crypto.randomUUID(),
                    email: formData.email,
                    phone: formData.phone,
                    note: formData.message
                });
            }
            return false;
        }

        setDisableSubmitInput(true);

        const {r, access_token: accessToken, public_api_stringee: publicApiStringee} = await fetchCustomerToken();
        if (r !== 0) {
            return "Error fetching token";
        }

        dispatch(setChatCustomerAccessToken(accessToken));
        dispatch(setPublicApiStringee(publicApiStringee));

        const client = await setupStringeeClient(accessToken);
        dispatch(setStringeeClient(client));
        await updateUserInfo(client);

        const showChatRoute = chatProfile.show_chat_route;
        const queueId = showChatRoute ? formData.queueId : chatProfile.chat_route;
        const createdConversation = await StringeeChat2
            .createLiveChatConversation(client, queueId, null, {serviceId: chatProfile.service_id});
        console.log("createdConversation chatBox: ", createdConversation);
        saveDataLocalStorage(createdConversation);
        if (createdConversation.id) {
            updateCustomFields();
        }

        // send first message
        const messagePostBody = {
            content: formData.message,
            metadata: ''
        };
        await createdConversation.sendMessage(chatTypeDefine.TEXT, messagePostBody);

        dispatch(setConvQueueId(queueId));
        if (chatProfile.enable_phone === 1) {
            dispatch(setCustomerPhone(formData.phone));
        }
        if (chatProfile.enable_email === 1) {
            dispatch(setCustomerEmail(formData.email));
        }

        dispatch(setConversationSelected(createdConversation));
        resetFormData();
    };

    const fetchCustomerToken = useCallback((data = {}) => {
        const userId = data.userId || crypto.randomUUID();
        const getTokenParam = {
            key: params.key,
            userId,
            username: data.name || formData.name,
            email: data.email || formData.email
        };

        return apiStringeeX.getChatCustomerData(getTokenParam);
    }, [params, formData]);

    const setupStringeeClient = useCallback((accessToken) => {
        return new Promise((resolve, reject) => {
            const client = new StringeeClient(STRINGEE_SERVER_ADDRS, API_STRINGEE_COM_BASE_URL);

            client.connect(accessToken);
            StringeeChat2.init(client);

            client.on('connect', function() {
                console.log('connected');
            });

            client.on('authen', function(res) {
                console.log('authen', res);
                dispatch(setAuthUser(res));
                dispatch(setStringeeClient(client));
                dispatch(setConnected(true));
                resolve(client);
            });

            client.on('disconnect', function(res) {
                console.log('disconnected', res);
            });
        });
    }, [StringeeChat2, StringeeClient, dispatch]);

    const updateUserInfo = async (client) => {
        const browserInfo = detect.parse(window.navigator.userAgent);
        const body = {
            display_name: formData.name,
            avatar_url: '',
            email: formData.email,
            phone: formData.phone,
            location: location.city + ',' + location.country,
            browser: browserInfo.browser.family + " v" + browserInfo.browser.version,
            platform: browserInfo.os.family,
            device: browserInfo.device.type,
            ipaddress: location.query,
            hostname: window.location.protocol + "//" + window.location.hostname,
            useragent: window.navigator.userAgent
        };

        const resUpdateUserInfo = await StringeeChat2.updateUserInfo(client, body);
        console.log('res updateUserInfo: ', resUpdateUserInfo);

        if (resUpdateUserInfo.r !== 0) {
            return 'error';
        }
    };

    const fetchData = useCallback(async () => {
        dispatch(setIsLoading(true));
        const {r, access_token: accessToken, public_api_stringee: publicApiStringee} =
            await fetchCustomerToken(params);

        if (r !== 0) {
            console.error("Error fetching token");
            return;
        }

        dispatch(setChatCustomerAccessToken(accessToken));
        dispatch(setPublicApiStringee(publicApiStringee));

        const client = await setupStringeeClient(accessToken);
        dispatch(setStringeeClient(client));

        if (params.convId) {
            try {
                const listConvs = await StringeeChat2.getConversationsByIds(client, [params.convId]);
                console.log('chatbox popup conversationInfo: ', listConvs);

                const convInfo = listConvs[0];
                dispatch(setConversationSelected(convInfo));
            } catch (error) {
                console.error('Error fetching conversation info:', error);
            }
        }

        if (params.queue) {
            dispatch(setConvQueueId(params.queue));
        }

        if (params.email) {
            dispatch(setCustomerEmail(params.email));
        }

        if (params.phone) {
            dispatch(setCustomerPhone(params.phone));
        }

        dispatch(setIsVolumeUp(params.noti !== '0'));

        dispatch(setIsLoading(false));
    }, [fetchCustomerToken, setupStringeeClient, dispatch, StringeeChat2, params]);

    useEffect(() => {
        if (params.isOldCustomer) {
            fetchData();
        }

        // eslint-disable-next-line
    }, [params.isOldCustomer]);

    useEffect(() => {
        if (params.popup) {
            dispatch(setIsPopup(true));
            fetchData();
        }

        // eslint-disable-next-line
    }, [params, dispatch]);

    const checkDisableSubmitInput = useCallback((newFormData) => {
        let disabled = false;

        if (!newFormData.name || !newFormData.message) {
            disabled = true;
        }

        if (chatProfile.enable_phone === 1 && (!newFormData.phone || !validatePhone(newFormData.phone))) {
            disabled = true;
        }

        if (chatProfile.enable_email === 1 && (!newFormData.email || !validateEmail(newFormData.email))) {
            disabled = true;
        }

        if (chatProfile.show_chat_route === 1 && !newFormData.queueId) {
            disabled = true;
        }

        setDisableSubmitInput(disabled);
    }, [chatProfile]);

    const onChangeFormData = useCallback((e, key) => {
        const value = e.target.value;
        const newFormData = {...formData, [key]: value};
        setFormData(newFormData);

        // check validate form
        switch (key) {
            case 'phone':
                setErrorMessage({
                    ...errorMessage,
                    phone: (value && !validatePhone(value)) ? t('chatbox.invalid_phone_number') : ""
                });
                break;
            case 'email':
                setErrorMessage({
                    ...errorMessage,
                    email: value && !validateEmail(value) ? t('chatbox.invalid_email_address') : ""
                });
                break;
            default:
                break;
        }

        checkDisableSubmitInput(newFormData);
    }, [formData, errorMessage, checkDisableSubmitInput, t]);

    if (selectedConversation && selectedConversation.id) {
        return <>
            <ChatBoxHeader/>
            <Container maxWidth={false} className="stringee-chat__container is-customer">
                <ContentConversation/>
            </Container>
        </>;
    }

    if (isLoading) {
        return <LoadingOverlay/>;
    }

    if (isShowInnerBusinessHour) {
        return <InnerBusinessHour></InnerBusinessHour>;
    }

    return (
        <Container maxWidth={false} className="stringee-chat-box__container">
            <div className="user_form">
                {!isCheckBusinessHour && <div className="out-opearting-hour">
                    {t('chatbox.out_business_hour')}
                </div>}

                <Box component="form">
                    <Box>
                        <label>{t('chatbox.your_name')}&nbsp;<span className="text-danger">*</span></label>
                        <TextField
                            fullWidth
                            value={formData.name}
                            size="small"
                            placeholder={t('chatbox.your_name')}
                            onChange={(e) => onChangeFormData(e, 'name')}
                        />
                    </Box>
                    {chatProfile.enable_phone === 1 && (
                        <Box>
                            <label>{t('chatbox.phone_number')}&nbsp;<span className="text-danger">*</span></label>
                            <TextField
                                error={!!errorMessage.phone}
                                fullWidth
                                size="small"
                                value={formData.phone}
                                placeholder="091 234 56 78"
                                onChange={(e) => onChangeFormData(e, 'phone')}
                                helperText={errorMessage.phone}
                            />
                        </Box>
                    )}
                    {chatProfile.enable_email === 1 && (
                        <Box>
                            <label>{t('chatbox.email_address')}&nbsp;<span className="text-danger">*</span></label>
                            <TextField
                                error={!!errorMessage.email}
                                fullWidth
                                size="small"
                                placeholder="your@email.com"
                                value={formData.email}
                                onChange={(e) => onChangeFormData(e, 'email')}
                                helperText={errorMessage.email}
                            />
                        </Box>
                    )}
                    {chatProfile.show_chat_route === 1 && (
                        <Box>
                            <label>{t('chatbox.select_queue')}&nbsp;<span className="text-danger">*</span></label>
                            <Select
                                fullWidth
                                size="small"
                                value={formData.queueId}
                                placeholder={t('chatbox.select_queue')}
                                MenuProps={MenuProps}
                                onChange={(e) => onChangeFormData(e, 'queueId')}
                            >
                                {queues.map((queue) => (
                                    <MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
                                ))}
                            </Select>
                        </Box>
                    )}
                    <CustomFieldBox formData={formData} setFormData={setFormData}></CustomFieldBox>
                    <Box>
                        <label>
                            {t('chatbox.first_msg')}&nbsp;
                            <span className="text-danger">*</span>
                        </label>
                        <TextField
                            rows={4}
                            multiline
                            fullWidth
                            size="small"
                            value={formData.message}
                            placeholder={t('chatbox.pl_first_msg')}
                            onChange={(e) => onChangeFormData(e, 'message')}
                        />
                    </Box>
                </Box>
            </div>
            <div className="form_actions">
                <div className="top">
                    <button className="btn btn-sm" id="btn_cancel_form" onClick={handleCancel}>
                        {t('chatbox.btn_cancel')}
                    </button>
                    <button
                        className="btn btn-sm"
                        id="btn_submit_form"
                        onClick={handleSubmit}
                        disabled={disableSubmitInput || disableSubmitByCF}
                    >{t('chatbox.btn_submit')}</button>
                </div>
                <div className="bottom stringeex">
                    <a target="_blank" href="https://stringeex.com/" rel="noreferrer">StringeeX</a>
                </div>
            </div>
        </Container>

    );
}

export default InitialForm;
