import {createSlice} from "@reduxjs/toolkit";

// Redux toolkit cho phép định nghĩa state (VD: "pageIsError"), định nghĩa các reducers/action (VD: setPageIsError)
// ngay trong 1 file JS, cho phép code dễ bảo trì hơn

// định nghĩa giá trị khởi tạo của state
// value=true tức là trang đang render có lỗi
// r/msg: thông tin chi tiết về lỗi
const initialState = {
    auth_user: {}
};

// định nghĩa state name, reducers, actions
const slice = createSlice({
    name: "chatAuthUser",
    initialState,
    reducers: {
        // action public ra để dùng là: setPageIsError(true/false)
        setAuthUser: (state, action) => {
            state.auth_user = action.payload;
        }
    }
});

// export state, actions, reducer để các components sử dụng
// lưu ý: quy định đặt tên giống như sample này để dễ hiểu, dễ bảo trì code
export const selectAuthUser = (state) => state.chatAuthUser.auth_user;
export const {setAuthUser} = slice.actions;
export default slice.reducer;
