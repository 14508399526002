import ApiBase from "./ApiBase";
import {API_STRINGEEX_COM_BASE_URL} from "../common/config";
import {CHAT_CUSTOMER_WIDGET_KEY} from "../common/sample";
import {setChatStorage} from "../common/common";

class ApiStringeeX extends ApiBase {
    constructor() {
        super();
        this.baseUrl = API_STRINGEEX_COM_BASE_URL;
    }

    setAuthToken(accessToken) {
        this.authToken = accessToken;
    }

    getChatCustomerData(data) {
        console.log("+++++++++ getTokenRestApi handle with params", data);
        const params = new URLSearchParams(data).toString();

        return this.httpGet("/v1/chatcustomer?" + params);
    }

    getListChatTemplate(search = '') {
        const data = {
            type: 0,
            search: search,
            limit: 200,
            page: 1,
            sort_order: 'desc',
            sort_by: 'created'
        };

        console.log("+++++++++ getListChatTemplate with search: ", search);
        const params = new URLSearchParams(data).toString();

        return this.httpGet("/v1/chattemplate?" + params);
    }

    async getContactByConversation(convId) {
        const res = await this.httpGet("/v1/ticketNote/get-contact-by-conversation?conversation_id=" + convId);

        console.log('++++++getContactByConversation res: ', res);

        if (res.r === 0) {
            return res.contact_id;
        } else {
            console.log('++++++getContactByConversation err: ', res.msg);
            return null;
        }
    }

    async getContactById(contactId) {
        const data = {
            version: 2,
            id: contactId,
            dir: "ltr"
        };
        const params = new URLSearchParams(data).toString();

        const res = await this.httpGet("/v1/contact?" + params);

        console.log('++++++getContactById res: ', res);

        if (res.r === 0) {
            if (res.data && res.data.rows && res.data.rows.length > 0) {
                return res.data.rows[0];
            } else {
                return null;
            }
        } else {
            console.log('++++++getContactById err: ', res.msg);
            return null;
        }
    }

    async fetchContactCustomFields() {
        const res = await this.httpGet("/v1/field/list?object_type=contact");

        console.log('++++++fetchContactCustomFields res: ', res);

        if (res.r === 0) {
            return res.data;
        } else {
            console.log('++++++getContactByConversation err: ', res.msg);
            return null;
        }
    }

    async updateCustomFields(conversationId, customFields) {
        try {
            const payload = {
                convId: conversationId,
                customField: customFields
            };
            const headers = {
                'Content-Type': 'multipart/form-data'
            };
            const res = await this
                .httpPost('/v1/chatcustomer/updatecustomfield?key=' + CHAT_CUSTOMER_WIDGET_KEY, payload, headers);
            console.log('++++++updateCustomFields res: ', res);
            const errorCode = res.r;
            if (errorCode === 0) {
                setChatStorage('ConvIdCF', conversationId);
                return true;
            }
            return false;
        } catch (e) {
            console.log('++++++updateCustomFields err: ', e);
            return false;
        }
    }
    
    async generateTicket(data = {}) {
        console.log("===> showOutOfBusinessHour", data);
        const params = new URLSearchParams(data).toString();
        return this.httpGet("/v1/chatcustomer/generateticket?" + params);
    }
}

export const apiStringeeX = new ApiStringeeX();
