import {useDispatch, useSelector} from "react-redux";
import {
    selectFocusedIndex,
    selectIsLoadingChatTemplate, selectIsReloadContactData,
    selectListChatTemplate,
    selectShowTemplate, setFocusedIndex, setIsLoadingChatTemplate, setIsReloadContactData,
    setListChatTemplate,
    setShowTemplate
} from "../../../store/chatTemplate";
import {
    Box,
    CircularProgress,
    ClickAwayListener,
    List,
    ListItemButton,
    ListItemText
} from "@mui/material";
import "./../../../styles/scss/chat_template.scss";
import {selectMsgInput, setMsgInput, setSelectedFiles} from "../../../store/messageField";
import {useCallback, useEffect, useRef, useState} from "react";
import useDebounce from "../../../common/useDebounce";
import {apiStringeeX} from "../../../api/ApiStringeeX";
import {BASE_URL} from "../../../common/config";
import {selectChatConversationSelected} from "../../../store/chatConversationSelected";
import {replaceTemplateVariables} from "../../../common/common";
import {useTranslation} from "react-i18next";
import {styled} from '@mui/material/styles';
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';

const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500
    }
});

function ChatTemplate(props) {
    const {t} = useTranslation();
    const IconEmptyData = BASE_URL + "/assets/images/empty-data.svg";

    const [contact, setContact] = useState({});
    const [contactCF, setContactCF] = useState({});
    const chatTemplateListRef = useRef(null);

    const dispatch = useDispatch();
    const chatConversationSelected = useSelector(selectChatConversationSelected);
    const listChatTemplate = useSelector(selectListChatTemplate);
    const msgInput = useSelector(selectMsgInput);
    const debouncedValue = useDebounce(msgInput, 300);
    const showTemplate = useSelector(selectShowTemplate);
    const focusedIndex = useSelector(selectFocusedIndex);
    const isLoadingChatTemplate = useSelector(selectIsLoadingChatTemplate);
    const isReloadContactData = useSelector(selectIsReloadContactData);

    const fetchContactCustomFields = useCallback(async () => {
        const contactCustomFields = await apiStringeeX.fetchContactCustomFields();

        if (contactCustomFields) {
            setContactCF(contactCustomFields);
        }
    }, []);

    useEffect(() => {
        fetchContactCustomFields();
    }, [fetchContactCustomFields]);

    const getContactData = useCallback(async () => {
        const contactId = await apiStringeeX.getContactByConversation(chatConversationSelected.id);

        if (contactId) {
            const contact = await apiStringeeX.getContactById(contactId);

            if (contact) {
                setContact(contact);
            }
        }
    }, [chatConversationSelected.id]);

    useEffect(() => {
        getContactData();
    }, [chatConversationSelected.id, getContactData]);

    useEffect(() => {
        if (isReloadContactData) {
            dispatch(setIsReloadContactData(false));
            getContactData();
        }
    }, [isReloadContactData, getContactData]);

    const handleSearch = useCallback(() => {
        if (msgInput && msgInput.startsWith("/")) {
            dispatch(setShowTemplate(true));
            dispatch(setIsLoadingChatTemplate(true));

            apiStringeeX.getListChatTemplate(msgInput.slice(1))
                .then((res) => {
                    console.log("+++ getListChatTemplate res", res);

                    if (res.r === 0) {
                        dispatch(setListChatTemplate(res.template.templates));

                        if (res.template.templates && res.template.templates.length) {
                            dispatch(setFocusedIndex(0));
                        }
                    }

                    dispatch(setIsLoadingChatTemplate(false));
                })
                .catch((err) => {
                    console.log("+++ getListChatTemplate error", err);
                    dispatch(setIsLoadingChatTemplate(false));
                });
        } else {
            dispatch(setShowTemplate(false));
        }
    }, [msgInput, dispatch]);

    useEffect(() => {
        handleSearch();
        // eslint-disable-next-line
    }, [debouncedValue]);

    const showChatTemplateManager = () => {
        const postMessageData = {
            action: 'showChatTemplateManager'
        };
        window.parent.postMessage(postMessageData, "*");
    };

    const selectChatTemplate = useCallback((template) => {
        let content = template.content;
        if (content) {
            content = replaceTemplateVariables(content, contact, contactCF);
            dispatch(setMsgInput(content));
        } else {
            dispatch(setMsgInput(''));
        }

        const files = template.files;
        if (Array.isArray(files) && files.length) {
            dispatch(setSelectedFiles(files));
        } else {
            dispatch(setSelectedFiles([]));
        }

        // focus vào boxchat khi chọn xong mẫu tin nhắn
        const inputSendMsgElement = document.getElementById('inputSendMessage');
        if (inputSendMsgElement) {
            inputSendMsgElement.focus();
        }

        dispatch(setShowTemplate(false));
    }, [contact, contactCF, dispatch]);

    const hideChatTemplate = () => {
        dispatch(setShowTemplate(false));
    };

    const handleMouseOver = (index) => {
        if (index !== null) {
            dispatch(setFocusedIndex(index));
        }
    };

    const handleKeyDown = useCallback((e) => {
        if (!showTemplate || !listChatTemplate || !listChatTemplate.length) {
            return false;
        }

        const isEnterKey = e.key === 'Enter' || e.keyCode === 13;

        if (e.key === 'ArrowUp') {
            const newIndex = focusedIndex === 0 ? listChatTemplate.length - 1 : Math.max(0, focusedIndex - 1);
            dispatch(setFocusedIndex(newIndex));
        } else if (e.key === 'ArrowDown') {
            const newIndex = focusedIndex === listChatTemplate.length - 1 ?
                0 : Math.min(listChatTemplate.length - 1, focusedIndex + 1);
            dispatch(setFocusedIndex(newIndex));
        } else if (isEnterKey) {
            if (typeof listChatTemplate[focusedIndex] !== 'undefined') {
                selectChatTemplate(listChatTemplate[focusedIndex]);
            }
        }
    }, [focusedIndex, listChatTemplate, selectChatTemplate, showTemplate, dispatch]);

    useEffect(() => {
        // Sử dụng sự kiện của document để theo dõi mũi tên
        const handleDocumentKeyDown = (e) => {
            handleKeyDown(e);
        };

        document.addEventListener('keydown', handleDocumentKeyDown);

        // Hủy đăng ký sự kiện khi component unmount
        return () => {
            document.removeEventListener('keydown', handleDocumentKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        // Thực hiện cuộn để item đang được focus hiển thị trong tầm nhìn
        if (chatTemplateListRef.current) {
            const itemElement = chatTemplateListRef.current.children[focusedIndex];
            if (itemElement) {
                itemElement.scrollIntoView({
                    block: 'nearest',
                    inline: 'nearest'
                });
            }
        }
    }, [focusedIndex]);

    if (!showTemplate) {
        return null;
    }

    return <ClickAwayListener onClickAway={hideChatTemplate}>
        <Box className="chat-template">
            <Box className="chat-template__header">
                <label className="chat-template__title">{t('template.chat_template')}</label>
                <div onClick={showChatTemplateManager} className="chat-template__manager">
                    {t('template.manager')}
                </div>
            </Box>
            {isLoadingChatTemplate ?
                <Box className="chat-template__loading">
                    <CircularProgress />
                </Box> :
                listChatTemplate && listChatTemplate.length ?
                    <List className="chat-template__list" ref={chatTemplateListRef}>
                        {
                            listChatTemplate.map((template, index) => (
                                <ListItemButton
                                    alignItems="flex-start"
                                    className={"chat-template__item" +
                                        (focusedIndex === index ? " is-focus" : "")}
                                    onClick={() => selectChatTemplate(template)}
                                    onMouseOver={() => handleMouseOver(index)}
                                    key={template.id}
                                >
                                    <ListItemText
                                        primary={'/' + template.title}
                                        secondary={
                                            <CustomWidthTooltip title={template.content}>
                                                <span className="display-block">{template.content}</span>
                                            </CustomWidthTooltip>
                                        }
                                    />
                                </ListItemButton>
                            ))
                        }
                    </List> :
                    <Box className="chat-template__empty">
                        <img src={IconEmptyData} alt=""/>
                        <div>{t('template.no_data')}</div>
                    </Box>
            }
        </Box>
    </ClickAwayListener>;
}

export default ChatTemplate;
