import {Box, Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import {forwardRef, useImperativeHandle, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectChatConversationSelected, setIsReloadConvContent} from "../../../store/chatConversationSelected";
import Slide from "@mui/material/Slide";
import * as React from 'react';
import {ThumbDown, ThumbDownOffAltOutlined, ThumbUp, ThumbUpOffAltOutlined} from "@mui/icons-material";
import {FormControl, FormHelperText, FormLabel, Textarea} from "@mui/joy";
import {selectRating, setRating} from "../../../store/chatbox";
import {ratingTypeDefine} from "../../../common/constants";
import {red} from "@mui/material/colors";
import {useTranslation} from "react-i18next";

const Transition = React.forwardRef(function Transition(
    props,
    ref,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalChatReview = forwardRef((props, ref) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const chatConversationSelected = useSelector(selectChatConversationSelected);
    const currentRating = useSelector(selectRating);

    const [ratingInput, setRatingInput] = useState(currentRating);
    const [open, setOpen] = useState(false);
    const [commentInput, setCommentInput] = useState('');

    const maxLengthComment = 120;
    const disableRating = [ratingTypeDefine.GOOD, ratingTypeDefine.BAD].includes(currentRating);

    useImperativeHandle(ref, () => ({
        show() {
            setOpen(true);
        }
    }));

    const onChangeCommentInput = (event) => {
        let comment = event.target.value;

        if (comment && comment.length > maxLengthComment) {
            comment = comment.substring(0, maxLengthComment);
        }

        setCommentInput(comment);
    };

    const onSelectRating = (rating) => {
        setRatingInput(rating);
    };

    const save = () => {
        chatConversationSelected.rateChat(ratingInput, commentInput).then((res) => {
            console.log('rateChat res++++++++', res);
            dispatch(setRating(ratingInput));

            chatConversationSelected.endChat().then((res) => {
                console.log('end chat res: ', res);

                if (res.r === 0) {
                    dispatch(setIsReloadConvContent(true));
                    setRatingInput(ratingTypeDefine.NOT_RATING);
                    setCommentInput('');
                    setOpen(false);
                }
            });
        }).catch((err) => {
            console.log('rateChat err++++++++', err);
        });
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullScreen
                TransitionComponent={Transition}
            >
                <DialogContent>
                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                        <FormLabel>{t('chatbox.pls_rate_chat')}</FormLabel>
                        <Box>
                            <Button
                                disabled={disableRating}
                                component="label"
                                onClick={() => onSelectRating(ratingTypeDefine.GOOD)}
                                sx={{minWidth: "unset"}}
                            >
                                {ratingInput === ratingTypeDefine.GOOD ?
                                    <ThumbUp sx={{color: "#1976d2"}}/> :
                                    <ThumbUpOffAltOutlined color="disabled"/>
                                }
                            </Button>
                            <Button
                                disabled={disableRating}
                                component="label"
                                onClick={() => onSelectRating(ratingTypeDefine.BAD)}
                                sx={{minWidth: "unset"}}
                            >
                                {ratingInput === ratingTypeDefine.BAD ?
                                    <ThumbDown sx={{color: red[400]}}/> :
                                    <ThumbDownOffAltOutlined color="disabled"/>
                                }
                            </Button>
                        </Box>
                    </Box>
                    <FormControl sx={{marginTop: "1rem"}}>
                        <FormLabel>{t('chatbox.leave_comment', {max: maxLengthComment})}</FormLabel>
                        <Textarea minRows={4} onChange={onChangeCommentInput} value={commentInput}/>
                        <FormHelperText>
                            {maxLengthComment - commentInput.length}&nbsp;{t('chatbox.limit_comment')}
                        </FormHelperText>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t('chatbox.btn_cancel')}</Button>
                    <Button
                        disabled={![ratingTypeDefine.GOOD, ratingTypeDefine.BAD].includes(ratingInput)}
                        onClick={save}
                    >{t('chatbox.btn_submit')}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default ModalChatReview;
