import {Avatar, Box, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {useSelector} from "react-redux";
import {
    selectChatConversationSelected,
    selectChatConversationSelectedInfo
} from "../../../store/chatConversationSelected";
import SystemMessage from "../messages/SystemMessage";
import {formatDateTimeInConvList, isEmpty, stringAvatar} from "../../../common/common";
import {CHAT_STATUS_MAP, chatTypeDefine} from "../../../common/constants";
import {useEffect, useState} from "react";
import {BASE_URL} from "../../../common/config";
import i18n from "../../../common/i18n";
import {selectStringeeClient} from "../../../store/chatStringeeClient";

const ZaloIcon = BASE_URL + '/assets/images/zalo-icon.png';
const MessengerIcon = BASE_URL + '/assets/images/messenger-icon.png';
const LivechatIcon = BASE_URL + '/assets/images/livechat-icon.png';
const conversationIconMap = {
    1: LivechatIcon,
    2: MessengerIcon,
    3: ZaloIcon
};

function ConversationStatus(props) {
    const conversation = props.conversation;
    const chatConversationSelectedInfo = useSelector(selectChatConversationSelectedInfo);
    const [chatStatusData, setChatStatusData] = useState(CHAT_STATUS_MAP[conversation.chatStatus]);

    useEffect(() => {
        if (chatConversationSelectedInfo && chatConversationSelectedInfo.id === conversation.id) {
            setChatStatusData(CHAT_STATUS_MAP[chatConversationSelectedInfo.chatStatus]);
        }
    }, [chatConversationSelectedInfo, chatConversationSelectedInfo.chatStatus, conversation.id]);

    useEffect(() => {
        setChatStatusData(CHAT_STATUS_MAP[conversation.chatStatus]);
    }, [conversation.chatStatus]);

    if (!chatStatusData || isEmpty(chatStatusData)) {
        return null;
    }

    return <span className="chat-item__status" style={{background: chatStatusData.background}}>
        {i18n.t(chatStatusData.label)}
    </span>;
}

function ConversationLastMessage({conversation}) {
    const message = conversation.lastMessage;
    const sender = message.sender;
    const [senderName, setSenderName] = useState('');
    const [messageContent, setMessageContent] = useState('');
    const client = useSelector(selectStringeeClient);

    useEffect(() => {
        const fetchSenderName = async () => {
            if (Object.values(chatTypeDefine).includes(message.type)) {
                if (conversation.participants && conversation.participants.length) {
                    const participant = conversation.participants.find((p) => p.user === sender);
                    if (participant) {
                        setSenderName(participant.name || participant.user);
                        return;
                    }
                }

                window.StringeeChat2.getUsersInfo(client, [sender],
                    function(status, code, message, users) {
                        if (users && users.length) {
                            const user = users.find((u) => u.userId === sender);
                            if (user) {
                                setSenderName(user.name);
                            }
                        }
                    });
            }
        };

        fetchSenderName();
    }, [client, conversation.participants, message.type, sender]);

    useEffect(() => {
        switch (message.type) {
            case chatTypeDefine.IMAGE:
                setMessageContent(senderName + i18n.t("list.sent_a_img"));
                break;
            case chatTypeDefine.VIDEO:
                setMessageContent(senderName + i18n.t("list.sent_a_video"));
                break;
            case chatTypeDefine.AUDIO:
                setMessageContent(senderName + i18n.t("list.sent_a_audio"));
                break;
            case chatTypeDefine.FILE:
                setMessageContent(senderName + i18n.t("list.sent_a_file"));
                break;
            default:
                setMessageContent(senderName + ": " + message.content.content);
        }
    }, [senderName, message]);

    if (!Object.values(chatTypeDefine).includes(message.type)) {
        return <SystemMessage messageData={message} isListItem={true}/>;
    }

    return (
        <>
            <span className="chat-item__last-msg">
                {messageContent}
            </span>
        </>
    );
}

function ConversationListItem(props) {
    const conversation = props.conversation;

    const chatConversationSelected = useSelector(selectChatConversationSelected);
    const client = useSelector(selectStringeeClient);
    const creatorName = client.isInternal ? conversation.name :
        conversation.creatorData ? (conversation.creatorData.name || conversation.creatorData.user) :
            conversation.creator;
    const creatorAvatar = (conversation.creatorData && conversation.creatorData.avatar) ?
        conversation.creatorData.avatar : null;

    return <ListItemButton
        disableGutters
        onClick={() => props.onSelectConv(conversation)}
        selected={chatConversationSelected && chatConversationSelected.id === conversation.id}
        className="conversation-item"
    >
        <ListItemAvatar className="chat-item__avatar">
            {creatorAvatar ?
                <Avatar alt="" src={creatorAvatar}/> :
                <Avatar {...stringAvatar(creatorName)}/>
            }

            <img
                className="chat-item__channel-icon"
                src={conversationIconMap[conversation.channelType] || LivechatIcon}
                alt=""
            />
        </ListItemAvatar>

        <ListItemText>
            <Box className="chat-item__primary">
                <span className="chat-item__name">
                    {creatorName}
                </span>
                <span className="chat-item__info">
                    {!client.isInternal && <ConversationStatus conversation={conversation}/>}
                    <span className="chat-item__time">
                        {formatDateTimeInConvList(
                            conversation.lastMessage.created ||
                            conversation.lastUpdate ||
                            Date.now())
                        }
                    </span>
                </span>
            </Box>
            <Box className="chat-item__secondary">
                <ConversationLastMessage conversation={conversation}/>
                {!conversation.unread ? null :
                    <span className="chat-item__unread">
                        {conversation.unread <= 5 ? conversation.unread : '5+'}
                    </span>
                }
            </Box>
        </ListItemText>
    </ListItemButton>;
}

export default ConversationListItem;
