import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectNotifyMessage, setNotifyMessage} from "../../../store/notifyMessage";
import {Alert} from "@mui/material";

export default function Notification() {
    const [state, setState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    });
    const {vertical, horizontal, open} = state;
    const notifyMessage = useSelector(selectNotifyMessage);
    const dispatch = useDispatch();

    useEffect(() => {
        if (notifyMessage) {
            setState((prevState) => ({...prevState, open: true}));
        }
    }, [notifyMessage]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setState({...state, open: false});
        dispatch(setNotifyMessage(''));
    };

    return (
        <Snackbar
            anchorOrigin={{vertical, horizontal}}
            open={open}
            onClose={handleClose}
            key={vertical + horizontal}
            autoHideDuration={5000}
        >
            <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                {notifyMessage}
            </Alert>
        </Snackbar>
    );
}
