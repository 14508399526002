import {setConversationSelected} from "../../../store/chatConversationSelected";
import {useDispatch} from "react-redux";
import i18n from "../../../common/i18n";

function ConversationNavigation(props) {
    const isNext = props.isNext || false;
    const conv = props.conv || null;
    const dispatch = useDispatch();

    const onChangeConv = () => {
        dispatch(setConversationSelected(conv));
    };

    if (!conv) {
        return null;
    }

    return (
        <div className="conversation-message-item msg-paginate" style={{
            top: isNext ? '0' : 'unset',
            bottom: isNext ? 'unset' : '0'
        }}>
            <button type="button" className="btn-paginate-conv" onClick={onChangeConv}>
                <span>{i18n.t(isNext ? "message.to_next_conv" : "message.to_previous_conv")}</span>
            </button>
        </div>
    );
}

export default ConversationNavigation;
