import {useCallback, useEffect, useRef, useState} from "react";
import {
    selectChatConversationSelected, selectChatConversationSelectedInfo,
    setConversationSelected, setConversationSelectedInfo, setIsOnlyReloadConvInfo,
    setIsReloadConvContent, setUnreadMsgCount
} from "../../store/chatConversationSelected";
import {cloneObjectWithPrototype, getChatStorage, isEmpty, setChatStorage} from "../../common/common";
import {
    selectChatConversationList,
    setChatConversationList,
    unshiftItemToChatConversationList
} from "../../store/chatConversationList";
import {setUserTypingIndicator} from "../../store/chatGeneral";
import {
    selectConversationMessageList,
    selectIsMarkReadMsgByScroll, setIsMarkReadMsgByScroll,
    unshiftItemToConversationMessageList
} from "../../store/conversationMessageList";
import {useDispatch, useSelector} from "react-redux";
import {selectStringeeClient} from "../../store/chatStringeeClient";
import {selectAuthUser} from "../../store/chatAuthUser";
import {
    selectChatProfile,
    selectConnected,
    selectIsCustomer,
    selectShowChat,
    setAgentReceivesConvName, setIsChatBot,
    setRating
} from "../../store/chatbox";
import {CHAT_BOT_USER_ID, chatContentTypeDefine, ratingTypeDefine} from "../../common/constants";
import {BASE_URL} from "../../common/config";

function ChatEventHandle() {
    // const [connected, setConnected] = useState(false);
    const [isUserActive, setIsUserActive] = useState(true);

    const chatConversationListRef = useRef([]);
    const convSelectedRef = useRef(null);
    const convSelectedInfoRef = useRef(null);
    const conversationMessageListRef = useRef([]);
    const authUserRef = useRef({});
    const isUserActiveRef = useRef(isUserActive);

    const dispatch = useDispatch();
    const client = useSelector(selectStringeeClient);
    const chatConversationList = useSelector(selectChatConversationList);
    const chatConversationSelected = useSelector(selectChatConversationSelected);
    const chatConversationSelectedInfo = useSelector(selectChatConversationSelectedInfo);
    const conversationMessageList = useSelector(selectConversationMessageList);
    const authUser = useSelector(selectAuthUser);
    const connected = useSelector(selectConnected);
    const isCustomer = useSelector(selectIsCustomer);
    const chatProfile = useSelector(selectChatProfile);
    const showChat = useSelector(selectShowChat);
    const isMarkReadMsgByScroll = useSelector(selectIsMarkReadMsgByScroll);

    useEffect(() => {
        chatConversationListRef.current = chatConversationList;
    }, [chatConversationList]);

    useEffect(() => {
        convSelectedRef.current = chatConversationSelected;
    }, [chatConversationSelected]);

    useEffect(() => {
        convSelectedInfoRef.current = chatConversationSelectedInfo;
    }, [chatConversationSelectedInfo]);

    useEffect(() => {
        conversationMessageListRef.current = conversationMessageList;
    }, [conversationMessageList]);

    useEffect(() => {
        authUserRef.current = authUser;
    }, [authUser]);

    const appendMessage = useCallback((message) => {
        console.log('appendMessage', message);

        for (let i = conversationMessageListRef.current.length - 1; i >= 0; i--) {
            if (conversationMessageListRef.current[i].id === message.id) {
                return;
            }
        }

        if (message.channelType === 3) { // zalo
            if (!message.user_name) {
                message.user_name = 'Visitor ' + message.sender;
            }
        }

        dispatch(unshiftItemToConversationMessageList(message));
    }, [dispatch]);

    const changeMessageState = useCallback((event) => {
        if (!convSelectedInfoRef.current || !convSelectedInfoRef.current.id) {
            return;
        }

        console.log('changemessagestate+++++++++', event);
        const participant = event.participant;
        const authUserId = authUserRef.current.id || authUserRef.current.userId;

        if (participant.conv.id === convSelectedInfoRef.current.id && participant.user !== authUserId) {
            const convInfo = cloneObjectWithPrototype(convSelectedInfoRef.current);

            if (event.status === "MsgSeen" || event.type === "MsgReceived") {
                console.log("MsgSeen", event);
                convInfo.lastMsgSeqSeen = event.participant.lastMsgSeqSeen;
                convInfo.lastMsgSeqReceived = event.participant.lastMsgSeqReceived;

                dispatch(setConversationSelectedInfo(convInfo));
            }
        }
    }, [dispatch]);

    const addChatMessage = useCallback((message) => {
        console.log('addchatmessage', message);
        const conv = message.conv;

        // xem trong mang convs co chua
        const convIndex = chatConversationListRef.current.findIndex((c) => c.id === conv.id);
        const authUserId = authUserRef.current.id || authUserRef.current.userId;

        if (!isCustomer && convIndex === -1) {
            // nếu conv chưa có trong list convs
            dispatch(unshiftItemToChatConversationList(conv));

            if (message.content) {
                // message.content.creator === authUserId khi agent continue chat
                // message.content.addedby === authUserId khi agent join / accept chat
                if (message.content.creator === authUserId || message.content.addedby === authUserId) {
                    if (window.displayMode === 'full') {
                        dispatch(setConversationSelected(conv));
                    }
                }
            }
        } else {
            // xử lý nhận và đọc tin nhắn
            if (convSelectedRef.current && conv.id === convSelectedRef.current.id) {
                // tin nhan den dung man hinh dang xem

                const listMsgElement = document.getElementById('scrollableDivContentConv');

                if (isUserActiveRef.current && !document.hidden && listMsgElement && listMsgElement.scrollTop >= -5) {
                    // check xem có đang active ở chat không
                    conv.unread = 0;

                    conv.markRead(message);
                } else {
                    dispatch(setUnreadMsgCount(conv.unread));
                    conv.markReceived(message);
                }
            } else {
                // nếu ko phải conv đang selected thì bắn sự kiện ra ngoài để update pin counter
                if (window.parent) {
                    window.parent.postMessage({
                        action: "updateUnreadConvPinCounter",
                        data: {
                            convId: conv.id,
                            unread: conv.unread
                        }
                    }, '*');
                }
            }

            if (!isCustomer) {
                // đổi trạng thái conv là tin nhắn đổi trạng thái (do sdk chưa tự update)
                if (message.content && message.content.type === chatContentTypeDefine.CHAT_CONVERSATION_STATUS_CHANGE) {
                    if (message.content.newStatus) {
                        conv.chatStatus = message.content.newStatus;
                    }
                }

                // đưa conv nhận được tin nhắn lên đầu list conv
                const convListTemp = [...chatConversationListRef.current];
                convListTemp.splice(convIndex, 1);
                convListTemp.unshift(conv);
                dispatch(setChatConversationList(convListTemp));
            } else {
                // check bật âm thanh ở chat customer
                if (getChatStorage('Noti') === '1') {
                    const audio = new Audio(BASE_URL + "/assets/audio/stairs.mp3");
                    audio.play();
                }
            }

            // xử lý append hoặc reload message và convInfo
            if (convSelectedRef.current && conv.id === convSelectedRef.current.id) {
                if (message.content && (message.content.addedby === authUserId || message.content.endedby ||
                    message.content.type === chatContentTypeDefine.CHAT_CONVERSATION_STATUS_CHANGE)) {
                    // khi có agent/KH join/end chat hoặc khi thay đổi trạng thái
                    if (isCustomer) {
                        dispatch(setIsOnlyReloadConvInfo(true));
                    } else {
                        dispatch(setIsReloadConvContent(true));
                    }
                } else {
                    dispatch(setConversationSelected(conv));
                    appendMessage(message);

                    if (isCustomer && message.content) {
                        if (message.content.addedby && message.content.addedby !== authUserId) {
                            // KH nhận noti nếu agent join chat khi KH tạo conv
                            dispatch(setIsReloadConvContent(true));
                            dispatch(setAgentReceivesConvName(message.user_name));
                            dispatch(setIsChatBot(message.content.addedby === CHAT_BOT_USER_ID));
                        }
                    }
                }
            } else {
                if (message.content) {
                    if (message.content.addedby === authUserId) {
                        // agent nhận noti khi agent accept new conv
                        dispatch(setConversationSelected(conv));
                    } else if (isCustomer && message.content.creator) {
                        // KH nhận sự kiện khi agent nhấn "Tiếp tục chat"
                        dispatch(setConversationSelected(conv));
                        setChatStorage('ConvId', conv.id);
                        dispatch(setRating(ratingTypeDefine.NOT_RATING));
                    }
                }
            }
        }
    }, [appendMessage, dispatch, isCustomer]);

    const userTypingListener = useCallback((data, type = 'begin') => {
        // console.log("+++ userTypingListener", type, data);

        if (convSelectedRef.current && data.convId === convSelectedRef.current.id) {
            const authUserId = authUserRef.current.id || authUserRef.current.userId;

            if (data.userId !== authUserId) {
                dispatch(setUserTypingIndicator(type === 'begin' ? data : null));
            }
        }
    }, [dispatch]);

    const timeoutInQueue = useCallback((data) => {
        console.log('timeoutInQueue', data);
        if (data.convId === convSelectedRef.current.id) {
            dispatch(setIsOnlyReloadConvInfo(true));

            // xóa tin nhắn chờ
            document.querySelectorAll('.waiting-message').forEach((e) => e.parentNode.removeChild(e));

            // push message: tất cả agent đang bận
            const created = Date.now();
            dispatch(unshiftItemToConversationMessageList({
                action: "ALL_AGENT_BUSY",
                id: "msg-agent-busy-" + created,
                created
            }));
        }
    }, [dispatch]);

    const customMessage = useCallback((msg) => {
        console.log("++++ custommessage", msg);
        if (msg) {
            if (msg.message.conversationids) {
                const convId = msg.message.conversationids;
                if (convId) {
                    console.log("++++ CHAT NOT ENDED");

                    // TODO chưa biết làm gì
                }
            }

            const authUserId = authUserRef.current.id || authUserRef.current.userId;

            if (msg.toUser === authUserId) {
                if (msg.message.action === "AGENT_REQUEST_CHAT_RATE_COMMENT") {
                    const created = Date.now();
                    dispatch(unshiftItemToConversationMessageList({
                        ...msg.message,
                        id: "msg-chat-rate-" + created,
                        created
                    }));
                } else if (msg.message.action === "AGENT_CONTINUE_CHAT") {
                    // Đã xử lý trong addChatMessage (isCustomer && message.content.creator)
                }
            }
        }
    }, [dispatch]);

    const updateUserInfoMsg = useCallback((data) => {
        console.log("+++ updateUserInfoMsg", data);

        let conv;
        const convIndex = chatConversationListRef.current.findIndex((c) => c.id === data.convId);
        const convListTemp = [...chatConversationListRef.current];

        if (convSelectedRef.current && convSelectedRef.current.id === data.convId) {
            conv = cloneObjectWithPrototype(convSelectedRef.current);
            conv.creatorData.name = data.display_name || conv.creatorData.name;
            dispatch(setConversationSelected(conv));
            dispatch(setIsOnlyReloadConvInfo(true));
        } else if (convIndex !== -1) {
            conv = convListTemp[convIndex];
            conv.creatorData.name = data.display_name || conv.creatorData.name;
        }

        if (convIndex !== -1) {
            convListTemp[convIndex] = conv;
            dispatch(setChatConversationList(convListTemp));
        }
    }, [dispatch]);

    const removeParticipantFromServer = useCallback((data) => {
        console.log("+++ removeParticipantFromServer", data);

        if (convSelectedRef.current && convSelectedRef.current.id === data.groupId) {
            dispatch(setIsReloadConvContent(true));
        }
    }, [dispatch]);

    const userIdListenedEvent = useRef(null);
    useEffect(() => {
        if (client && connected) {
            if (!userIdListenedEvent.current || client.userId !== userIdListenedEvent.current) {
                console.log('client listener event chat message ++++++');
                client.on('changemessagestate', (ev) => changeMessageState(ev));

                client.on('addchatmessage', (message) => addChatMessage(message));

                client.on('userBeginTypingListener', (data) => userTypingListener(data, 'begin'));
                client.on('userEndTypingListener', (data) => userTypingListener(data, 'end'));

                client.on('timeoutInQueue', (data) => timeoutInQueue(data));

                client.on("custommessage", (msg) => customMessage(msg));

                client.on("updateUserInfoMsg", (data) => updateUserInfoMsg(data));

                client.on("removeParticipantFromServer", (data) => removeParticipantFromServer(data));

                userIdListenedEvent.current = client.userId;
            }
        }
    }, [addChatMessage, changeMessageState, userTypingListener, timeoutInQueue, customMessage, updateUserInfoMsg,
        client, connected, removeParticipantFromServer]);

    const onMarkReadConvSelected = useCallback(() => {
        if (convSelectedRef.current && convSelectedRef.current.id) {
            if (convSelectedRef.current.lastMsgSeqSeen < convSelectedRef.current.lastSeq) {
                // reset unread conv đang select ở list
                const convIndex = chatConversationListRef.current.findIndex(
                    (c) => c.id === convSelectedRef.current.id
                );
                if (convIndex !== -1) {
                    const convListTemp = [...chatConversationListRef.current];
                    convListTemp[convIndex].unread = 0;

                    dispatch(setChatConversationList(convListTemp));
                }

                dispatch(setUnreadMsgCount(0));

                convSelectedRef.current.markRead(convSelectedRef.current.lastMessage);
            }
        }
    }, [dispatch]);

    // gửi trạng thái đã xem tin nhắn khi người dùng active lại vào màn hình chat
    useEffect(() => {
        isUserActiveRef.current = isUserActive;

        if (isUserActive) {
            const listMsgElement = document.getElementById('scrollableDivContentConv');

            if (listMsgElement && listMsgElement.scrollTop >= -5) {
                onMarkReadConvSelected();
            }
        }
    }, [isUserActive, onMarkReadConvSelected]);

    useEffect(() => {
        if (isMarkReadMsgByScroll) {
            dispatch(setIsMarkReadMsgByScroll(false));

            onMarkReadConvSelected();
        }
    }, [isMarkReadMsgByScroll, onMarkReadConvSelected, dispatch]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsUserActive(!document.hidden);
        };

        const handleBlurWindow = () => {
            if (window.parent) {
                if (!document.hasFocus() && !window.parent.document.hasFocus()) {
                    setIsUserActive(false);
                }
            } else {
                setIsUserActive(false);
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('focus', handleVisibilityChange);
        window.addEventListener('blur', handleBlurWindow);

        // Thêm sự kiện focus cho trang chứa iframe
        if (window.parent) {
            window.parent.addEventListener('focus', handleVisibilityChange);
            window.parent.addEventListener('blur', handleBlurWindow);
        }

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('focus', handleVisibilityChange);
            window.removeEventListener('blur', handleBlurWindow);

            if (window.parent) {
                window.parent.removeEventListener('focus', handleVisibilityChange);
                window.parent.removeEventListener('blur', handleBlurWindow);
            }
        };
    }, []);


    // di chuyển chatbox khi người dùng kéo chuột
    const isDragging = useRef(false);
    const position = useRef({x: 0, y: 0});
    const mouseStart = useRef({x: 0, y: 0});
    const positionDefaultX = useRef(0);
    const positionDefaultY = useRef(0);

    useEffect(() => {
        // reset default position when toggle chatbox
        if (isCustomer) {
            if (!showChat) {
                // chatBoxIframeWrapper.style.transform = "none"; // ChatBox.toggleChatOn
                position.current = {
                    x: positionDefaultX.current,
                    y: positionDefaultY.current
                };
            }
        }
    }, [showChat, isCustomer]);

    useEffect(() => {
        if (isCustomer && !isEmpty(chatProfile)) {
            const chatboxHeaderElement = window.parent.document.getElementById("stringeeX_chatbox_header_move");
            const chatBoxIframeWrapper = window.parent.document.getElementById('stringeeX_chatbox_iframe_wrapper');

            const handleMouseDown = (e) => {
                isDragging.current = true;
                mouseStart.current = {
                    x: e.clientX,
                    y: e.clientY
                };
            };

            const handleMouseMove = (e) => {
                if (!isDragging.current) return;

                const deltaX = e.clientX - mouseStart.current.x;
                const deltaY = e.clientY - mouseStart.current.y;

                const x = position.current.x + deltaX;
                const y = position.current.y + deltaY;

                // Kiểm tra giới hạn màn hình
                let minX;
                let maxX;
                const launchPosition = chatProfile.launch_position ?? 'right';
                if (launchPosition === 'right') {
                    minX = chatBoxIframeWrapper.offsetWidth - window.parent.innerWidth + chatProfile.side_spacing;
                    maxX = chatProfile.side_spacing;
                } else {
                    minX = -chatProfile.side_spacing;
                    maxX = window.parent.innerWidth - chatBoxIframeWrapper.offsetWidth - chatProfile.side_spacing;
                }
                const maxY = chatProfile.bottom_spacing;
                // Áp dụng giới hạn
                const boundedX = Math.max(minX, Math.min(x, maxX));
                const boundedY = Math.max(-positionDefaultY.current, Math.min(y, maxY));

                chatBoxIframeWrapper.style.transform = `translate(${boundedX}px, ${boundedY}px)`;
                position.current = {
                    x: boundedX,
                    y: boundedY
                };

                mouseStart.current = {
                    x: e.clientX,
                    y: e.clientY
                };
            };

            const handleMouseUp = () => {
                isDragging.current = false;
            };

            if (chatboxHeaderElement && chatBoxIframeWrapper) {
                // set default position
                positionDefaultX.current = chatProfile.side_spacing;
                positionDefaultY.current = window.parent.innerHeight -
                    chatBoxIframeWrapper.offsetHeight - chatProfile.bottom_spacing;

                position.current = {
                    x: positionDefaultX.current,
                    y: positionDefaultY.current
                };

                // Khi người dùng nhấn chuột xuống
                chatboxHeaderElement.addEventListener('mousedown', handleMouseDown);

                // Khi người dùng di chuyển chuột
                window.parent.document.addEventListener('mousemove', handleMouseMove);

                // Khi người dùng nhả chuột ra
                document.addEventListener('mouseup', handleMouseUp);
                window.parent.document.addEventListener('mouseup', handleMouseUp);

                return () => {
                    chatboxHeaderElement.removeEventListener('mousedown', handleMouseDown);
                    window.parent.document.removeEventListener('mousemove', handleMouseMove);
                    window.parent.document.removeEventListener('mouseup', handleMouseUp);
                    document.removeEventListener('mouseup', handleMouseUp);
                };
            }
        }
    }, [isCustomer, chatProfile]);

    const createNewChatConv = useCallback((userIds) => {
        if (!Array.isArray(userIds)) {
            if (userIds) {
                const listUserIDs = userIds.trim().split(',');
                userIds = [];

                listUserIDs.forEach((id) => {
                    if (id.trim().length === 0) {
                        return;
                    }

                    userIds.push(id.trim());
                });
            }
        }

        if (!userIds || !userIds.length) {
            alert('Please enter userIDs');
            return;
        }

        let options = {};
        if (userIds.length > 1) {
            const name = 'Group ' + userIds.join(', ');
            options = {
                isDistinct: false,
                isGroup: true,
                name: name
            };
        } else {
            options = {
                isDistinct: true,
                isGroup: false,
                name: userIds[0]
            };
        }

        window.StringeeChat2.createConversation(client, userIds, options)
            .then(function(createdConv) {
                console.log('created Conversation', createdConv);

                const convIndex = chatConversationListRef.current.findIndex((c) => c.id === createdConv.id);
                if (convIndex === -1) {
                    dispatch(unshiftItemToChatConversationList(createdConv));
                } else {
                    const convListTemp = [...chatConversationListRef.current];
                    convListTemp.splice(convIndex, 1);
                    convListTemp.unshift(createdConv);
                    dispatch(setChatConversationList(convListTemp));
                }

                dispatch(setConversationSelected(createdConv));
            })
            .catch(function(err) {
                console.log(err);
            });
    }, [client, dispatch]);

    useEffect(() => {
        const StringeeChatWidget = window.parent.StringeeChatWidget || null;

        if (client && client.isInternal && StringeeChatWidget) {
            StringeeChatWidget.on('createNewChatConv', (userIds) => createNewChatConv(userIds));
        }
    }, [client, createNewChatConv]);

    return null;
}

export default ChatEventHandle;
