// i18n.js
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import translationEn from '../locales/en/en.json';
import translationVi from '../locales/vi/vi.json';
import chatboxEn from '../locales/en/chatbox.json';
import chatboxVi from '../locales/vi/chatbox.json';

i18n
    .use(initReactI18next) // kết hợp react-i18next với i18next
    .init({
        resources: {
            vi: {
                translation: {
                    ...translationVi,
                    chatbox: chatboxVi
                }
            },
            en: {
                translation: {
                    ...translationEn,
                    chatbox: chatboxEn
                }
            }
        },
        lng: 'vi', // ngôn ngữ mặc định
        fallbackLng: 'vi', // ngôn ngữ dự phòng nếu ngôn ngữ yêu cầu không khả dụng
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
