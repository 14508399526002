import "./styles/App.scss";
// import các thư viện cần thiết ở đây
// import {createBrowserRouter} from "react-router-dom";

// import layouts
// import DefaultLayout from "./common_components/main_layout/DefaultLayout";

// import các pages dùng nhiều (mở app lên là thấy page/tính năng này ngay)
import Home from "./pages/Home";

// Import trực tiếp ở đây sẽ khiến App Bundle tải lần đầu tiên tăng dung lượng
// các page/tính năng người dùng có thể chưa cần dùng ngay (hoặc ít dùng) thì có thể sử dụng lazy load
// import ErrorPage from "./pages/ErrorPage";

export default function App() {
    return (
        <Home/>
    );
}
