import {useDispatch, useSelector} from "react-redux";
import {selectCustomFields, setCustomFields} from "../../../store/chatbox";
import {Box, Checkbox, ListItemText, MenuItem, Select, TextField} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {TreeSelect} from "antd";
import {buildTreeCustomField, cloneObject} from "../../../common/common";
import {useTranslation} from "react-i18next";
import {FormHelperText} from "@mui/joy";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        }
    }
};

function CustomFieldBox({formData, setFormData}) {
    const [defaultData, setDefaultData] = useState(false);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const customFields = useSelector(selectCustomFields);

    useEffect(() => {
        if (!defaultData && customFields && customFields.length) {
            const data = {};

            customFields.forEach((element) => {
                let value;
                const fieldType = element.field_type;

                if (fieldType === 'checkbox') {
                    value = false;
                } else if (fieldType === 'multi_select') {
                    value = [];
                } else {
                    value = '';
                }

                data[element.id] = value;
            });

            setFormData((prevState) => ({...prevState, ...data}));
            setDefaultData(true);
        }
    }, [customFields, defaultData, setFormData]);

    const onChangeFormData = useCallback((e, element, isValue = false) => {
        let checkInvalid = false;
        const fieldType = element.field_type;
        const fieldRequired = !!element.field_required;
        const fieldId = element.id;
        const value = isValue ? e : e.target.value;

        setFormData((prevState) => ({...prevState, [fieldId]: value}));

        // validate
        if (value) {
            if (fieldType === 'regexp' && element.field_regex) {
                const regCheck = value.match(element.field_regex);
                checkInvalid = !(regCheck && regCheck[0] && regCheck[0] === value);
            } else if (fieldType === "integer") {
                const checkInt = Number.isInteger(Number(value));
                checkInvalid = !checkInt;
            }
        } else if (fieldRequired) {
            checkInvalid = true;
        }

        dispatch(setCustomFields(customFields.map((item) => (
            item.id === element.id ? {...item, checkInvalid} : item
        ))));
    }, [customFields, dispatch, setFormData]);

    const renderCustomField = (element) => {
        const fieldName = element.field_name;
        const fieldType = element.field_type;
        const fieldId = element.id;
        const options = element.options;

        switch (fieldType) {
            case 'text':
            case 'regexp':
                return <TextField
                    error={element.checkInvalid}
                    fullWidth
                    value={formData[fieldId]}
                    size="small"
                    placeholder={fieldName}
                    onChange={(e) => onChangeFormData(e, element)}
                    helperText={element.checkInvalid ? t('chatbox.please_enter_a_valid_value') : null}
                />;
            case 'integer':
            case "decimal":
                return <TextField
                    error={element.checkInvalid}
                    type="number"
                    fullWidth
                    value={formData[fieldId]}
                    size="small"
                    placeholder={fieldName}
                    onChange={(e) => onChangeFormData(e, element)}
                    helperText={element.checkInvalid ? t('chatbox.please_enter_a_valid_value') : null}
                />;
            case "textarea":
                return <TextField
                    error={element.checkInvalid}
                    rows={2}
                    multiline
                    fullWidth
                    value={formData[fieldId]}
                    size="small"
                    placeholder={fieldName}
                    onChange={(e) => onChangeFormData(e, element)}
                    helperText={element.checkInvalid ? t('chatbox.please_enter_a_valid_value') : null}
                />;
            case "checkbox":
                return <div>
                    <Checkbox
                        value={formData[fieldId]}
                        onChange={(e) => onChangeFormData(e.target.checked, element, true)}
                    />
                </div>;
            case "drop_down":
                if (!options || !options.length) {
                    return null;
                }

                return <>
                    <Select
                        error={element.checkInvalid}
                        fullWidth
                        size="small"
                        value={formData[fieldId]}
                        MenuProps={MenuProps}
                        onChange={(e) => onChangeFormData(e, element)}
                    >
                        {options.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
                        ))}
                    </Select>
                    {element.checkInvalid && <FormHelperText>{t('chatbox.please_enter_a_valid_value')}</FormHelperText>}
                </>;
            case "date":
                return <TextField
                    error={element.checkInvalid}
                    type="date"
                    fullWidth
                    value={formData[fieldId]}
                    size="small"
                    placeholder={fieldName}
                    onChange={(e) => {
                        onChangeFormData(e, element);
                    }}
                    helperText={element.checkInvalid ? t('chatbox.please_enter_a_valid_value') : null}
                />;
            case "multi_select":
                if (!options || !options.length) {
                    return null;
                }

                return <>
                    <Select
                        error={element.checkInvalid}
                        multiple
                        fullWidth
                        size="small"
                        value={formData[fieldId]}
                        onChange={(e) => onChangeFormData(e, element)}
                        renderValue={(customFieldValues) => {
                            const selectedOptions = options.filter((item) => customFieldValues.includes(item.id));
                            return selectedOptions.map((item) => item.value).join(', ');
                        }}
                        MenuProps={MenuProps}
                        helperText={element.checkInvalid ? t('chatbox.please_enter_a_valid_value') : null}
                    >
                        {options.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                <Checkbox
                                    checked={formData[fieldId] &&
                                        formData[fieldId].findIndex((i) => (i === item.id)) > -1}
                                />
                                <ListItemText primary={item.value} />
                            </MenuItem>
                        ))}
                    </Select>
                    {element.checkInvalid && <FormHelperText>{t('chatbox.please_enter_a_valid_value')}</FormHelperText>}
                </>;
            case "cascader":
                if (!options || !options.length) {
                    return null;
                }

                const treeData = buildTreeCustomField(cloneObject(options));

                return <>
                    <TreeSelect
                        showSearch
                        style={{width: '100%'}}
                        value={formData[fieldId]}
                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                        placeholder={fieldName}
                        allowClear
                        // treeDefaultExpandAll
                        onChange={(value) => onChangeFormData(value, element, true)}
                        treeData={treeData}
                        treeLine={true}
                        status={element.checkInvalid ? "error" : null}
                    >
                    </TreeSelect>
                    {element.checkInvalid && <FormHelperText>{t('chatbox.please_enter_a_valid_value')}</FormHelperText>}
                </>;
            default:
                return null;
        }
    };

    if (!customFields || !customFields.length || !defaultData) {
        return null;
    }

    return <>
        {customFields.map((element) => {
            const fieldName = element.field_name;
            const fieldRequired = !!element.field_required;

            const customFieldHtml = renderCustomField(element);

            if (!customFieldHtml) {
                return null;
            }

            return <Box key={element.id}>
                <label>
                    {fieldName}&nbsp;
                    {fieldRequired && <span className="text-danger">*</span>}
                </label>
                {customFieldHtml}
            </Box>;
        })}
    </>;
}

export default CustomFieldBox;
