import {createSlice} from "@reduxjs/toolkit";
import {apiStringee} from "../api/ApiStringee";

// Redux toolkit cho phép định nghĩa state (VD: "pageIsError"), định nghĩa các reducers/action (VD: setPageIsError)
// ngay trong 1 file JS, cho phép code dễ bảo trì hơn

// định nghĩa giá trị khởi tạo của state
// value=true tức là trang đang render có lỗi
// r/msg: thông tin chi tiết về lỗi
const initialState = {
    isLoading: false,
    currentPlayerPlay: null,
    chatCustomerAccessToken: null,
    publicApiStringee: null,
    userTypingIndicator: null,
    displayMode: null
};

// định nghĩa state name, reducers, actions
const slice = createSlice({
    name: "chatGeneral",
    initialState,
    reducers: {
        // action public ra để dùng là: setPageIsError(true/false)
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setCurrentPlayerPlay: (state, action) => {
            state.currentPlayerPlay = action.payload;
        },
        setChatCustomerAccessToken: (state, action) => {
            const accessToken = action.payload;

            state.chatCustomerAccessToken = accessToken;
            apiStringee.setAuthToken(accessToken);
        },
        setPublicApiStringee: (state, action) => {
            state.publicApiStringee = action.payload;
        },
        setUserTypingIndicator: (state, action) => {
            state.userTypingIndicator = action.payload;
        },
        setDisplayMode: (state, action) => {
            state.displayMode = action.payload;
        }
    }
});

// export state, actions, reducer để các components sử dụng
// lưu ý: quy định đặt tên giống như sample này để dễ hiểu, dễ bảo trì code
export const selectIsLoading = (state) => state.chatGeneral.isLoading;
export const selectCurrentPlayerPlay = (state) => state.chatGeneral.currentPlayerPlay;
export const selectChatCustomerAccessToken = (state) => state.chatGeneral.chatCustomerAccessToken;
export const selectPublicApiStringee = (state) => state.chatGeneral.publicApiStringee;
export const selectUserTypingIndicator = (state) => state.chatGeneral.userTypingIndicator;
export const selectDisplayMode = (state) => state.chatGeneral.displayMode;
export const {
    setIsLoading,
    setCurrentPlayerPlay,
    setChatCustomerAccessToken,
    setPublicApiStringee,
    setUserTypingIndicator,
    setDisplayMode
} = slice.actions;
export default slice.reducer;
