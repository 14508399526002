import {useCallback, useEffect, useMemo, useRef} from "react";
import InitialForm from "./InitialForm";
import "./../../styles/scss/chat_box.scss";
import ChatEventHandle from "./ChatEventHandle";
import {useDispatch, useSelector} from "react-redux";
import {
    selectChatProfile,
    selectConvQueueId,
    selectCustomerEmail,
    selectCustomerPhone,
    selectIsPopup,
    selectIsStartAgain,
    selectIsVolumeUp,
    selectShowChat,
    setChatProfile, setCustomFields,
    setIsCheckBusinessHour,
    setIsCustomer, setIsStartAgain,
    setLocation,
    setQueues,
    setShowChat
} from "../../store/chatbox";
import {apiStringee} from "../../api/ApiStringee";
import {apiStringeeX} from "../../api/ApiStringeeX";
import {checkBusinessHour, isEmpty} from "../../common/common";
import {useTranslation} from "react-i18next";
import {ArrowOutward} from "@mui/icons-material";
import {BASE_URL} from "../../common/config";
import {selectAuthUser} from "../../store/chatAuthUser";
import {selectChatConversationSelected} from "../../store/chatConversationSelected";

function ChatBox(props) {
    const {t} = useTranslation();
    const params = useMemo(() => props.params || {}, [props.params]);
    const chatBoxIframeWrapper = window.parent.document.getElementById('stringeeX_chatbox_iframe_wrapper');
    const chatWindowPopupRef = useRef(null);

    const dispatch = useDispatch();
    const chatProfile = useSelector(selectChatProfile);
    const showChat = useSelector(selectShowChat);
    const isPopup = useSelector(selectIsPopup);
    const convQueueId = useSelector(selectConvQueueId);
    const customerPhone = useSelector(selectCustomerPhone);
    const customerEmail = useSelector(selectCustomerEmail);
    const authUser = useSelector(selectAuthUser);
    const isVolumeUp = useSelector(selectIsVolumeUp);
    const chatConversationSelected = useSelector(selectChatConversationSelected);
    const isStartAgain = useSelector(selectIsStartAgain);

    const fetchLocation = useCallback(() => {
        apiStringee.httpRawRequest('/v1/chat/getlocation', 'get', null)
            .then((res) => {
                dispatch(setLocation(res));
            });
    }, [dispatch]);

    const toggleStyle = useCallback((chatProfile) => {
        if (chatProfile.background) {
            document.documentElement.style.setProperty('--color-default', chatProfile.background);
        }

        if (!params.popup) {
            const sideSpacing = chatProfile.side_spacing ? chatProfile.side_spacing + 'px' : '15px';
            const bottomSpacing = chatProfile.bottom_spacing ? chatProfile.bottom_spacing + 'px' : '15px';
            chatBoxIframeWrapper.style.bottom = bottomSpacing;
            if (chatProfile.launch_position) {
                const launchPosition = chatProfile.launch_position ?? 'right';
                if (launchPosition === 'right') {
                    chatBoxIframeWrapper.style.right = sideSpacing;
                } else {
                    chatBoxIframeWrapper.style.left = sideSpacing;
                }
            }
        }
    }, [chatBoxIframeWrapper, params]);

    const handleCheckBusinessHour = useCallback((chatProfile) => {
        const _enabledBH = chatProfile.enabled;
        const hourDummy = chatProfile.hour;
        let isCheckBusinessHour = true;

        if (hourDummy && _enabledBH) {
            if (hourDummy.split(";")[0]) {
                const hour0 = hourDummy.split(";")[0];
                isCheckBusinessHour = checkBusinessHour(
                    hour0.split("-")[0],
                    hour0.split("-")[1]
                );

                if (!isCheckBusinessHour) {
                    if (hourDummy.split(";")[1]) {
                        const hour1 = hourDummy.split(";")[1];
                        isCheckBusinessHour = checkBusinessHour(
                            hour1.split("-")[0],
                            hour1.split("-")[1]
                        );
                    }
                }
            } else {
                isCheckBusinessHour = checkBusinessHour(
                    hourDummy.split("-")[0],
                    hourDummy.split("-")[1]
                );
            }
        }

        dispatch(setIsCheckBusinessHour(isCheckBusinessHour));

        return isCheckBusinessHour;
    }, []);

    const fetchQueues = useCallback(async function() {
        await apiStringeeX.httpGet('/v1/chatcustomer/getsettingandqueues?enable_for_chat=1&key=' + params.key)
            .then((res) => {
                if (res.r === 0) {
                    dispatch(setQueues(res.queues));

                    if (res.customField) {
                        dispatch(setCustomFields(res.customField));
                    }

                    const chatProfileRes = res.chatProfile;
                    dispatch(setChatProfile(chatProfileRes));

                    toggleStyle(chatProfileRes);
                    handleCheckBusinessHour(chatProfileRes);
                }
            });
    }, [dispatch, toggleStyle, handleCheckBusinessHour, params.key]);

    useEffect(() => {
        const fetchData = async () => {
            if (isStartAgain) {
                dispatch(setIsStartAgain(false));
                await fetchQueues();

                const postMessageData = {
                    action: "handleStartAgain"
                };
                window.parent.postMessage(postMessageData, "*");
            }
        };

        fetchData();
    }, [isStartAgain, dispatch]);

    useEffect(() => {
        fetchQueues();
        fetchLocation();
    }, [fetchLocation, fetchQueues]);

    const showChatOn = useCallback(() => {
        if (chatBoxIframeWrapper) {
            const className = params.popup ? 'popup' : 'full-open';

            dispatch(setIsCustomer(true));
            chatBoxIframeWrapper.classList.add(className);

            if (chatWindowPopupRef.current) {
                chatWindowPopupRef.current.close();
            }

            dispatch(setShowChat(true));
        }
    }, [dispatch, params, chatBoxIframeWrapper]);

    const hideChatOn = useCallback(() => {
        if (chatBoxIframeWrapper) {
            const className = params.popup ? 'popup' : 'full-open';

            chatBoxIframeWrapper.classList.remove(className);
            chatBoxIframeWrapper.style.transform = "none";

            dispatch(setShowChat(false));
        }
    }, [dispatch, params, chatBoxIframeWrapper]);

    useEffect(() => {
        if (params.isOldCustomer && chatProfile && !isEmpty(chatProfile)) {
            showChatOn();
        }

        // eslint-disable-next-line
    }, [params, chatProfile]);

    useEffect(() => {
        if (params.popup) {
            showChatOn();
        }

        // eslint-disable-next-line
    }, [params]);

    const showChatPopup = useCallback(() => {
        if (!isPopup && chatConversationSelected && chatConversationSelected.id) {
            const postMessageData = {
                action: 'handleShowChatPopup'
            };
            window.parent.postMessage(postMessageData, "*");

            const body = new URLSearchParams({
                popup: true,
                convId: chatConversationSelected.id,
                key: params.key,
                queue: convQueueId,
                userId: authUser.userId || authUser.id,
                email: customerEmail,
                phone: customerPhone,
                name: authUser.displayName || authUser.name,
                noti: isVolumeUp ? 1 : 0
            });

            chatWindowPopupRef.current = window.open(
                BASE_URL + '/popup_index.html?' + body.toString(),
                "Welcome",
                "width=520,height=800"
            );
            chatWindowPopupRef.current.focus();

            setTimeout(() => {
                hideChatOn();
            }, 200);
        }
    }, [isPopup, chatConversationSelected, params, convQueueId, authUser, isVolumeUp,
        customerEmail, customerPhone, hideChatOn]);

    if (!chatProfile || isEmpty(chatProfile)) {
        return null;
    }

    return <div className="row">
        <div id="stringeechat" className="k8s">
            <div className={"Layout Layout-open Layout-expand Layout-right stringeechat" +
                (showChat? '' : ' display-none') + (params.popup ? ' popup' : '')}>

                <div className="Messenger_messenger initial_form initial_chat" style={{display: "block"}}>
                    <div className="Messenger_header">
                        <h4 className="Messenger_prompt">
                            {t("chatbox.chat_us")}
                        </h4>
                        {!params.popup && <div className="header_right">
                            {(chatConversationSelected && chatConversationSelected.id) &&
                                <span className="chat_iframe_icon" onClick={showChatPopup}>
                                    <ArrowOutward sx={{verticalAlign: "bottom"}}/>
                                </span>
                            }
                            <span className="chat_close_icon init" onClick={hideChatOn}>
                                <svg><path d="M0 0H30V5H0z"></path></svg>
                            </span>
                        </div>}
                    </div>

                    <div className="Messenger_content">
                        <InitialForm
                            params={params}
                            hideChat={hideChatOn}
                            handleCheckBusinessHour={handleCheckBusinessHour}
                        />
                        <ChatEventHandle/>
                    </div>
                </div>
            </div>
            {!showChat && <div className="chat_on" onClick={showChatOn}>
                <div className="badge">0</div>
                <img
                    className="chat_on_img"
                    id="chat_on_img"
                    alt=""
                    src="https://v2.stringee.com/stringeexchat/sdk/product//css/assets/chat-icon.png"
                />
            </div>}
        </div>
    </div>;
}
export default ChatBox;
