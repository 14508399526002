import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText
} from "@mui/material";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {
    removeSelectedFileWithIndex,
    selectSelectedFiles,
    setSelectedFiles
} from "../../../store/messageField";
import {Delete} from "@mui/icons-material";
import * as React from "react";

const ModalPreviewSelectedFiles = forwardRef((props, ref) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();
    const selectedFiles = useSelector(selectSelectedFiles);

    useImperativeHandle(ref, () => ({
        show() {
            setOpen(true);
        }
    }));

    useEffect(() => {
        if (!selectedFiles || !selectedFiles.length) {
            setOpen(false);
        }
        if (selectedFiles?.length) {
            setOpen(true);
        }
    }, [selectedFiles]);

    const cancel = () => {
        dispatch(setSelectedFiles([]));
    };

    const sendFiles = async () => {
        if (selectedFiles && selectedFiles.length && props.handleSendFile) {
            for (const file of selectedFiles) {
                await props.handleSendFile(file);
            }
        }

        dispatch(setSelectedFiles([]));
    };

    const removeFileSelected = (index) => {
        dispatch(removeSelectedFileWithIndex(index));
    };

    return (
        <>
            <Dialog open={open} onClose={cancel} fullWidth>
                <DialogTitle sx={{fontSize: "16px"}}>
                    {t('chatbox.count_files_selected', {count: selectedFiles.length})}
                </DialogTitle>
                <DialogContent style={{padding: 0}}>
                    <List>
                        {
                            selectedFiles.map((file, index) => {
                                return <ListItem
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => removeFileSelected(index)}
                                        >
                                            <Delete/>
                                        </IconButton>
                                    }
                                >
                                    <ListItemText primary={file.name}/>
                                </ListItem>;
                            })
                        }
                    </List>
                </DialogContent>
                <DialogActions sx={{justifyContent: "space-between"}}>
                    <Button component="label">
                        <span>{t('chatbox.add')}</span>
                        <input type="file" multiple hidden onChange={props.handleSelectedFiles}/>
                    </Button>
                    <Box>
                        <Button onClick={cancel}>{t('chatbox.btn_cancel')}</Button>
                        <Button onClick={sendFiles}>{t('message_field.btn_send')}</Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
});

export default ModalPreviewSelectedFiles;
