export const chatStatusDefine = {
    RINGING: 0,
    ANSWERING: 1,
    ENDED: 2,
    MISSED: 3,
    INACTIVE: 4
};
export const CHAT_STATUS_MAP = {
    [chatStatusDefine.RINGING]: {
        label: "common.status.ringing",
        value: chatStatusDefine.RINGING,
        background: "#FCD34D"
    },
    [chatStatusDefine.ANSWERING]: {
        label: "common.status.answering",
        value: chatStatusDefine.ANSWERING,
        background: "#87C08A"
    },
    [chatStatusDefine.ENDED]: {
        label: "common.status.ended",
        value: chatStatusDefine.ENDED,
        background: "#C5C5C5"
    },
    [chatStatusDefine.MISSED]: {
        label: "common.status.missed",
        value: chatStatusDefine.MISSED,
        background: "#FDBA74"
    },
    [chatStatusDefine.INACTIVE]: {
        label: "common.status.inactive",
        value: chatStatusDefine.INACTIVE,
        background: "#868C8F4C"
    }
};

export const chatTypeDefine = {
    TEXT: 1,
    IMAGE: 2,
    VIDEO: 3,
    AUDIO: 4,
    FILE: 5,
    STICKER: 11
};

export const chatContentTypeDefine = {
    CHAT_ADD_USER_CONV: 1,
    CHAT_REMOVE_USER_CONV: 2,
    CHAT_UPDATE_CONV: 3,
    CHAT_END_CONV: 4,
    CHAT_CUSTOMER_COMMENT_RATING: 5,
    CHAT_CONVERSATION_STATUS_CHANGE: 6,
    CHAT_TRANSFER: 7
};

export const actorPlatformDefine = {
    1: 'ios',
    2: 'android',
    3: "web"
};

export const actorTypeDefine = {
    SYSTEM: 0,
    AGENT: 1,
    CUSTOMER: 2,
    PAGE: 3
};

export const convStatusChangeReason = {
    // ringing
    ROUTE_CHAT: 0,
    REROUTE_CHAT_AGENT_NO_REPLY_TIME_OUT: 1,
    REROUTE_CHAT_AGENT_DISCONNECT_TIME_OUT: 2,

    // answer
    AGENT_ANSWER_CHAT: 3,
    NEW_MSG_FROM_CUSTOMER_TO_INACTIVE_CONV: 4,
    NEW_MSG_FROM_AGENT_TO_INACTIVE_CONV: 5,
    AGENT_CONTINUE_CHAT: 6,

    // inactive
    CUSTOMER_DISCONNECT_TIMEOUT: 7,
    NO_MESSAGE_TO_CONVERSATION_TIMEOUT: 8,

    // missed
    ROUTE_CHAT_IN_QUEUE_TIME_OUT: 9,

    // end
    END_CHAT_BY_USER: 10,
    END_CHAT_BY_INACTIVE_TIMEOUT: 11,
    END_CHAT_BY_SYSTEM: 12
};

export const ratingTypeDefine = {
    GOOD: 1,
    BAD: 0,
    NOT_RATING: -1
};

export const audioExtensions = ["mp3", "wav", "ogg"];
export const videoExtensions = ["mp4", "avi", "mov", "wmv", "webm"];
export const imgExtensions = [
    "jpg",
    "jpeg",
    "png",
    "xbm",
    "bmp",
    "webp",
    "svgz",
    "gif",
    "jfif",
    "svg",
    "ico",
    "tiff",
    "pjpeg",
    "pjp",
    "tif"
];
export const fileExtensions = [
    "pdf",
    "doc",
    "docx",
    "txt",
    "html",
    "xml",
    "json",
    "csv",
    "zip",
    "rar",
    "xlsx",
    "xls",
    "ppt",
    "pptx",
    "odt",
    "ods",
    "odp",
    "rtf",
    "heif",
    "heic"
];

export const CHAT_BOT_USER_ID = 'chat-gpt-bot';

export const fileIcons = {
    no_icon: "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVy" +
        "YXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAg" +
        "LS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94" +
        "bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU4IDU4IiBzdHlsZT0iZW5hYmxl" +
        "LWJhY2tncm91bmQ6bmV3IDAgMCA1OCA1ODsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI1MTJweCIgaGVpZ2h0PSI1MTJweCI+Cjxw" +
        "b2x5Z29uIHN0eWxlPSJmaWxsOiNFREVBREE7IiBwb2ludHM9IjUxLjUsMTQgMzcuNSwwIDYuNSwwIDYuNSw1OCA1MS41LDU4ICIvPgo8cG9s" +
        "eWdvbiBzdHlsZT0iZmlsbDojQ0VDOUFFOyIgcG9pbnRzPSIzNy41LDAgMzcuNSwxNCA1MS41LDE0ICIvPgo8cGF0aCBzdHlsZT0iZmlsbDoj" +
        "Q0VDOUFFOyIgZD0iTTQxLjUsMjJoLTE2Yy0wLjU1MiwwLTEtMC40NDctMS0xczAuNDQ4LTEsMS0xaDE2YzAuNTUyLDAsMSwwLjQ0NywxLDFT" +
        "NDIuMDUyLDIyLDQxLjUsMjJ6Ii8+CjxwYXRoIHN0eWxlPSJmaWxsOiMxNEEwODU7IiBkPSJNMTcuNSwyM2MtMC4yNTcsMC0wLjUxNC0wLjA5" +
        "OS0wLjcwOC0wLjI5M2wtMi0yYy0wLjM5MS0wLjM5MS0wLjM5MS0xLjAyMywwLTEuNDE0ICBzMS4wMjMtMC4zOTEsMS40MTQsMGwxLjM2Nywx" +
        "LjM2N2w0LjMwMS0zLjQ0MWMwLjQzLTAuMzQ1LDEuMDYxLTAuMjc1LDEuNDA1LDAuMTU2YzAuMzQ1LDAuNDMyLDAuMjc1LDEuMDYxLTAuMTU2" +
        "LDEuNDA2bC01LDQgIEMxNy45NDEsMjIuOTI4LDE3LjcyLDIzLDE3LjUsMjN6Ii8+CjxwYXRoIHN0eWxlPSJmaWxsOiNDRUM5QUU7IiBkPSJN" +
        "NDEuNSwzM2gtMTZjLTAuNTUyLDAtMS0wLjQ0Ny0xLTFzMC40NDgtMSwxLTFoMTZjMC41NTIsMCwxLDAuNDQ3LDEsMVM0Mi4wNTIsMzMsNDEu" +
        "NSwzM3oiLz4KPHBhdGggc3R5bGU9ImZpbGw6IzE0QTA4NTsiIGQ9Ik0xNy41LDM0Yy0wLjI1NywwLTAuNTE0LTAuMDk5LTAuNzA4LTAuMjkz" +
        "bC0yLTJjLTAuMzkxLTAuMzkxLTAuMzkxLTEuMDIzLDAtMS40MTQgIHMxLjAyMy0wLjM5MSwxLjQxNCwwbDEuMzY3LDEuMzY3bDQuMzAxLTMu" +
        "NDQxYzAuNDMtMC4zNDUsMS4wNjEtMC4yNzUsMS40MDUsMC4xNTZjMC4zNDUsMC40MzIsMC4yNzUsMS4wNjEtMC4xNTYsMS40MDZsLTUsNCAg" +
        "QzE3Ljk0MSwzMy45MjgsMTcuNzIsMzQsMTcuNSwzNHoiLz4KPHBhdGggc3R5bGU9ImZpbGw6I0NFQzlBRTsiIGQ9Ik00MS41LDQ0aC0xNmMt" +
        "MC41NTIsMC0xLTAuNDQ3LTEtMXMwLjQ0OC0xLDEtMWgxNmMwLjU1MiwwLDEsMC40NDcsMSwxUzQyLjA1Miw0NCw0MS41LDQ0eiIvPgo8cGF0" +
        "aCBzdHlsZT0iZmlsbDojMTRBMDg1OyIgZD0iTTE3LjUsNDVjLTAuMjU3LDAtMC41MTQtMC4wOTktMC43MDgtMC4yOTNsLTItMmMtMC4zOTEt" +
        "MC4zOTEtMC4zOTEtMS4wMjMsMC0xLjQxNCAgczEuMDIzLTAuMzkxLDEuNDE0LDBsMS4zNjcsMS4zNjdsNC4zMDEtMy40NDFjMC40My0wLjM0" +
        "NSwxLjA2MS0wLjI3NSwxLjQwNSwwLjE1NmMwLjM0NSwwLjQzMiwwLjI3NSwxLjA2MS0wLjE1NiwxLjQwNmwtNSw0ICBDMTcuOTQxLDQ0Ljky" +
        "OCwxNy43Miw0NSwxNy41LDQ1eiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4K" +
        "PC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N" +
        "2Zz4K",
    ppt: "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRv" +
        "cjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+" +
        "CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGlu" +
        "ayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM4NCAzODQiIHN0eWxlPSJlbmFibGUt" +
        "YmFja2dyb3VuZDpuZXcgMCAwIDM4NCAzODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8" +
        "cG9seWdvbiBzdHlsZT0iZmlsbDojRUZFRUVFOyIgcG9pbnRzPSI2NCwwIDY0LDM4NCAyODgsMzg0IDM4NCwyODggMzg0LDAgIi8+Cjxwb2x5" +
        "Z29uIHN0eWxlPSJmaWxsOiNBQkFCQUI7IiBwb2ludHM9IjI4OCwyODggMjg4LDM4NCAzODQsMjg4ICIvPgo8cG9seWdvbiBzdHlsZT0iZmls" +
        "bDojREVERUREOyIgcG9pbnRzPSIxOTIsMzg0IDI4OCwzODQgMjg4LDI4OCAiLz4KPHBhdGggc3R5bGU9ImZpbGw6I0NCN0IyOTsiIGQ9Ik0w" +
        "LDk2djExMmgyNTZWOTZIMHoiLz4KPGc+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTYwLjk5MiwxMTcuODU2YzMuNTIsMCw2" +
        "LjQ2NCwwLjU5Miw4Ljg4LDEuNzc2czQuMzM2LDIuNzM2LDUuODA4LDQuNjU2czIuNTI4LDQuMTEyLDMuMTY4LDYuNTc2ICAgYzAuNjI0LDIu" +
        "NDQ4LDAuOTYsNC45NzYsMC45Niw3LjUzNmMwLDMuNTItMC41MjgsNi41OTItMS41ODQsOS4yMTZjLTEuMDU2LDIuNjI0LTIuNTI4LDQuNzY4" +
        "LTQuNDE2LDYuNDE2ICAgYy0xLjg4OCwxLjY2NC00LjE3NiwyLjkyOC02Ljg2NCwzLjc0NGMtMi42ODgsMC44MTYtNS42NjQsMS4yNjQtOC45" +
        "MjgsMS4yNjRoLTcuMzkydjI3LjM2SDM2Ljh2LTY4LjU0NEw2MC45OTIsMTE3Ljg1NnogICAgTTU2Ljc2OCwxNDguODk2YzIuNzUyLDAsNC45" +
        "OTItMC44NDgsNi42NzItMi41MTJjMS42OTYtMS42NjQsMi41NDQtNC4yNTYsMi41NDQtNy43NzZjMC0zLjQ1Ni0wLjczNi02LjA4LTIuMjA4" +
        "LTcuODcyICAgYy0xLjQ3Mi0xLjc3Ni0zLjkzNi0yLjY4OC03LjM5Mi0yLjY4OGgtNS43NnYyMC44NDhMNTYuNzY4LDE0OC44OTZMNTYuNzY4" +
        "LDE0OC44OTZ6Ii8+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTEyMC40MTYsMTE3Ljg1NmMzLjUyLDAsNi40NjQsMC41OTIs" +
        "OC44OCwxLjc3NmMyLjQxNiwxLjE4NCw0LjMzNiwyLjczNiw1LjgwOCw0LjY1NiAgIGMxLjQ3MiwxLjkyLDIuNTI4LDQuMTEyLDMuMTY4LDYu" +
        "NTc2YzAuNjI0LDIuNDQ4LDAuOTYsNC45NzYsMC45Niw3LjUzNmMwLDMuNTItMC41MjgsNi41OTItMS41ODQsOS4yMTYgICBjLTEuMDU2LDIu" +
        "NjI0LTIuNTI4LDQuNzY4LTQuNDE2LDYuNDE2Yy0xLjg4OCwxLjY2NC00LjE3NiwyLjkyOC02Ljg2NCwzLjc0NGMtMi42ODgsMC44MTYtNS42" +
        "NjQsMS4yNjQtOC45MjgsMS4yNjRoLTcuMzkydjI3LjM2ICAgSDk2LjIyNHYtNjguNTQ0TDEyMC40MTYsMTE3Ljg1NnogTTExNi4xOTIsMTQ4" +
        "Ljg5NmMyLjc1MiwwLDQuOTkyLTAuODQ4LDYuNjcyLTIuNTEyYzEuNjk2LTEuNjY0LDIuNTQ0LTQuMjU2LDIuNTQ0LTcuNzc2ICAgYzAtMy40" +
        "NTYtMC43MzYtNi4wOC0yLjIwOC03Ljg3MmMtMS40NzItMS43NzYtMy45MzYtMi42ODgtNy4zOTItMi42ODhoLTUuNzZ2MjAuODQ4TDExNi4x" +
        "OTIsMTQ4Ljg5NkwxMTYuMTkyLDE0OC44OTZ6Ii8+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTE5NS43NzYsMTI5LjE4NEgx" +
        "ODAuOFYxODYuNGgtMTMuODI0di01Ny4yMTZIMTUydi0xMS4zMjhoNDMuNzc2VjEyOS4xODR6Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4K" +
        "PGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4K" +
        "PGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==",
    pptx: "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXR" +
        "vcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0" +
        "+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGl" +
        "uayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM4NCAzODQiIHN0eWxlPSJlbmFibGU" +
        "tYmFja2dyb3VuZDpuZXcgMCAwIDM4NCAzODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo" +
        "8cG9seWdvbiBzdHlsZT0iZmlsbDojRUZFRUVFOyIgcG9pbnRzPSI2NCwwIDY0LDM4NCAyODgsMzg0IDM4NCwyODggMzg0LDAgIi8+Cjxwb2x" +
        "5Z29uIHN0eWxlPSJmaWxsOiNBQkFCQUI7IiBwb2ludHM9IjI4OCwyODggMjg4LDM4NCAzODQsMjg4ICIvPgo8cG9seWdvbiBzdHlsZT0iZml" +
        "sbDojREVERUREOyIgcG9pbnRzPSIxOTIsMzg0IDI4OCwzODQgMjg4LDI4OCAiLz4KPHBhdGggc3R5bGU9ImZpbGw6I0NCN0IyOTsiIGQ9Ik0" +
        "wLDk2djExMmgyNTZWOTZIMHoiLz4KPGc+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTYwLjk5MiwxMTcuODU2YzMuNTIsMCw" +
        "2LjQ2NCwwLjU5Miw4Ljg4LDEuNzc2czQuMzM2LDIuNzM2LDUuODA4LDQuNjU2czIuNTI4LDQuMTEyLDMuMTY4LDYuNTc2ICAgYzAuNjI0LDI" +
        "uNDQ4LDAuOTYsNC45NzYsMC45Niw3LjUzNmMwLDMuNTItMC41MjgsNi41OTItMS41ODQsOS4yMTZjLTEuMDU2LDIuNjI0LTIuNTI4LDQuNzY" +
        "4LTQuNDE2LDYuNDE2ICAgYy0xLjg4OCwxLjY2NC00LjE3NiwyLjkyOC02Ljg2NCwzLjc0NGMtMi42ODgsMC44MTYtNS42NjQsMS4yNjQtOC4" +
        "5MjgsMS4yNjRoLTcuMzkydjI3LjM2SDM2Ljh2LTY4LjU0NEw2MC45OTIsMTE3Ljg1NnogICAgTTU2Ljc2OCwxNDguODk2YzIuNzUyLDAsNC4" +
        "5OTItMC44NDgsNi42NzItMi41MTJjMS42OTYtMS42NjQsMi41NDQtNC4yNTYsMi41NDQtNy43NzZjMC0zLjQ1Ni0wLjczNi02LjA4LTIuMjA" +
        "4LTcuODcyICAgYy0xLjQ3Mi0xLjc3Ni0zLjkzNi0yLjY4OC03LjM5Mi0yLjY4OGgtNS43NnYyMC44NDhMNTYuNzY4LDE0OC44OTZMNTYuNzY" +
        "4LDE0OC44OTZ6Ii8+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTEyMC40MTYsMTE3Ljg1NmMzLjUyLDAsNi40NjQsMC41OTI" +
        "sOC44OCwxLjc3NmMyLjQxNiwxLjE4NCw0LjMzNiwyLjczNiw1LjgwOCw0LjY1NiAgIGMxLjQ3MiwxLjkyLDIuNTI4LDQuMTEyLDMuMTY4LDY" +
        "uNTc2YzAuNjI0LDIuNDQ4LDAuOTYsNC45NzYsMC45Niw3LjUzNmMwLDMuNTItMC41MjgsNi41OTItMS41ODQsOS4yMTYgICBjLTEuMDU2LDI" +
        "uNjI0LTIuNTI4LDQuNzY4LTQuNDE2LDYuNDE2Yy0xLjg4OCwxLjY2NC00LjE3NiwyLjkyOC02Ljg2NCwzLjc0NGMtMi42ODgsMC44MTYtNS4" +
        "2NjQsMS4yNjQtOC45MjgsMS4yNjRoLTcuMzkydjI3LjM2ICAgSDk2LjIyNHYtNjguNTQ0TDEyMC40MTYsMTE3Ljg1NnogTTExNi4xOTIsMTQ" +
        "4Ljg5NmMyLjc1MiwwLDQuOTkyLTAuODQ4LDYuNjcyLTIuNTEyYzEuNjk2LTEuNjY0LDIuNTQ0LTQuMjU2LDIuNTQ0LTcuNzc2ICAgYzAtMy4" +
        "0NTYtMC43MzYtNi4wOC0yLjIwOC03Ljg3MmMtMS40NzItMS43NzYtMy45MzYtMi42ODgtNy4zOTItMi42ODhoLTUuNzZ2MjAuODQ4TDExNi4" +
        "xOTIsMTQ4Ljg5NkwxMTYuMTkyLDE0OC44OTZ6Ii8+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTE5NS43NzYsMTI5LjE4NEg" +
        "xODAuOFYxODYuNGgtMTMuODI0di01Ny4yMTZIMTUydi0xMS4zMjhoNDMuNzc2VjEyOS4xODR6Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4" +
        "KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4" +
        "KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==",
    xlsx: "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXR" +
        "vcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0" +
        "+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGl" +
        "uayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM4NCAzODQiIHN0eWxlPSJlbmFibGU" +
        "tYmFja2dyb3VuZDpuZXcgMCAwIDM4NCAzODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo" +
        "8cG9seWdvbiBzdHlsZT0iZmlsbDojRUZFRUVFOyIgcG9pbnRzPSI2NCwwIDY0LDM4NCAyODgsMzg0IDM4NCwyODggMzg0LDAgIi8+Cjxwb2x" +
        "5Z29uIHN0eWxlPSJmaWxsOiNBQkFCQUI7IiBwb2ludHM9IjI4OCwyODggMjg4LDM4NCAzODQsMjg4ICIvPgo8cG9seWdvbiBzdHlsZT0iZml" +
        "sbDojREVERUREOyIgcG9pbnRzPSIxOTIsMzg0IDI4OCwzODQgMjg4LDI4OCAiLz4KPHBhdGggc3R5bGU9ImZpbGw6IzYxQjU2NTsiIGQ9Ik0" +
        "wLDk2djExMmgyNTZWOTZIMHoiLz4KPGc+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTQ5LjE4NCwxMTcuODU2bDguODMyLDI" +
        "yLjE3Nmw4LjczNi0yMi4xNzZoMTUuMDcybC0xNS45MzYsMzMuODg4TDgyLjk3NiwxODYuNEg2Ny40MjQgICBsLTkuODcyLTIzLjIzMkw0Ny4" +
        "3NDQsMTg2LjRIMzIuNTc2bDE3LjA4OC0zNC42NTZMMzMuOTIsMTE3Ljg1Nkw0OS4xODQsMTE3Ljg1Nkw0OS4xODQsMTE3Ljg1NnoiLz4KCTx" +
        "wYXRoIHN0eWxlPSJmaWxsOiNGRkZGRkY7IiBkPSJNOTcuOTUyLDExNy44NTZoMTMuODI0djU3LjIxNmgyNC40NjRWMTg2LjRIOTcuOTUyTDk" +
        "3Ljk1MiwxMTcuODU2TDk3Ljk1MiwxMTcuODU2eiIvPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0xNjMuMTM2LDE2Ny4wMDh" +
        "jMCwxLjYsMC4xMjgsMy4wNTYsMC4zNjgsNC4zNTJjMC4yNzIsMS4zMTIsMC43MzYsMi40MTYsMS40NCwzLjMxMiAgIGMwLjcwNCwwLjkxMiw" +
        "xLjY2NCwxLjYxNiwyLjg0OCwyLjEyOGMxLjE2OCwwLjQ5NiwyLjY3MiwwLjc2OCw0LjQ0OCwwLjc2OGMyLjEyOCwwLDQuMDE2LTAuNjg4LDU" +
        "uNzEyLTIuMDY0ICAgYzEuNjgtMS4zNzYsMi41NDQtMy41MiwyLjU0NC02LjM4NGMwLTEuNTM2LTAuMjI0LTIuODY0LTAuNjI0LTMuOTg0Yy0" +
        "wLjQxNi0xLjEyLTEuMTA0LTIuMTI4LTIuMDY0LTMuMDA4ICAgYy0wLjk3Ni0wLjkxMi0yLjI0LTEuNzEyLTMuNzkyLTIuNDQ4cy0zLjUwNC0" +
        "xLjQ4OC01LjgwOC0yLjI1NmMtMy4wNTYtMS4wMjQtNS43MTItMi4xNi03Ljk2OC0zLjM3NiAgIGMtMi4yNC0xLjItNC4xMTItMi42MjQtNS4" +
        "2MTYtNC4yNzJjLTEuNTA0LTEuNjMyLTIuNjA4LTMuNTItMy4zMTItNS42NjRjLTAuNzA0LTIuMTYtMS4wNTYtNC42MjQtMS4wNTYtNy40NTY" +
        "gICBjMC02Ljc4NCwxLjg4OC0xMS44MjQsNS42NjQtMTUuMTUyYzMuNzYtMy4zMjgsOC45Ni00Ljk5MiwxNS41NTItNC45OTJjMy4wNzIsMCw" +
        "1LjkwNCwwLjMzNiw4LjQ5NiwxLjAwOHM0LjgzMiwxLjc0NCw2LjcyLDMuMjY0ICAgYzEuODg4LDEuNTA0LDMuMzYsMy40MjQsNC40MTYsNS4" +
        "3NDRjMS4wNCwyLjMzNiwxLjU4NCw1LjEzNiwxLjU4NCw4LjR2MS45MkgxNzkuNDRjMC0zLjI2NC0wLjU3Ni01Ljc3Ni0xLjcxMi03LjU1MiA" +
        "gIGMtMS4xNTItMS43NDQtMy4wNzItMi42NC01Ljc2LTIuNjRjLTEuNTM2LDAtMi44MTYsMC4yNC0zLjg0LDAuNjcyYy0xLjAwOCwwLjQ0OC0" +
        "xLjg0LDEuMDQtMi40NDgsMS43NzYgICBjLTAuNjA4LDAuNzM2LTEuMDA4LDEuNTg0LTEuMjMyLDIuNTQ0Yy0wLjI0LDAuOTYtMC4zMzYsMS4" +
        "5NTItMC4zMzYsMi45NzZjMCwyLjEyOCwwLjQ0OCwzLjg4OCwxLjM0NCw1LjMyOCAgIGMwLjg5NiwxLjQ1NiwyLjgxNiwyLjc4NCw1Ljc2LDM" +
        "uOTg0bDEwLjY1Niw0LjYwOGMyLjYyNCwxLjE1Miw0Ljc2OCwyLjM1Miw2LjQxNiwzLjYxNmMxLjY2NCwxLjI0OCwzLjAwOCwyLjU5MiwzLjk" +
        "4NCw0LjAzMiAgIGMwLjk5MiwxLjQ0LDEuNjgsMy4wMDgsMi4wNjQsNC43NTJjMC4zODQsMS43MTIsMC41NzYsMy42NDgsMC41NzYsNS43NDR" +
        "jMCw3LjIzMi0yLjA5NiwxMi40OTYtNi4yODgsMTUuNzkyICAgYy00LjE5MiwzLjI5Ni0xMC4wMzIsNC45Ni0xNy41Miw0Ljk2Yy03LjgwOCw" +
        "wLTEzLjM5Mi0xLjY5Ni0xNi43NjgtNS4wODhjLTMuMzYtMy4zOTItNS4wMjQtOC4yNTYtNS4wMjQtMTQuNTkydi0yLjc4NGgxMy44MjQgICB" +
        "MMTYzLjEzNiwxNjcuMDA4eiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjx" +
        "nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjw" +
        "vc3ZnPgo=",
    xls: "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRv" +
        "cjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+" +
        "CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGlu" +
        "ayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM4NCAzODQiIHN0eWxlPSJlbmFibGUt" +
        "YmFja2dyb3VuZDpuZXcgMCAwIDM4NCAzODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8" +
        "cG9seWdvbiBzdHlsZT0iZmlsbDojRUZFRUVFOyIgcG9pbnRzPSI2NCwwIDY0LDM4NCAyODgsMzg0IDM4NCwyODggMzg0LDAgIi8+Cjxwb2x5" +
        "Z29uIHN0eWxlPSJmaWxsOiNBQkFCQUI7IiBwb2ludHM9IjI4OCwyODggMjg4LDM4NCAzODQsMjg4ICIvPgo8cG9seWdvbiBzdHlsZT0iZmls" +
        "bDojREVERUREOyIgcG9pbnRzPSIxOTIsMzg0IDI4OCwzODQgMjg4LDI4OCAiLz4KPHBhdGggc3R5bGU9ImZpbGw6IzYxQjU2NTsiIGQ9Ik0w" +
        "LDk2djExMmgyNTZWOTZIMHoiLz4KPGc+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTQ5LjE4NCwxMTcuODU2bDguODMyLDIy" +
        "LjE3Nmw4LjczNi0yMi4xNzZoMTUuMDcybC0xNS45MzYsMzMuODg4TDgyLjk3NiwxODYuNEg2Ny40MjQgICBsLTkuODcyLTIzLjIzMkw0Ny43" +
        "NDQsMTg2LjRIMzIuNTc2bDE3LjA4OC0zNC42NTZMMzMuOTIsMTE3Ljg1Nkw0OS4xODQsMTE3Ljg1Nkw0OS4xODQsMTE3Ljg1NnoiLz4KCTxw" +
        "YXRoIHN0eWxlPSJmaWxsOiNGRkZGRkY7IiBkPSJNOTcuOTUyLDExNy44NTZoMTMuODI0djU3LjIxNmgyNC40NjRWMTg2LjRIOTcuOTUyTDk3" +
        "Ljk1MiwxMTcuODU2TDk3Ljk1MiwxMTcuODU2eiIvPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0xNjMuMTM2LDE2Ny4wMDhj" +
        "MCwxLjYsMC4xMjgsMy4wNTYsMC4zNjgsNC4zNTJjMC4yNzIsMS4zMTIsMC43MzYsMi40MTYsMS40NCwzLjMxMiAgIGMwLjcwNCwwLjkxMiwx" +
        "LjY2NCwxLjYxNiwyLjg0OCwyLjEyOGMxLjE2OCwwLjQ5NiwyLjY3MiwwLjc2OCw0LjQ0OCwwLjc2OGMyLjEyOCwwLDQuMDE2LTAuNjg4LDUu" +
        "NzEyLTIuMDY0ICAgYzEuNjgtMS4zNzYsMi41NDQtMy41MiwyLjU0NC02LjM4NGMwLTEuNTM2LTAuMjI0LTIuODY0LTAuNjI0LTMuOTg0Yy0w" +
        "LjQxNi0xLjEyLTEuMTA0LTIuMTI4LTIuMDY0LTMuMDA4ICAgYy0wLjk3Ni0wLjkxMi0yLjI0LTEuNzEyLTMuNzkyLTIuNDQ4cy0zLjUwNC0x" +
        "LjQ4OC01LjgwOC0yLjI1NmMtMy4wNTYtMS4wMjQtNS43MTItMi4xNi03Ljk2OC0zLjM3NiAgIGMtMi4yNC0xLjItNC4xMTItMi42MjQtNS42" +
        "MTYtNC4yNzJjLTEuNTA0LTEuNjMyLTIuNjA4LTMuNTItMy4zMTItNS42NjRjLTAuNzA0LTIuMTYtMS4wNTYtNC42MjQtMS4wNTYtNy40NTYg" +
        "ICBjMC02Ljc4NCwxLjg4OC0xMS44MjQsNS42NjQtMTUuMTUyYzMuNzYtMy4zMjgsOC45Ni00Ljk5MiwxNS41NTItNC45OTJjMy4wNzIsMCw1" +
        "LjkwNCwwLjMzNiw4LjQ5NiwxLjAwOHM0LjgzMiwxLjc0NCw2LjcyLDMuMjY0ICAgYzEuODg4LDEuNTA0LDMuMzYsMy40MjQsNC40MTYsNS43" +
        "NDRjMS4wNCwyLjMzNiwxLjU4NCw1LjEzNiwxLjU4NCw4LjR2MS45MkgxNzkuNDRjMC0zLjI2NC0wLjU3Ni01Ljc3Ni0xLjcxMi03LjU1MiAg" +
        "IGMtMS4xNTItMS43NDQtMy4wNzItMi42NC01Ljc2LTIuNjRjLTEuNTM2LDAtMi44MTYsMC4yNC0zLjg0LDAuNjcyYy0xLjAwOCwwLjQ0OC0x" +
        "Ljg0LDEuMDQtMi40NDgsMS43NzYgICBjLTAuNjA4LDAuNzM2LTEuMDA4LDEuNTg0LTEuMjMyLDIuNTQ0Yy0wLjI0LDAuOTYtMC4zMzYsMS45" +
        "NTItMC4zMzYsMi45NzZjMCwyLjEyOCwwLjQ0OCwzLjg4OCwxLjM0NCw1LjMyOCAgIGMwLjg5NiwxLjQ1NiwyLjgxNiwyLjc4NCw1Ljc2LDMu" +
        "OTg0bDEwLjY1Niw0LjYwOGMyLjYyNCwxLjE1Miw0Ljc2OCwyLjM1Miw2LjQxNiwzLjYxNmMxLjY2NCwxLjI0OCwzLjAwOCwyLjU5MiwzLjk4" +
        "NCw0LjAzMiAgIGMwLjk5MiwxLjQ0LDEuNjgsMy4wMDgsMi4wNjQsNC43NTJjMC4zODQsMS43MTIsMC41NzYsMy42NDgsMC41NzYsNS43NDRj" +
        "MCw3LjIzMi0yLjA5NiwxMi40OTYtNi4yODgsMTUuNzkyICAgYy00LjE5MiwzLjI5Ni0xMC4wMzIsNC45Ni0xNy41Miw0Ljk2Yy03LjgwOCww" +
        "LTEzLjM5Mi0xLjY5Ni0xNi43NjgtNS4wODhjLTMuMzYtMy4zOTItNS4wMjQtOC4yNTYtNS4wMjQtMTQuNTkydi0yLjc4NGgxMy44MjQgICBM" +
        "MTYzLjEzNiwxNjcuMDA4eiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjxn" +
        "Pgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwv" +
        "c3ZnPgo=",
    rar: "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRv" +
        "cjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+" +
        "CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGlu" +
        "ayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM4NCAzODQiIHN0eWxlPSJlbmFibGUt" +
        "YmFja2dyb3VuZDpuZXcgMCAwIDM4NCAzODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8" +
        "bGluZWFyR3JhZGllbnQgaWQ9IlNWR0lEXzFfIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9Ii04NS40NjA1IiB5MT0iNTUz" +
        "Ljk1NzUiIHgyPSItMzIuNjEzNSIgeTI9IjUwMS4xMTQ1IiBncmFkaWVudFRyYW5zZm9ybT0ibWF0cml4KDggMCAwIC04IDc5My4wMTYgNDUw" +
        "OSkiPgoJPHN0b3Agb2Zmc2V0PSIwIiBzdHlsZT0ic3RvcC1jb2xvcjojRUZFRUVFIi8+Cgk8c3RvcCBvZmZzZXQ9IjEiIHN0eWxlPSJzdG9w" +
        "LWNvbG9yOiNERURFREQiLz4KPC9saW5lYXJHcmFkaWVudD4KPHBvbHlnb24gc3R5bGU9ImZpbGw6dXJsKCNTVkdJRF8xXyk7IiBwb2ludHM9" +
        "IjY0LDAgNjQsMzg0IDI4OCwzODQgMzg0LDI4OCAzODQsMCAiLz4KPHBvbHlnb24gc3R5bGU9ImZpbGw6I0FCQUJBQjsiIHBvaW50cz0iMjg4" +
        "LDI4OCAyODgsMzg0IDM4NCwyODggIi8+Cjxwb2x5Z29uIHN0eWxlPSJmaWxsOiNERURFREQ7IiBwb2ludHM9IjE5MiwzODQgMjg4LDM4NCAy" +
        "ODgsMjg4ICIvPgo8cGF0aCBzdHlsZT0iZmlsbDojNDAyRDdBOyIgZD0iTTAsOTZ2MTEyaDI1NlY5NkgweiIvPgo8Zz4KCTxwYXRoIHN0eWxl" +
        "PSJmaWxsOiNGRkZGRkY7IiBkPSJNNTkuNTY4LDEyOS4xODRIMzYuMDMydi0xMS4zMjhoMzkuNDU2djEwLjQ2NGwtMjUuNjMyLDQ2Ljc1Mmgy" +
        "Ni4wMTZWMTg2LjRIMzQuMzJ2LTEwLjA4ICAgTDU5LjU2OCwxMjkuMTg0eiIvPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik05" +
        "My4yNDgsMTE3Ljg1NmgxMy44MjRWMTg2LjRIOTMuMjQ4VjExNy44NTZ6Ii8+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTE1" +
        "MS4xMzYsMTE3Ljg0YzMuNTIsMCw2LjQ2NCwwLjYwOCw4Ljg4LDEuNzkyczQuMzM2LDIuNzM2LDUuODA4LDQuNjU2czIuNTI4LDQuMTEyLDMu" +
        "MTY4LDYuNTc2ICAgYzAuNjI0LDIuNDQ4LDAuOTYsNC45NzYsMC45Niw3LjUzNmMwLDMuNTItMC41MjgsNi41OTItMS41ODQsOS4yMTZjLTEu" +
        "MDU2LDIuNjI0LTIuNTI4LDQuNzY4LTQuNDE2LDYuNDE2ICAgYy0xLjg4OCwxLjY2NC00LjE3NiwyLjkyOC02Ljg2NCwzLjc0NHMtNS42NjQs" +
        "MS4yNDgtOC45NDQsMS4yNDhoLTcuMzkydjI3LjM2aC0xMy44MDhWMTE3Ljg0SDE1MS4xMzZ6IE0xNDYuOTEyLDE0OC44OTYgICBjMi43NTIs" +
        "MCw0Ljk5Mi0wLjg0OCw2LjY3Mi0yLjUxMmMxLjY5Ni0xLjY2NCwyLjU0NC00LjI1NiwyLjU0NC03Ljc3NmMwLTMuNDU2LTAuNzM2LTYuMDgt" +
        "Mi4yMDgtNy44NzIgICBjLTEuNDcyLTEuNzc2LTMuOTM2LTIuNjg4LTcuMzkyLTIuNjg4aC01Ljc2djIwLjg0OEgxNDYuOTEyeiIvPgo8L2c+" +
        "CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+" +
        "CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=",
    zip: "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXR" +
        "vcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0" +
        "+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGl" +
        "uayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM4NCAzODQiIHN0eWxlPSJlbmFibGU" +
        "tYmFja2dyb3VuZDpuZXcgMCAwIDM4NCAzODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo" +
        "8bGluZWFyR3JhZGllbnQgaWQ9IlNWR0lEXzFfIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9Ii04NS40NjA1IiB5MT0iNTU" +
        "zLjk1NzUiIHgyPSItMzIuNjEzNSIgeTI9IjUwMS4xMTQ1IiBncmFkaWVudFRyYW5zZm9ybT0ibWF0cml4KDggMCAwIC04IDc5My4wMTYgND" +
        "UwOSkiPgoJPHN0b3Agb2Zmc2V0PSIwIiBzdHlsZT0ic3RvcC1jb2xvcjojRUZFRUVFIi8+Cgk8c3RvcCBvZmZzZXQ9IjEiIHN0eWxlPSJzd" +
        "G9wLWNvbG9yOiNERURFREQiLz4KPC9saW5lYXJHcmFkaWVudD4KPHBvbHlnb24gc3R5bGU9ImZpbGw6dXJsKCNTVkdJRF8xXyk7IiBwb2lu" +
        "dHM9IjY0LDAgNjQsMzg0IDI4OCwzODQgMzg0LDI4OCAzODQsMCAiLz4KPHBvbHlnb24gc3R5bGU9ImZpbGw6I0FCQUJBQjsiIHBvaW50cz0" +
        "iMjg4LDI4OCAyODgsMzg0IDM4NCwyODggIi8+Cjxwb2x5Z29uIHN0eWxlPSJmaWxsOiNERURFREQ7IiBwb2ludHM9IjE5MiwzODQgMjg4LD" +
        "M4NCAyODgsMjg4ICIvPgo8cGF0aCBzdHlsZT0iZmlsbDojNDAyRDdBOyIgZD0iTTAsOTZ2MTEyaDI1NlY5NkgweiIvPgo8Zz4KCTxwYXRoI" +
        "HN0eWxlPSJmaWxsOiNGRkZGRkY7IiBkPSJNNTkuNTY4LDEyOS4xODRIMzYuMDMydi0xMS4zMjhoMzkuNDU2djEwLjQ2NGwtMjUuNjMyLDQ2" +
        "Ljc1MmgyNi4wMTZWMTg2LjRIMzQuMzJ2LTEwLjA4ICAgTDU5LjU2OCwxMjkuMTg0eiIvPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjs" +
        "iIGQ9Ik05My4yNDgsMTE3Ljg1NmgxMy44MjRWMTg2LjRIOTMuMjQ4VjExNy44NTZ6Ii8+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOy" +
        "IgZD0iTTE1MS4xMzYsMTE3Ljg0YzMuNTIsMCw2LjQ2NCwwLjYwOCw4Ljg4LDEuNzkyczQuMzM2LDIuNzM2LDUuODA4LDQuNjU2czIuNTI4L" +
        "DQuMTEyLDMuMTY4LDYuNTc2ICAgYzAuNjI0LDIuNDQ4LDAuOTYsNC45NzYsMC45Niw3LjUzNmMwLDMuNTItMC41MjgsNi41OTItMS41ODQs" +
        "OS4yMTZjLTEuMDU2LDIuNjI0LTIuNTI4LDQuNzY4LTQuNDE2LDYuNDE2ICAgYy0xLjg4OCwxLjY2NC00LjE3NiwyLjkyOC02Ljg2NCwzLjc" +
        "0NHMtNS42NjQsMS4yNDgtOC45NDQsMS4yNDhoLTcuMzkydjI3LjM2aC0xMy44MDhWMTE3Ljg0SDE1MS4xMzZ6IE0xNDYuOTEyLDE0OC44OT" +
        "YgICBjMi43NTIsMCw0Ljk5Mi0wLjg0OCw2LjY3Mi0yLjUxMmMxLjY5Ni0xLjY2NCwyLjU0NC00LjI1NiwyLjU0NC03Ljc3NmMwLTMuNDU2L" +
        "TAuNzM2LTYuMDgtMi4yMDgtNy44NzIgICBjLTEuNDcyLTEuNzc2LTMuOTM2LTIuNjg4LTcuMzkyLTIuNjg4aC01Ljc2djIwLjg0OEgxNDYu" +
        "OTEyeiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo" +
        "8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=",
    xml: "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYX" +
        "RvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgL" +
        "S0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94" +
        "bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM4NCAzODQiIHN0eWxlPSJlbmF" +
        "ibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4NCAzODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycH" +
        "giPgo8cG9seWdvbiBzdHlsZT0iZmlsbDojRUZFRUVFOyIgcG9pbnRzPSI2NCwwIDY0LDM4NCAyODgsMzg0IDM4NCwyODggMzg0LDAgIi8+C" +
        "jxwb2x5Z29uIHN0eWxlPSJmaWxsOiNBQkFCQUI7IiBwb2ludHM9IjI4OCwyODggMjg4LDM4NCAzODQsMjg4ICIvPgo8cG9seWdvbiBzdHls" +
        "ZT0iZmlsbDojREVERUREOyIgcG9pbnRzPSIxOTIsMzg0IDI4OCwzODQgMjg4LDI4OCAiLz4KPHBhdGggc3R5bGU9ImZpbGw6I0NCNTY0MTs" +
        "iIGQ9Ik0wLDk2djExMmgyNTZWOTZIMHoiLz4KPGc+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTQ5LjE4NCwxMTcuODU2bD" +
        "guODMyLDIyLjE3Nmw4LjczNi0yMi4xNzZoMTUuMDcybC0xNS45MzYsMzMuODg4TDgyLjk3NiwxODYuNEg2Ny40MjQgICBsLTkuODcyLTIzL" +
        "jIzMkw0Ny43NDQsMTg2LjRIMzIuNTc2bDE3LjA4OC0zNC42NTZMMzMuOTIsMTE3Ljg1Nkw0OS4xODQsMTE3Ljg1Nkw0OS4xODQsMTE3Ljg1" +
        "NnoiLz4KCTxwYXRoIHN0eWxlPSJmaWxsOiNGRkZGRkY7IiBkPSJNMTE4Ljc4NCwxMTcuODU2bDkuNzkyLDQ4LjM4NGgwLjE5Mmw5Ljg4OC" +
        "00OC4zODRoMjAuNDQ4VjE4Ni40aC0xMi42NzJ2LTU0LjcyaC0wLjE5MiAgIGwtMTIuMTkyLDU0LjcyaC0xMC43NTJsLTEyLjE5Mi01NC43M" +
        "mgtMC4xOTJ2NTQuNzJIOTguMjR2LTY4LjU0NEwxMTguNzg0LDExNy44NTZ6Ii8+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0i" +
        "TTE3OC41NzYsMTE3Ljg1NmgxMy44NHY1Ny4yMTZoMjQuNDY0VjE4Ni40aC0zOC4zMDRWMTE3Ljg1NnoiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4" +
        "KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz" +
        "4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K",
    html: "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYX" +
        "RvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS" +
        "0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bG" +
        "luayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM4NCAzODQiIHN0eWxlPSJlbmFibG" +
        "UtYmFja2dyb3VuZDpuZXcgMCAwIDM4NCAzODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPg" +
        "o8cG9seWdvbiBzdHlsZT0iZmlsbDojRUZFRUVFOyIgcG9pbnRzPSI2NC4wMDQsMCA2NC4wMDQsMzg0IDI4Ny45OTYsMzg0IDM4My45OTYsMj" +
        "g4IDM4My45OTYsMCAiLz4KPHBvbHlnb24gc3R5bGU9ImZpbGw6I0FCQUJBQjsiIHBvaW50cz0iMjg3Ljk5NiwyODggMjg3Ljk5NiwzODQgMz" +
        "gzLjk5NiwyODggIi8+Cjxwb2x5Z29uIHN0eWxlPSJmaWxsOiNERURFREQ7IiBwb2ludHM9IjE5MS45OTYsMzg0IDI4Ny45OTYsMzg0IDI4Ny" +
        "45OTYsMjg4ICIvPgo8cGF0aCBzdHlsZT0iZmlsbDojMkI4NEMzOyIgZD0iTTAuMDA0LDk2djExMmgyNTZWOTZIMC4wMDR6Ii8+CjxnPgoJPH" +
        "BhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0zMC45NTYsMTE3Ljg1NnYyNi4yMDhoMTYuMTI4di0yNi4yMDhoMTMuODI0VjE4Ni40SD" +
        "Q3LjA4NHYtMzAuNDMySDMwLjk1NlYxODYuNEgxNy4xMzJ2LTY4LjU0NCAgIEwzMC45NTYsMTE3Ljg1NnoiLz4KCTxwYXRoIHN0eWxlPSJmaW" +
        "xsOiNGRkZGRkY7IiBkPSJNMTE2Ljc4OCwxMjkuMTg0aC0xNC45NzZWMTg2LjRoLTEzLjg0di01Ny4yMTZoLTE0Ljk2di0xMS4zMjhoNDMuNz" +
        "c2VjEyOS4xODR6Ii8+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTE1MC40NjgsMTE3Ljg1Nmw5Ljc5Miw0OC4zODRoMC4xOT" +
        "JsOS44ODgtNDguMzg0aDIwLjQ0OFYxODYuNGgtMTIuNjcydi01NC43MmgtMC4yMDggICBsLTEyLjE5Miw1NC43MmgtMTAuNzUybC0xMi4xOT" +
        "ItNTQuNzJoLTAuMTkydjU0LjcyaC0xMi42NzJ2LTY4LjU0NEwxNTAuNDY4LDExNy44NTZ6Ii8+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRk" +
        "ZGOyIgZD0iTTIwNC4xNjQsMTE3Ljg1NmgxMy44NHY1Ny4yMTZoMjQuNDhWMTg2LjRoLTM4LjMyVjExNy44NTZ6Ii8+CjwvZz4KPGc+CjwvZz" +
        "4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz" +
        "4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==",
    txt: "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRv" +
        "cjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+" +
        "CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGlu" +
        "ayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM4NCAzODQiIHN0eWxlPSJlbmFibGUt" +
        "YmFja2dyb3VuZDpuZXcgMCAwIDM4NCAzODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8" +
        "cG9seWdvbiBzdHlsZT0iZmlsbDojRUZFRUVFOyIgcG9pbnRzPSI2NCwwIDY0LDM4NCAyODgsMzg0IDM4NCwyODggMzg0LDAgIi8+Cjxwb2x5" +
        "Z29uIHN0eWxlPSJmaWxsOiNBQkFCQUI7IiBwb2ludHM9IjI4OCwyODggMjg4LDM4NCAzODQsMjg4ICIvPgo8cG9seWdvbiBzdHlsZT0iZmls" +
        "bDojREVERUREOyIgcG9pbnRzPSIxOTIsMzg0IDI4OCwzODQgMjg4LDI4OCAiLz4KPHBhdGggc3R5bGU9ImZpbGw6IzE1NDk4QTsiIGQ9Ik0w" +
        "LDk2djExMmgyNTZWOTZMMCw5NkwwLDk2eiIvPgo8Zz4KCTxwYXRoIHN0eWxlPSJmaWxsOiNGRkZGRkY7IiBkPSJNNzYuOTI4LDEyOS4xODRI" +
        "NjEuOTUyVjE4Ni40SDQ4LjEyOHYtNTcuMjE2SDMzLjE1MnYtMTEuMzI4aDQzLjc3NlYxMjkuMTg0eiIvPgoJPHBhdGggc3R5bGU9ImZpbGw6" +
        "I0ZGRkZGRjsiIGQ9Ik0xMDQuODgsMTE3Ljg1Nmw4LjgzMiwyMi4xNzZsOC43MzYtMjIuMTc2aDE1LjA1NmwtMTUuOTIsMzMuODg4bDE3LjA3" +
        "MiwzNC42NTZoLTE1LjU1MiAgIGwtOS44ODgtMjMuMjMybC05Ljc5MiwyMy4yMzJIODguMjcybDE3LjA3Mi0zNC42NTZsLTE1LjcyOC0zMy44" +
        "ODhIMTA0Ljg4eiIvPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0xOTMuNzYsMTI5LjE4NGgtMTQuOTkyVjE4Ni40SDE2NC45" +
        "NnYtNTcuMjE2aC0xNC45NzZ2LTExLjMyOGg0My43NzZWMTI5LjE4NHoiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4K" +
        "PC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4K" +
        "PC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K",
    docx: "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXR" +
        "vcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0" +
        "+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGl" +
        "uayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM4NCAzODQiIHN0eWxlPSJlbmFibGU" +
        "tYmFja2dyb3VuZDpuZXcgMCAwIDM4NCAzODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo" +
        "8cG9seWdvbiBzdHlsZT0iZmlsbDojRUZFRUVFOyIgcG9pbnRzPSI2NCwwIDY0LDM4NCAyODgsMzg0IDM4NCwyODggMzg0LDAgIi8+Cjxwb2x" +
        "5Z29uIHN0eWxlPSJmaWxsOiNBQkFCQUI7IiBwb2ludHM9IjI4OCwyODggMjg4LDM4NCAzODQsMjg4ICIvPgo8cG9seWdvbiBzdHlsZT0iZml" +
        "sbDojREVERUREOyIgcG9pbnRzPSIxOTIsMzg0IDI4OCwzODQgMjg4LDI4OCAiLz4KPHBhdGggc3R5bGU9ImZpbGw6IzRFOTVEMDsiIGQ9Ik0" +
        "wLDk2djExMmgyNTZWOTZMMCw5NkwwLDk2eiIvPgo8Zz4KCTxwYXRoIHN0eWxlPSJmaWxsOiNGRkZGRkY7IiBkPSJNNjAuMTI4LDExNy44NTZ" +
        "jNC44MTYsMCw4LjY3MiwwLjc4NCwxMS42MzIsMi4zNTJjMi45NDQsMS41NjgsNS4yMzIsMy43OTIsNi44NjQsNi43MiAgIGMxLjY0OCwyLjk" +
        "xMiwyLjczNiw2LjQsMy4zMTIsMTAuNTEyYzAuNTc2LDQuMDk2LDAuODY0LDguNjcyLDAuODY0LDEzLjcxMmMwLDYuMDE2LTAuMzUyLDExLjI" +
        "0OC0xLjA4OCwxNS42OTYgICBjLTAuNzM2LDQuNDMyLTIsOC4xMTItMy43OTIsMTAuOTkyYy0xLjc5MiwyLjg5Ni00LjE5Miw1LjAyNC03LjI" +
        "sNi40MzJjLTMuMDQsMS40MjQtNi44NDgsMi4xMjgtMTEuNDU2LDIuMTI4SDM3LjQ3MnYtNjguNTQ0ICAgTDYwLjEyOCwxMTcuODU2eiBNNTc" +
        "uNDQsMTc2LjI0YzIuNDMyLDAsNC4zODQtMC40MTYsNS44NTYtMS4yNDhzMi42NC0yLjIwOCwzLjUwNC00LjEyOGMwLjg4LTEuOTIsMS40NTY" +
        "tNC40NDgsMS43MjgtNy42ICAgYzAuMjg4LTMuMTIsMC40MzItNy4wMjQsMC40MzItMTEuNjk2YzAtMy45MDQtMC4xNDQtNy4zMjgtMC4zODQ" +
        "tMTAuMjg4Yy0wLjI1Ni0yLjk0NC0wLjc4NC01LjM5Mi0xLjU4NC03LjM0NCAgIGMtMC44MTYtMS45NTItMS45ODQtMy40MjQtMy41NjgtNC4" +
        "0Yy0xLjU2OC0wLjk5Mi0zLjY2NC0xLjUwNC02LjI4OC0xLjUwNGgtNS44NHY0OC4yMDhMNTcuNDQsMTc2LjI0eiIvPgoJPHBhdGggc3R5bGU" +
        "9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0xMDAuNzg0LDEzNy43NzZjMC40OC00LjM2OCwxLjU1Mi04LjE0NCwzLjE2OC0xMS4yOGMxLjY0OC0zLjE" +
        "yLDMuOTg0LTUuNTg0LDcuMDU2LTcuMzQ0ICAgYzMuMDU2LTEuNzQ0LDcuMi0yLjY0LDEyLjM2OC0yLjY0YzUuMTg0LDAsOS4zMTIsMC44OTY" +
        "sMTIuMzg0LDIuNjRjMy4wNTYsMS43Niw1LjQyNCw0LjIyNCw3LjA0LDcuMzQ0ICAgYzEuNjQ4LDMuMTM2LDIuNjg4LDYuOTEyLDMuMTY4LDE" +
        "xLjI4YzAuNDgsNC4zODQsMC43Miw5LjE2OCwwLjcyLDE0LjM1MmMwLDUuMjMyLTAuMjQsMTAuMDQ4LTAuNzIsMTQuMzg0ICAgYy0wLjQ4LDQ" +
        "uMzUyLTEuNTM2LDguMDk2LTMuMTY4LDExLjIzMnMtMy45ODQsNS41MzYtNy4wNCw3LjJjLTMuMDcyLDEuNjY0LTcuMiwyLjQ5Ni0xMi4zODQ" +
        "sMi40OTYgICBjLTUuMTY4LDAtOS4zMTItMC44NDgtMTIuMzY4LTIuNDk2Yy0zLjA3Mi0xLjY2NC01LjQyNC00LjA2NC03LjA1Ni03LjJzLTI" +
        "uNjg4LTYuODgtMy4xNjgtMTEuMjMyICAgYy0wLjQ2NC00LjMzNi0wLjcyLTkuMTUyLTAuNzItMTQuMzg0QzEwMC4wNjQsMTQ2Ljk2LDEwMC4" +
        "zMiwxNDIuMTc2LDEwMC43ODQsMTM3Ljc3NnogTTExNC4yNCwxNjMuODg4ICAgYzAuMjI0LDMuMjMyLDAuNjcyLDUuODU2LDEuMzQ0LDcuODc" +
        "yczEuNjMyLDMuNDg4LDIuODgsNC40MTZzMi44OTYsMS4zOTIsNC45NDQsMS4zOTJzMy42OTYtMC40NjQsNC45Ni0xLjM5MiAgIGMxLjI0OC0" +
        "wLjk0NCwyLjIwOC0yLjQsMi44OC00LjQxNnMxLjEyLTQuNjQsMS4zNDQtNy44NzJzMC4zMzYtNy4xMzYsMC4zMzYtMTEuNzQ0cy0wLjEyOC0" +
        "4LjUxMi0wLjMzNi0xMS43MTIgICBzLTAuNjcyLTUuODI0LTEuMzQ0LTcuODcycy0xLjYzMi0zLjUzNi0yLjg4LTQuNDQ4Yy0xLjI0OC0wLjk" +
        "0NC0yLjkxMi0xLjQwOC00Ljk2LTEuNDA4cy0zLjY4LDAuNDY0LTQuOTQ0LDEuNDA4ICAgYy0xLjI0OCwwLjkyOC0yLjIwOCwyLjQtMi44OCw" +
        "0LjQ0OHMtMS4xMiw0LjY3Mi0xLjM0NCw3Ljg3MmMtMC4yNCwzLjItMC4zMzYsNy4xMDQtMC4zMzYsMTEuNzEyUzExNCwxNjAuNjU2LDExNC4" +
        "yNCwxNjMuODg4eiIvPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0xOTEuOTg0LDEzMC4xMTJjLTEuMTg0LTIuMjg4LTMuMzQ" +
        "0LTMuNDI0LTYuNDgtMy40MjRjLTEuNzEyLDAtMy4xMzYsMC40NjQtNC4yNzIsMS40MDggICBjLTEuMTIsMC45MjgtMiwyLjQxNi0yLjY0LDQ" +
        "uNDk2cy0xLjA4OCw0LjgtMS4zNDQsOC4xNzZjLTAuMjU2LDMuMzYtMC4zNjgsNy40NzItMC4zNjgsMTIuMzM2YzAsNS4xODQsMC4xNzYsOS4" +
        "zNzYsMC41MTIsMTIuNTc2ICAgYzAuMzUyLDMuMiwwLjkxMiw1LjY2NCwxLjY0OCw3LjQ0czEuNjY0LDIuOTYsMi43ODQsMy41NTJjMS4xMiw" +
        "wLjYwOCwyLjQxNiwwLjkyOCwzLjg4OCwwLjkyOGMxLjIxNiwwLDIuMzM2LTAuMjA4LDMuNDA4LTAuNjI0ICAgYzEuMDQtMC40MTYsMS45Njg" +
        "tMS4yNDgsMi43MzYtMi40OTZzMS4zNzYtMy4wMDgsMS44MjQtNS4yOGMwLjQzMi0yLjI3MiwwLjY3Mi01LjI2NCwwLjY3Mi04Ljk3NmgxMy4" +
        "4MDggICBjMCwzLjY5Ni0wLjI4OCw3LjIzMi0wLjg2NCwxMC41NmMtMC41NzYsMy4zMjgtMS42NDgsNi4yNC0zLjIxNiw4LjczNmMtMS41Njg" +
        "sMi40OC0zLjc2LDQuNDMyLTYuNjI0LDUuODQgICBjLTIuODQ4LDEuNDA4LTYuNTI4LDIuMTI4LTExLjA3MiwyLjEyOGMtNS4xODQsMC05LjM" +
        "xMi0wLjg0OC0xMi4zODQtMi40OTZjLTMuMDU2LTEuNjY0LTUuNDI0LTQuMDY0LTcuMDQtNy4yICAgYy0xLjY0OC0zLjEzNi0yLjY4OC02Ljg" +
        "4LTMuMTY4LTExLjIzMmMtMC40OC00LjMzNi0wLjcyLTkuMTUyLTAuNzItMTQuMzg0YzAtNS4xODQsMC4yNC05Ljk2OCwwLjcyLTE0LjM1MiA" +
        "gIGMwLjQ4LTQuMzY4LDEuNTM2LTguMTQ0LDMuMTY4LTExLjI4YzEuNjMyLTMuMTIsMy45ODQtNS41ODQsNy4wNC03LjM0NGMzLjA3Mi0xLjc" +
        "0NCw3LjItMi42NCwxMi4zODQtMi42NCAgIGM0LjkyOCwwLDguODE2LDAuOCwxMS42NjQsMi40YzIuODQ4LDEuNiw0Ljk5MiwzLjYzMiw2LjM" +
        "4NCw2LjA5NmMxLjM5MiwyLjQ2NCwyLjI4OCw1LjEyLDIuNjQsNy45NjggICBjMC4zMzYsMi44NDgsMC41MjgsNS41MiwwLjUyOCw4LjAxNmg" +
        "tMTMuODRDMTkzLjc2LDEzNiwxOTMuMTY4LDEzMi4zNjgsMTkxLjk4NCwxMzAuMTEyeiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo" +
        "8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo" +
        "8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=",
    doc: "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRv" +
        "cjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKS" +
        "AgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMT" +
        "k5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM4NCAzODQiIHN0e" +
        "WxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4NCAzODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaW" +
        "dodD0iNTEycHgiPgo8cG9seWdvbiBzdHlsZT0iZmlsbDojRUZFRUVFOyIgcG9pbnRzPSI2NCwwIDY0LDM4NCAyODgsMzg0IDM4NCwyODg" +
        "gMzg0LDAgIi8+Cjxwb2x5Z29uIHN0eWxlPSJmaWxsOiNBQkFCQUI7IiBwb2ludHM9IjI4OCwyODggMjg4LDM4NCAzODQsMjg4ICIvPgo8c" +
        "G9seWdvbiBzdHlsZT0iZmlsbDojREVERUREOyIgcG9pbnRzPSIxOTIsMzg0IDI4OCwzODQgMjg4LDI4OCAiLz4KPHBhdGggc3R5bGU9ImZ" +
        "pbGw6IzRFOTVEMDsiIGQ9Ik0wLDk2djExMmgyNTZWOTZMMCw5NkwwLDk2eiIvPgo8Zz4KCTxwYXRoIHN0eWxlPSJmaWxsOiNGRkZGRkY7I" +
        "iBkPSJNNjAuMTI4LDExNy44NTZjNC44MTYsMCw4LjY3MiwwLjc4NCwxMS42MzIsMi4zNTJjMi45NDQsMS41NjgsNS4yMzIsMy43OTIsNi" +
        "44NjQsNi43MiAgIGMxLjY0OCwyLjkxMiwyLjczNiw2LjQsMy4zMTIsMTAuNTEyYzAuNTc2LDQuMDk2LDAuODY0LDguNjcyLDAuODY0LDE" +
        "zLjcxMmMwLDYuMDE2LTAuMzUyLDExLjI0OC0xLjA4OCwxNS42OTYgICBjLTAuNzM2LDQuNDMyLTIsOC4xMTItMy43OTIsMTAuOTkyYy0x" +
        "Ljc5MiwyLjg5Ni00LjE5Miw1LjAyNC03LjIsNi40MzJjLTMuMDQsMS40MjQtNi44NDgsMi4xMjgtMTEuNDU2LDIuMTI4SDM3LjQ3MnYtN" +
        "jguNTQ0ICAgTDYwLjEyOCwxMTcuODU2eiBNNTcuNDQsMTc2LjI0YzIuNDMyLDAsNC4zODQtMC40MTYsNS44NTYtMS4yNDhzMi42NC0yLj" +
        "IwOCwzLjUwNC00LjEyOGMwLjg4LTEuOTIsMS40NTYtNC40NDgsMS43MjgtNy42ICAgYzAuMjg4LTMuMTIsMC40MzItNy4wMjQsMC40MzI" +
        "tMTEuNjk2YzAtMy45MDQtMC4xNDQtNy4zMjgtMC4zODQtMTAuMjg4Yy0wLjI1Ni0yLjk0NC0wLjc4NC01LjM5Mi0xLjU4NC03LjM0NCAg" +
        "IGMtMC44MTYtMS45NTItMS45ODQtMy40MjQtMy41NjgtNC40Yy0xLjU2OC0wLjk5Mi0zLjY2NC0xLjUwNC02LjI4OC0xLjUwNGgtNS44N" +
        "HY0OC4yMDhMNTcuNDQsMTc2LjI0eiIvPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0xMDAuNzg0LDEzNy43NzZjMC40OC" +
        "00LjM2OCwxLjU1Mi04LjE0NCwzLjE2OC0xMS4yOGMxLjY0OC0zLjEyLDMuOTg0LTUuNTg0LDcuMDU2LTcuMzQ0ICAgYzMuMDU2LTEuNzQ" +
        "0LDcuMi0yLjY0LDEyLjM2OC0yLjY0YzUuMTg0LDAsOS4zMTIsMC44OTYsMTIuMzg0LDIuNjRjMy4wNTYsMS43Niw1LjQyNCw0LjIyNCw" +
        "3LjA0LDcuMzQ0ICAgYzEuNjQ4LDMuMTM2LDIuNjg4LDYuOTEyLDMuMTY4LDExLjI4YzAuNDgsNC4zODQsMC43Miw5LjE2OCwwLjcyLDE" +
        "0LjM1MmMwLDUuMjMyLTAuMjQsMTAuMDQ4LTAuNzIsMTQuMzg0ICAgYy0wLjQ4LDQuMzUyLTEuNTM2LDguMDk2LTMuMTY4LDExLjIzMnM" +
        "tMy45ODQsNS41MzYtNy4wNCw3LjJjLTMuMDcyLDEuNjY0LTcuMiwyLjQ5Ni0xMi4zODQsMi40OTYgICBjLTUuMTY4LDAtOS4zMTItMC4" +
        "4NDgtMTIuMzY4LTIuNDk2Yy0zLjA3Mi0xLjY2NC01LjQyNC00LjA2NC03LjA1Ni03LjJzLTIuNjg4LTYuODgtMy4xNjgtMTEuMjMyICA" +
        "gYy0wLjQ2NC00LjMzNi0wLjcyLTkuMTUyLTAuNzItMTQuMzg0QzEwMC4wNjQsMTQ2Ljk2LDEwMC4zMiwxNDIuMTc2LDEwMC43ODQsMTM" +
        "3Ljc3NnogTTExNC4yNCwxNjMuODg4ICAgYzAuMjI0LDMuMjMyLDAuNjcyLDUuODU2LDEuMzQ0LDcuODcyczEuNjMyLDMuNDg4LDIuODg" +
        "sNC40MTZzMi44OTYsMS4zOTIsNC45NDQsMS4zOTJzMy42OTYtMC40NjQsNC45Ni0xLjM5MiAgIGMxLjI0OC0wLjk0NCwyLjIwOC0yLjQ" +
        "sMi44OC00LjQxNnMxLjEyLTQuNjQsMS4zNDQtNy44NzJzMC4zMzYtNy4xMzYsMC4zMzYtMTEuNzQ0cy0wLjEyOC04LjUxMi0wLjMzNi0" +
        "xMS43MTIgICBzLTAuNjcyLTUuODI0LTEuMzQ0LTcuODcycy0xLjYzMi0zLjUzNi0yLjg4LTQuNDQ4Yy0xLjI0OC0wLjk0NC0yLjkxMi0" +
        "xLjQwOC00Ljk2LTEuNDA4cy0zLjY4LDAuNDY0LTQuOTQ0LDEuNDA4ICAgYy0xLjI0OCwwLjkyOC0yLjIwOCwyLjQtMi44OCw0LjQ0OHM" +
        "tMS4xMiw0LjY3Mi0xLjM0NCw3Ljg3MmMtMC4yNCwzLjItMC4zMzYsNy4xMDQtMC4zMzYsMTEuNzEyUzExNCwxNjAuNjU2LDExNC4yNCw" +
        "xNjMuODg4eiIvPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0xOTEuOTg0LDEzMC4xMTJjLTEuMTg0LTIuMjg4LTMuMzQ" +
        "0LTMuNDI0LTYuNDgtMy40MjRjLTEuNzEyLDAtMy4xMzYsMC40NjQtNC4yNzIsMS40MDggICBjLTEuMTIsMC45MjgtMiwyLjQxNi0yLjY" +
        "0LDQuNDk2cy0xLjA4OCw0LjgtMS4zNDQsOC4xNzZjLTAuMjU2LDMuMzYtMC4zNjgsNy40NzItMC4zNjgsMTIuMzM2YzAsNS4xODQsMC4" +
        "xNzYsOS4zNzYsMC41MTIsMTIuNTc2ICAgYzAuMzUyLDMuMiwwLjkxMiw1LjY2NCwxLjY0OCw3LjQ0czEuNjY0LDIuOTYsMi43ODQsMy4" +
        "1NTJjMS4xMiwwLjYwOCwyLjQxNiwwLjkyOCwzLjg4OCwwLjkyOGMxLjIxNiwwLDIuMzM2LTAuMjA4LDMuNDA4LTAuNjI0ICAgYzEuMDQ" +
        "tMC40MTYsMS45NjgtMS4yNDgsMi43MzYtMi40OTZzMS4zNzYtMy4wMDgsMS44MjQtNS4yOGMwLjQzMi0yLjI3MiwwLjY3Mi01LjI2NCw" +
        "wLjY3Mi04Ljk3NmgxMy44MDggICBjMCwzLjY5Ni0wLjI4OCw3LjIzMi0wLjg2NCwxMC41NmMtMC41NzYsMy4zMjgtMS42NDgsNi4yNC0" +
        "zLjIxNiw4LjczNmMtMS41NjgsMi40OC0zLjc2LDQuNDMyLTYuNjI0LDUuODQgICBjLTIuODQ4LDEuNDA4LTYuNTI4LDIuMTI4LTExLjA" +
        "3MiwyLjEyOGMtNS4xODQsMC05LjMxMi0wLjg0OC0xMi4zODQtMi40OTZjLTMuMDU2LTEuNjY0LTUuNDI0LTQuMDY0LTcuMDQtNy4yICA" +
        "gYy0xLjY0OC0zLjEzNi0yLjY4OC02Ljg4LTMuMTY4LTExLjIzMmMtMC40OC00LjMzNi0wLjcyLTkuMTUyLTAuNzItMTQuMzg0YzAtNS4" +
        "xODQsMC4yNC05Ljk2OCwwLjcyLTE0LjM1MiAgIGMwLjQ4LTQuMzY4LDEuNTM2LTguMTQ0LDMuMTY4LTExLjI4YzEuNjMyLTMuMTIsMy4" +
        "5ODQtNS41ODQsNy4wNC03LjM0NGMzLjA3Mi0xLjc0NCw3LjItMi42NCwxMi4zODQtMi42NCAgIGM0LjkyOCwwLDguODE2LDAuOCwxMS4" +
        "2NjQsMi40YzIuODQ4LDEuNiw0Ljk5MiwzLjYzMiw2LjM4NCw2LjA5NmMxLjM5MiwyLjQ2NCwyLjI4OCw1LjEyLDIuNjQsNy45NjggICB" +
        "jMC4zMzYsMi44NDgsMC41MjgsNS41MiwwLjUyOCw4LjAxNmgtMTMuODRDMTkzLjc2LDEzNiwxOTMuMTY4LDEzMi4zNjgsMTkxLjk4NCw" +
        "xMzAuMTEyeiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c" +
        "+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=",
    json: "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmV" +
        "yYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCA" +
        "wKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcv" +
        "MTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM4NCAzODQiIHN0e" +
        "WxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4NCAzODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaW" +
        "dodD0iNTEycHgiPgo8cG9seWdvbiBzdHlsZT0iZmlsbDojRUZFRUVFOyIgcG9pbnRzPSI2NCwwIDY0LDM4NCAyODgsMzg0IDM4NCwyODgg" +
        "Mzg0LDAgIi8+Cjxwb2x5Z29uIHN0eWxlPSJmaWxsOiNBQkFCQUI7IiBwb2ludHM9IjI4OCwyODggMjg4LDM4NCAzODQsMjg4ICIvPgo8c" +
        "G9seWdvbiBzdHlsZT0iZmlsbDojREVERUREOyIgcG9pbnRzPSIxOTIsMzg0IDI4OCwzODQgMjg4LDI4OCAiLz4KPHBhdGggc3R5bGU9I" +
        "mZpbGw6I0NCNTY0MTsiIGQ9Ik0wLDk2djExMmgyNTZWOTZMMCw5NkwwLDk2eiIvPgo8Zz4KCTxwYXRoIHN0eWxlPSJmaWxsOiNGRkZGRk" +
        "Y7IiBkPSJNNTQuODgsMTY3LjJjMCw3LjI5Ni0xLjY4LDEyLjQ5Ni01LjA4OCwxNS42MTZjLTMuMzc2LDMuMDg4LTguNTYsNC42NTYtMT" +
        "UuNTM2LDQuNjU2ICAgYy0zLjY2NCwwLTYuNjU2LTAuNDk2LTguOTkyLTEuNTA0Yy0yLjMzNi0wLjk5Mi00LjIwOC0yLjM4NC01LjU4NC" +
        "00LjIyNHMtMi4zMi0zLjk1Mi0yLjgxNi02LjM4NCAgIGMtMC41MjgtMi40MTYtMC43ODQtNC45OTItMC43ODQtNy42NjR2LTIuOTkyaD" +
        "EyLjY3MnYyLjEyOGMwLDMuNjQ4LDAuMzg0LDYuMzM2LDEuMiw4LjExMmMwLjgsMS43NDQsMi40MzIsMi42NCw0Ljk0NCwyLjY0ICAgcz" +
        "QuMTYtMC44OTYsNC45Ni0yLjY0YzAuOC0xLjc2LDEuMi00LjQ2NCwxLjItOC4xMTJ2LTQ4Ljk3Nkg1NC44OEM1NC44OCwxMTcuODU2LD" +
        "U0Ljg4LDE2Ny4yLDU0Ljg4LDE2Ny4yeiIvPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik04MC44LDE2Ny4wMDhjMCwxLj" +
        "YsMC4xNDQsMy4wNTYsMC4zODQsNC4zNTJjMC4yNTYsMS4zMTIsMC43MzYsMi40MTYsMS40NCwzLjMxMiAgIGMwLjcwNCwwLjkxMiwxLj" +
        "Y2NCwxLjYxNiwyLjg0OCwyLjEyOGMxLjE2OCwwLjQ5NiwyLjY1NiwwLjc2OCw0LjQ0OCwwLjc2OGMyLjExMiwwLDQuMDE2LTAuNjg4LD" +
        "UuNjk2LTIuMDY0ICAgYzEuNjk2LTEuMzc2LDIuNTYtMy41MiwyLjU2LTYuMzg0YzAtMS41MzYtMC4yMjQtMi44NjQtMC42MjQtMy45OD" +
        "RjLTAuNDE2LTEuMTItMS4xMDQtMi4xMjgtMi4wNjQtMy4wMDggICBjLTAuOTc2LTAuOTEyLTIuMjQtMS43MTItMy44MDgtMi40NDhzLT" +
        "MuNTA0LTEuNDg4LTUuNzkyLTIuMjU2Yy0zLjA3Mi0xLjAyNC01LjcyOC0yLjE2LTcuOTY4LTMuMzc2ICAgYy0yLjI0LTEuMi00LjExMi" +
        "0yLjYyNC01LjYxNi00LjI3MmMtMS41MDQtMS42MzItMi42MjQtMy41Mi0zLjMxMi01LjY2NGMtMC43Mi0yLjE2LTEuMDU2LTQuNjI0LTE" +
        "uMDU2LTcuNDU2ICAgYzAtNi43ODQsMS44ODgtMTEuODI0LDUuNjY0LTE1LjE1MnM4Ljk3Ni00Ljk5MiwxNS41NjgtNC45OTJjMy4wNTY" +
        "sMCw1LjkwNCwwLjMzNiw4LjQ5NiwxLjAwOHM0LjgzMiwxLjc0NCw2LjcyLDMuMjY0ICAgYzEuODcyLDEuNTA0LDMuMzYsMy40MjQsNC4" +
        "0LDUuNzQ0YzEuMDU2LDIuMzM2LDEuNiw1LjEzNiwxLjYsOC40djEuOTJIOTcuMTJjMC0zLjI2NC0wLjU3Ni01Ljc3Ni0xLjcxMi03LjU" +
        "1MiAgIGMtMS4xNTItMS43NDQtMy4wODgtMi42NC01Ljc3Ni0yLjY0Yy0xLjUzNiwwLTIuODE2LDAuMjQtMy44MjQsMC42NzJjLTEuMDA" +
        "4LDAuNDQ4LTEuODQsMS4wNC0yLjQ0OCwxLjc3NnMtMS4wNCwxLjYxNi0xLjI2NCwyLjU3NiAgIGMtMC4yMjQsMC45Ni0wLjMzNiwxLjk" +
        "1Mi0wLjMzNiwyLjk3NmMwLDIuMTI4LDAuNDQ4LDMuODg4LDEuMzQ0LDUuMzI4YzAuODk2LDEuNDU2LDIuODE2LDIuNzg0LDUuNzYsMy4" +
        "5ODRsMTAuNjU2LDQuNjA4ICAgYzIuNjI0LDEuMTUyLDQuNzUyLDIuMzUyLDYuNDE2LDMuNjE2czIuOTkyLDIuNTkyLDMuOTg0LDQuMDM" +
        "yczEuNzEyLDMuMDA4LDIuMDgsNC43NTJjMC4zODQsMS43MTIsMC41NzYsMy42NDgsMC41NzYsNS43NDQgICBjMCw3LjIzMi0yLjA5Niw" +
        "xMi40OTYtNi4zMDQsMTUuNzkyYy00LjE5MiwzLjI5Ni0xMC4wMzIsNC45Ni0xNy41MDQsNC45NmMtNy44MDgsMC0xMy40MDgtMS42OTY" +
        "tMTYuNzY4LTUuMDg4ICAgcy01LjA0LTguMjU2LTUuMDQtMTQuNTkydi0yLjc4NEg4MC44TDgwLjgsMTY3LjAwOEw4MC44LDE2Ny4wMDh" +
        "6Ii8+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTEyNC41MjgsMTM3Ljc3NmMwLjQ2NC00LjM2OCwxLjUzNi04LjE0NCw" +
        "zLjE1Mi0xMS4yOGMxLjY0OC0zLjEyLDQtNS41ODQsNy4wNTYtNy4zNDQgICBjMy4wNTYtMS43NDQsNy4yLTIuNjQsMTIuMzY4LTIuNjR" +
        "jNS4xODQsMCw5LjMxMiwwLjg5NiwxMi4zODQsMi42NGMzLjA3MiwxLjc2LDUuNDI0LDQuMjI0LDcuMDQsNy4zNDQgICBjMS42NDgsMy4" +
        "xMzYsMi42ODgsNi45MTIsMy4xODQsMTEuMjhjMC40NjQsNC4zODQsMC43Miw5LjE2OCwwLjcyLDE0LjM1MmMwLDUuMjMyLTAuMjU2LDE" +
        "wLjA0OC0wLjcyLDE0LjM4NCAgIGMtMC40OTYsNC4zNTItMS41NTIsOC4wOTYtMy4xODQsMTEuMjMyYy0xLjYzMiwzLjEzNi0zLjk2OCw" +
        "1LjUzNi03LjA0LDcuMmMtMy4wNzIsMS42NjQtNy4yLDIuNDk2LTEyLjM4NCwyLjQ5NiAgIGMtNS4xNjgsMC05LjMxMi0wLjg0OC0xMi4" +
        "zNjgtMi40OTZjLTMuMDU2LTEuNjY0LTUuNDI0LTQuMDY0LTcuMDU2LTcuMmMtMS42MzItMy4xMzYtMi42ODgtNi44OC0zLjE1Mi0xMS4" +
        "yMzIgICBjLTAuNDgtNC4zMzYtMC43Mi05LjE1Mi0wLjcyLTE0LjM4NEMxMjMuODA4LDE0Ni45NiwxMjQuMDQ4LDE0Mi4xNzYsMTI0LjU" +
        "yOCwxMzcuNzc2eiBNMTM3Ljk2OCwxNjMuODg4ICAgYzAuMjI0LDMuMjMyLDAuNjcyLDUuODU2LDEuMzQ0LDcuODcyczEuNjMyLDMuNDg" +
        "4LDIuODgsNC40MTZjMS4yNDgsMC45MjgsMi45MTIsMS4zOTIsNC45NDQsMS4zOTJjMi4wNjQsMCwzLjY5Ni0wLjQ2NCw0Ljk2LTEuMzk" +
        "yICAgYzEuMjQ4LTAuOTQ0LDIuMjA4LTIuNCwyLjg5Ni00LjQxNmMwLjY1Ni0yLjAxNiwxLjEwNC00LjY0LDEuMzQ0LTcuODcyYzAuMjI" +
        "0LTMuMjMyLDAuMzM2LTcuMTM2LDAuMzM2LTExLjc0NCAgIHMtMC4xMTItOC41MTItMC4zMzYtMTEuNzEyYy0wLjI0LTMuMi0wLjY4OC0" +
        "1LjgyNC0xLjM0NC03Ljg3MmMtMC42ODgtMi4wNDgtMS42NDgtMy41MzYtMi44OTYtNC40NDggICBjLTEuMjQ4LTAuOTQ0LTIuODk2LTE" +
        "uNDA4LTQuOTYtMS40MDhjLTIuMDMyLDAtMy42OCwwLjQ2NC00Ljk0NCwxLjQwOGMtMS4yNDgsMC45MjgtMi4yMDgsMi40LTIuODgsNC4" +
        "0NDggICBjLTAuNjcyLDIuMDQ4LTEuMTIsNC42NzItMS4zNDQsNy44NzJzLTAuMzM2LDcuMTA0LTAuMzM2LDExLjcxMlMxMzcuNzQ0LDE" +
        "2MC42NTYsMTM3Ljk2OCwxNjMuODg4eiIvPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0yMTcuMDI0LDE2NC44MTZoMC4" +
        "xOTJ2LTQ2Ljk2aDEyLjY3MlYxODYuNEgyMTQuMTZsLTE2LjkxMi00OGgtMC4xOTJ2NDhoLTEyLjY4OHYtNjguNTQ0aDE1LjkzNiAgIEw" +
        "yMTcuMDI0LDE2NC44MTZ6Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4" +
        "KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+Cjw" +
        "vZz4KPC9zdmc+Cg==",
    pdf: "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVy" +
        "YXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCA" +
        "wKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmc" +
        "vMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM4NCAzODQiIHN" +
        "0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4NCAzODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGh" +
        "laWdodD0iNTEycHgiPgo8cG9seWdvbiBzdHlsZT0iZmlsbDojRUZFRUVFOyIgcG9pbnRzPSI2NCwwIDY0LDM4NCAyODgsMzg0IDM4NCw" +
        "yODggMzg0LDAgIi8+Cjxwb2x5Z29uIHN0eWxlPSJmaWxsOiNBQkFCQUI7IiBwb2ludHM9IjI4OCwyODggMjg4LDM4NCAzODQsMjg4ICI" +
        "vPgo8cG9seWdvbiBzdHlsZT0iZmlsbDojREVERUREOyIgcG9pbnRzPSIxOTIsMzg0IDI4OCwzODQgMjg4LDI4OCAiLz4KPHBhdGggc3R" +
        "5bGU9ImZpbGw6I0NCNTY0MTsiIGQ9Ik0wLDk2djExMmgyNTZWOTZIMHoiLz4KPGc+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyI" +
        "gZD0iTTYwLjk5MiwxMTcuODU2YzMuNTIsMCw2LjQ2NCwwLjU5Miw4Ljg4LDEuNzc2czQuMzM2LDIuNzM2LDUuODA4LDQuNjU2czIuNTI" +
        "4LDQuMTEyLDMuMTY4LDYuNTc2ICAgYzAuNjI0LDIuNDQ4LDAuOTYsNC45NzYsMC45Niw3LjUzNmMwLDMuNTItMC41MjgsNi41OTItMS4" +
        "1ODQsOS4yMTZjLTEuMDU2LDIuNjI0LTIuNTI4LDQuNzY4LTQuNDE2LDYuNDE2ICAgYy0xLjg4OCwxLjY2NC00LjE3NiwyLjkyOC02Ljg" +
        "2NCwzLjc0NGMtMi42ODgsMC44MTYtNS42NjQsMS4yNjQtOC45MjgsMS4yNjRoLTcuMzkydjI3LjM2SDM2Ljh2LTY4LjU0NEw2MC45OTI" +
        "sMTE3Ljg1NnogICAgTTU2Ljc2OCwxNDguODk2YzIuNzUyLDAsNC45OTItMC44NDgsNi42NzItMi41MTJjMS42OTYtMS42NjQsMi41NDQ" +
        "tNC4yNTYsMi41NDQtNy43NzZjMC0zLjQ1Ni0wLjczNi02LjA4LTIuMjA4LTcuODcyICAgYy0xLjQ3Mi0xLjc3Ni0zLjkzNi0yLjY4OC0" +
        "3LjM5Mi0yLjY4OGgtNS43NnYyMC44NDhMNTYuNzY4LDE0OC44OTZMNTYuNzY4LDE0OC44OTZ6Ii8+Cgk8cGF0aCBzdHlsZT0iZmlsbDo" +
        "jRkZGRkZGOyIgZD0iTTExOS41NTIsMTE3Ljg0YzQuODE2LDAsOC42NzIsMC44LDExLjYzMiwyLjM2OGMyLjk0NCwxLjU2OCw1LjIzMiw" +
        "zLjc5Miw2Ljg2NCw2LjcyICAgYzEuNjQ4LDIuOTEyLDIuNzM2LDYuNCwzLjMxMiwxMC41MTJjMC41NzYsNC4wOTYsMC44NjQsOC42NzI" +
        "sMC44NjQsMTMuNzEyYzAsNi4wMTYtMC4zNTIsMTEuMjQ4LTEuMDg4LDE1LjY5NiAgIGMtMC43MzYsNC40MzItMiw4LjExMi0zLjc5Miw" +
        "xMC45OTJjLTEuNzkyLDIuODk2LTQuMTkyLDUuMDI0LTcuMiw2LjQzMnMtNi44MTYsMi4xMTItMTEuNDI0LDIuMTEySDk2Ljg5NlYxMTc" +
        "uODRMMTE5LjU1MiwxMTcuODQgICBMMTE5LjU1MiwxMTcuODR6IE0xMTYuODY0LDE3Ni4yNGMyLjQzMiwwLDQuMzg0LTAuNDE2LDUuODU" +
        "2LTEuMjQ4czIuNjQtMi4yMDgsMy41MDQtNC4xMjhjMC44OC0xLjkyLDEuNDU2LTQuNDQ4LDEuNzI4LTcuNiAgIGMwLjI4OC0zLjEyLDA" +
        "uNDMyLTcuMDI0LDAuNDMyLTExLjY5NmMwLTMuOTA0LTAuMTQ0LTcuMzI4LTAuMzg0LTEwLjI4OGMtMC4yNTYtMi45NDQtMC43ODQtNS4" +
        "zOTItMS41ODQtNy4zNDQgICBjLTAuODE2LTEuOTUyLTEuOTg0LTMuNDI0LTMuNTY4LTQuNGMtMS41NjgtMC45OTItMy42NjQtMS41MDQ" +
        "tNi4yODgtMS41MDRoLTUuODR2NDguMjA4TDExNi44NjQsMTc2LjI0eiIvPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0" +
        "xOTkuMjMyLDExNy44NTZ2MTEuMzI4aC0yNC40OHYxNi4xMjhoMjMuMDR2MTEuMzI4aC0yMy4wNHYyOS43NmgtMTMuODI0di02OC41NDR" +
        "MMTk5LjIzMiwxMTcuODU2eiAgICIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo" +
        "8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjx" +
        "nPgo8L2c+Cjwvc3ZnPgo=",
    csv: "data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyY" +
        "XRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKS" +
        "AgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk" +
        "5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM4NCAzODQiIHN0eWxl" +
        "PSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM4NCAzODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdod" +
        "D0iNTEycHgiPgo8cG9seWdvbiBzdHlsZT0iZmlsbDojRUZFRUVFOyIgcG9pbnRzPSI2NCwwIDY0LDM4NCAyODgsMzg0IDM4NCwyODggMz" +
        "g0LDAgIi8+Cjxwb2x5Z29uIHN0eWxlPSJmaWxsOiNBQkFCQUI7IiBwb2ludHM9IjI4OCwyODggMjg4LDM4NCAzODQsMjg4ICIvPgo8cG9" +
        "seWdvbiBzdHlsZT0iZmlsbDojREVERUREOyIgcG9pbnRzPSIxOTIsMzg0IDI4OCwzODQgMjg4LDI4OCAiLz4KPHBhdGggc3R5bGU9ImZp" +
        "bGw6IzQ0OEU0NzsiIGQ9Ik0wLDk2djExMmgyNTZWOTZMMCw5NkwwLDk2eiIvPgo8Zz4KCTxwYXRoIHN0eWxlPSJmaWxsOiNGRkZGRkY7I" +
        "iBkPSJNNjQuMzIsMTMwLjExMmMtMS4xODQtMi4yODgtMy4zNDQtMy40MjQtNi40OC0zLjQyNGMtMS43MjgsMC0zLjE1MiwwLjQ2NC00Lj" +
        "I3MiwxLjQwOCAgIGMtMS4xMiwwLjkyOC0yLDIuNDE2LTIuNjQsNC40OTZzLTEuMDg4LDQuOC0xLjM0NCw4LjE3NmMtMC4yNzIsMy4zNi0" +
        "wLjM4NCw3LjQ3Mi0wLjM4NCwxMi4zMzZjMCw1LjE4NCwwLjE3Niw5LjM3NiwwLjUyOCwxMi41NzYgICBjMC4zMzYsMy4yLDAuODk2LDUu" +
        "NjY0LDEuNjMyLDcuNDRzMS42NjQsMi45NiwyLjc4NCwzLjU1MmMxLjEyLDAuNjA4LDIuNDE2LDAuOTI4LDMuODg4LDAuOTI4YzEuMjE2L" +
        "DAsMi4zNTItMC4yMDgsMy40MDgtMC42MjQgICBzMS45NjgtMS4yNDgsMi43MzYtMi40OTZjMC43ODQtMS4yNDgsMS4zOTItMy4wMDgsMS" +
        "44MjQtNS4yOGMwLjQ0OC0yLjI3MiwwLjY3Mi01LjI2NCwwLjY3Mi04Ljk3Nkg4MC40OCAgIGMwLDMuNjk2LTAuMjg4LDcuMjMyLTAuODY" +
        "0LDEwLjU2cy0xLjY2NCw2LjI0LTMuMjE2LDguNzM2Yy0xLjU4NCwyLjQ4LTMuNzc2LDQuNDMyLTYuNjI0LDUuODQgICBjLTIuODQ4LDEu" +
        "NDA4LTYuNTQ0LDIuMTI4LTExLjA4OCwyLjEyOGMtNS4xNjgsMC05LjMxMi0wLjg0OC0xMi4zNjgtMi40OTZjLTMuMDcyLTEuNjY0LTUuN" +
        "DI0LTQuMDY0LTcuMDU2LTcuMiAgIHMtMi42ODgtNi44OC0zLjE2OC0xMS4yMzJjLTAuNDY0LTQuMzM2LTAuNzItOS4xNTItMC43Mi0xNC" +
        "4zODRjMC01LjE4NCwwLjI1Ni05Ljk2OCwwLjcyLTE0LjM1MiAgIGMwLjQ4LTQuMzY4LDEuNTUyLTguMTQ0LDMuMTY4LTExLjI4YzEuNjQ" +
        "4LTMuMTIsMy45ODQtNS41ODQsNy4wNTYtNy4zNDRjMy4wNTYtMS43NDQsNy4yLTIuNjQsMTIuMzY4LTIuNjQgICBjNC45NDQsMCw4Ljgx" +
        "NiwwLjgsMTEuNjY0LDIuNGMyLjg0OCwxLjYsNC45NzYsMy42MzIsNi4zNjgsNi4wOTZzMi4zMDQsNS4xMiwyLjY0LDcuOTY4YzAuMzUyL" +
        "DIuODQ4LDAuNTI4LDUuNTIsMC41MjgsOC4wMTZINjYuMDggICBDNjYuMDgsMTM2LDY1LjQ4OCwxMzIuMzY4LDY0LjMyLDEzMC4xMTJ6Ii" +
        "8+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTEwOS4wNzIsMTY3LjAwOGMwLDEuNiwwLjE0NCwzLjA1NiwwLjM4NCw0LjM" +
        "1MmMwLjI3MiwxLjMxMiwwLjczNiwyLjQxNiwxLjQ0LDMuMzEyICAgYzAuNzA0LDAuOTEyLDEuNjY0LDEuNjE2LDIuODQ4LDIuMTI4YzEu" +
        "MTY4LDAuNDk2LDIuNjcyLDAuNzY4LDQuNDQ4LDAuNzY4YzIuMTI4LDAsNC4wMTYtMC42ODgsNS43MTItMi4wNjQgICBjMS42OC0xLjM3N" +
        "iwyLjU0NC0zLjUyLDIuNTQ0LTYuMzg0YzAtMS41MzYtMC4yMjQtMi44NjQtMC42MjQtMy45ODRjLTAuNDE2LTEuMTItMS4xMDQtMi4xMj" +
        "gtMi4wNjQtMy4wMDggICBjLTAuOTc2LTAuOTEyLTIuMjQtMS43MTItMy43OTItMi40NDhzLTMuNTA0LTEuNDg4LTUuODA4LTIuMjU2Yy0" +
        "zLjA1Ni0xLjAyNC01LjcxMi0yLjE2LTcuOTY4LTMuMzc2ICAgYy0yLjI0LTEuMi00LjExMi0yLjYyNC01LjYxNi00LjI3MmMtMS41MDQt" +
        "MS42MzItMi42MDgtMy41Mi0zLjMxMi01LjY2NGMtMC43MDQtMi4xNi0xLjA1Ni00LjYyNC0xLjA1Ni03LjQ1NiAgIGMwLTYuNzg0LDEuO" +
        "Dg4LTExLjgyNCw1LjY2NC0xNS4xNTJjMy43Ni0zLjMyOCw4Ljk2LTQuOTkyLDE1LjU1Mi00Ljk5MmMzLjA3MiwwLDUuOTA0LDAuMzM2LD" +
        "guNDk2LDEuMDA4czQuODMyLDEuNzQ0LDYuNzIsMy4yNjQgICBjMS44ODgsMS41MDQsMy4zNiwzLjQyNCw0LjQxNiw1Ljc0NGMxLjA0LDI" +
        "uMzM2LDEuNTg0LDUuMTM2LDEuNTg0LDguNHYxLjkyaC0xMy4yMzJjMC0zLjI2NC0wLjU3Ni01Ljc3Ni0xLjcxMi03LjU1MiAgIGMtMS4x" +
        "NTItMS43NDQtMy4wNzItMi42NC01Ljc2LTIuNjRjLTEuNTM2LDAtMi44MTYsMC4yNC0zLjg0LDAuNjcyYy0xLjAwOCwwLjQ0OC0xLjg0L" +
        "DEuMDQtMi40NDgsMS43NzZzLTEuMDQsMS42MTYtMS4yNjQsMi41NzYgICBjLTAuMjQsMC45Ni0wLjMzNiwxLjk1Mi0wLjMzNiwyLjk3Nm" +
        "MwLDIuMTI4LDAuNDQ4LDMuODg4LDEuMzQ0LDUuMzI4YzAuODk2LDEuNDU2LDIuODE2LDIuNzg0LDUuNzYsMy45ODRsMTAuNjU2LDQuNjA" +
        "4ICAgYzIuNjI0LDEuMTUyLDQuNzY4LDIuMzUyLDYuNDE2LDMuNjE2YzEuNjY0LDEuMjQ4LDMuMDA4LDIuNTkyLDMuOTg0LDQuMDMyYzAu" +
        "OTkyLDEuNDQsMS42OCwzLjAwOCwyLjA2NCw0Ljc1MiAgIGMwLjM4NCwxLjcxMiwwLjU3NiwzLjY0OCwwLjU3Niw1Ljc0NGMwLDcuMjMyL" +
        "TIuMDk2LDEyLjQ5Ni02LjI4OCwxNS43OTJjLTQuMTkyLDMuMjk2LTEwLjAzMiw0Ljk2LTE3LjUyLDQuOTYgICBjLTcuODA4LDAtMTMuMz" +
        "kyLTEuNjk2LTE2Ljc2OC01LjA4OGMtMy4zNi0zLjM5Mi01LjAyNC04LjI1Ni01LjAyNC0xNC41OTJ2LTIuNzg0aDEzLjgyNEwxMDkuMDc" +
        "yLDE2Ny4wMDhMMTA5LjA3MiwxNjcuMDA4eiIvPgoJPHBhdGggc3R5bGU9ImZpbGw6I0ZGRkZGRjsiIGQ9Ik0xNzcuMzQ0LDE2OC41NDRo" +
        "MC4zMDRsMTAuMTc2LTUwLjY4OGgxNC4zMkwxODYuNCwxODYuNGgtMTcuNzZsLTE1LjcyOC02OC41NDRoMTQuNzg0ICAgTDE3Ny4zNDQsM" +
        "TY4LjU0NHoiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPg" +
        "o8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K",
    heif: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZ' +
        'G9iZSBJbGx1c3RyYXRvciAyNC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjx' +
        'zdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJod' +
        'HRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmF' +
        'ibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+D' +
        'QoJLnN0MHtmaWxsOiNFRkVFRUU7fQ0KCS5zdDF7ZmlsbDojQUJBQkFCO30NCgkuc3Qye2ZpbGw6I0RFREVERDt9DQoJLnN0M3tmaWxsOiM' +
        'wMDk2ODg7fQ0KCS5zdDR7ZmlsbDojRkZGRkZGO30NCjwvc3R5bGU+DQo8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9Ijg1LjMsMCA4N' +
        'S4zLDUxMiAzODQsNTEyIDUxMiwzODQgNTEyLDAgIi8+DQo8cG9seWdvbiBjbGFzcz0ic3QxIiBwb2ludHM9IjM4NCwzODQgMzg0LDUxMiA' +
        '1MTIsMzg0ICIvPg0KPHBvbHlnb24gY2xhc3M9InN0MiIgcG9pbnRzPSIyNTYsNTEyIDM4NCw1MTIgMzg0LDM4NCAiLz4NCjxwYXRoIGNsYX' +
        'NzPSJzdDMiIGQ9Ik0wLDEyOHYxNDkuM2gzNDEuM1YxMjhIMHoiLz4NCjxnPg0KCTxwYXRoIGNsYXNzPSJzdDQiIGQ9Ik01Ni45LDI0NS40' +
        'di04NS41aDE0LjV2MzUuOGgxNi4zdi0zNS44aDE0LjZ2ODUuNUg4Ny44di0zOC42SDcxLjV2MzguNkg1Ni45eiIvPg0KCTxwYXRoIGNsYX' +
        'NzPSJzdDQiIGQ9Ik0xMjAuOCwyNDUuNHYtODUuNWgzMy42djExaC0xOXYyNC42aDE0Ljl2MTAuOWgtMTQuOXYyOC4zaDE5LjJ2MTAuOEgx' +
        'MjAuOHoiLz4NCgk8cGF0aCBjbGFzcz0ic3Q0IiBkPSJNMTY5LjYsMjQ1LjR2LTg1LjVIMTg0djg1LjVIMTY5LjZ6Ii8+DQoJPHBhdGggY2x' +
        'hc3M9InN0NCIgZD0iTTIwMi41LDI0NS40di04NS41aDMyLjl2MTAuOGgtMTguM3YyNC45aDE0Ljl2MTAuOWgtMTQuOXYzOC45SDIwMi41e' +
        'iIvPg0KPC9nPg0KPC9zdmc+DQo=',
    heic: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZ' +
        'G9iZSBJbGx1c3RyYXRvciAyNC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCj' +
        'xzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPS' +
        'JodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPS' +
        'JlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2' +
        'NzcyI+DQoJLnN0MHtmaWxsOiNFRkVFRUU7fQ0KCS5zdDF7ZmlsbDojQUJBQkFCO30NCgkuc3Qye2ZpbGw6I0RFREVERDt9DQoJLnN0M3' +
        'tmaWxsOiNGRjk4MDA7fQ0KCS5zdDR7ZmlsbDojRkZGRkZGO30NCjwvc3R5bGU+DQo8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9I' +
        'jg1LjMsMCA4NS4zLDUxMiAzODQsNTEyIDUxMiwzODQgNTEyLDAgIi8+DQo8cG9seWdvbiBjbGFzcz0ic3QxIiBwb2ludHM9IjM4NCwz' +
        'ODQgMzg0LDUxMiA1MTIsMzg0ICIvPg0KPHBvbHlnb24gY2xhc3M9InN0MiIgcG9pbnRzPSIyNTYsNTEyIDM4NCw1MTIgMzg0LDM4NCAi' +
        'Lz4NCjxwYXRoIGNsYXNzPSJzdDMiIGQ9Ik0wLDEyOHYxNDkuM2gzNDEuM1YxMjhIMHoiLz4NCjxpbWFnZSBzdHlsZT0ib3ZlcmZsb3c6' +
        'dmlzaWJsZTsiIHdpZHRoPSIxNjY0IiBoZWlnaHQ9IjEzNiIgeGxpbms6aHJlZj0iNEM5NTE2OEQuanBnIiAgdHJhbnNmb3JtPSJtYXRy' +
        'aXgoMC40NTcyIDAgMCAwLjQ1NzIgLTI4LjMxNDkgNjEzLjIzNTMpIj4NCjwvaW1hZ2U+DQo8Zz4NCgk8cGF0aCBjbGFzcz0ic3Q0IiBk' +
        'PSJNNTYuOSwyNDUuNHYtODUuNWgxNC41djM1LjhoMTYuM3YtMzUuOGgxNC42djg1LjVIODcuOHYtMzguNkg3MS41djM4LjZINTYuOXoi' +
        'Lz4NCgk8cGF0aCBjbGFzcz0ic3Q0IiBkPSJNMTIwLjgsMjQ1LjR2LTg1LjVoMzMuNnYxMWgtMTl2MjQuNmgxNC45djEwLjloLTE0Ljl2' +
        'MjguM2gxOS4ydjEwLjhIMTIwLjh6Ii8+DQoJPHBhdGggY2xhc3M9InN0NCIgZD0iTTE2OS42LDI0NS40di04NS41SDE4NHY4NS41SDE2' +
        'OS42eiIvPg0KCTxwYXRoIGNsYXNzPSJzdDQiIGQ9Ik0yMjQuMSwyNDYuNmMtNS45LDAtMTAuNS0xLjItMTMuOC0zLjdjLTMuMy0yLjUt' +
        'NS43LTUuOC03LTEwLjFjLTEuMy00LjMtMi05LjItMi0xNC43di0zMC41DQoJCWMwLTUuOCwwLjctMTAuOSwyLTE1LjJjMS4zLTQuMywz' +
        'LjYtNy42LDctOS45YzMuMy0yLjMsNy45LTMuNSwxMy44LTMuNWM1LjUsMCw5LjksMSwxMywzYzMuMSwyLDUuNCw0LjksNi43LDguNg0K' +
        'CQljMS4zLDMuNywyLDguMSwyLDEzdjcuMmgtMTR2LTcuNGMwLTIuNC0wLjEtNC42LTAuMy02LjVjLTAuMi0yLTAuOS0zLjUtMS45LTQu' +
        'N2MtMS0xLjItMi44LTEuNy01LjMtMS43Yy0yLjUsMC00LjMsMC42LTUuNSwxLjgNCgkJYy0xLjIsMS4yLTEuOSwyLjktMi4yLDVjLTAu' +
        'MywyLjEtMC41LDQuNC0wLjUsN3YzNy4xYzAsMy4xLDAuMiw1LjYsMC43LDcuNmMwLjUsMiwxLjMsMy41LDIuNSw0LjVjMS4yLDEsMi45' +
        'LDEuNSw1LDEuNQ0KCQljMi40LDAsNC4yLTAuNiw1LjItMS44YzEtMS4yLDEuNy0yLjksMS45LTQuOWMwLjMtMiwwLjQtNC4zLDAuNC02' +
        'Ljl2LTcuN2gxNHY2LjljMCw1LjEtMC42LDkuNS0xLjksMTMuNWMtMS4zLDMuOS0zLjQsNy02LjYsOS4yDQoJCUMyMzQuMSwyNDUuNSwy' +
        'MjkuNywyNDYuNiwyMjQuMSwyNDYuNnoiLz4NCjwvZz4NCjwvc3ZnPg0K'
};
