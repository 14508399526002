import {createSlice} from "@reduxjs/toolkit";

// Redux toolkit cho phép định nghĩa state (VD: "pageIsError"), định nghĩa các reducers/action (VD: setPageIsError)
// ngay trong 1 file JS, cho phép code dễ bảo trì hơn

// định nghĩa giá trị khởi tạo của state
// value=true tức là trang đang render có lỗi
// r/msg: thông tin chi tiết về lỗi
const initialState = {
    msgInput: '',
    selectedFiles: []
};

// định nghĩa state name, reducers, actions
const slice = createSlice({
    name: "messageField",
    initialState,
    reducers: {
        // action public ra để dùng là: setPageIsError(true/false)
        setMsgInput: (state, action) => {
            state.msgInput = action.payload;
        },
        concatMsgInput: (state, action) => {
            state.msgInput += action.payload;
        },
        setSelectedFiles: (state, action) => {
            state.selectedFiles = action.payload;
        },
        pushSelectedFiles: (state, action) => {
            const files = action.payload;

            if (files.length) {
                state.selectedFiles = [...state.selectedFiles, ...files];
            } else {
                state.selectedFiles = [...state.selectedFiles, files];
            }
        },
        removeSelectedFileWithIndex: (state, action) => {
            state.selectedFiles = state.selectedFiles.filter((_, index) => index !== action.payload);
        }
    }
});

// export state, actions, reducer để các components sử dụng
// lưu ý: quy định đặt tên giống như sample này để dễ hiểu, dễ bảo trì code
export const selectMsgInput = (state) => state.messageField.msgInput;
export const selectSelectedFiles = (state) => state.messageField.selectedFiles;
export const {
    setMsgInput,
    concatMsgInput,
    setSelectedFiles,
    pushSelectedFiles,
    removeSelectedFileWithIndex
} = slice.actions;
export default slice.reducer;
