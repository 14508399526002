import UserMessage from "./UserMessage";
import SystemMessage from "./SystemMessage";
import {useDispatch, useSelector} from "react-redux";
import {selectConversationMessageList, setIsMarkReadMsgByScroll} from "../../../store/conversationMessageList";
import {formatDateTime, groupDay} from "../../../common/common";
import {useEffect, useState} from "react";
import ActionMessage from "./ActionMessage";
import {Box} from "@mui/material";
import i18n from "../../../common/i18n";
import {ExpandMore} from "@mui/icons-material";
import {selectUnreadMsgCount} from "../../../store/chatConversationSelected";
import {selectIsCustomer} from "../../../store/chatbox";

function ConversationMessageList({scrollableDivContentConvRef}) {
    const [isEnableMoveToBottom, setIsEnableMoveToBottom] = useState(false);

    const dispatch = useDispatch();
    const conversationMessageList = useSelector(selectConversationMessageList);
    const unreadMsgCount = useSelector(selectUnreadMsgCount);
    const isCustomer = useSelector(selectIsCustomer);
    const [msgGroupDays, setMsgGroupDays] = useState({});
    const oneDaySecond = 24 * 60 * 60 * 1000;
    const today = Math.floor(new Date().getTime() / oneDaySecond);
    const yesterday = Math.floor(new Date().getTime() / oneDaySecond - 1);

    useEffect(() => {
        setMsgGroupDays(groupDay(conversationMessageList));
    }, [conversationMessageList]);


    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = scrollableDivContentConvRef.scrollTop;
            const offsetHeight = scrollableDivContentConvRef.offsetHeight;

            setIsEnableMoveToBottom(offsetHeight && (scrollTop + offsetHeight <= 0));

            if (scrollTop >= -5) {
                dispatch(setIsMarkReadMsgByScroll(true));
            }
        };

        if (scrollableDivContentConvRef) {
            scrollableDivContentConvRef.addEventListener('scroll', handleScroll);
            handleScroll();

            return () => {
                scrollableDivContentConvRef.removeEventListener('scroll', handleScroll);
            };
        }
    }, [scrollableDivContentConvRef, dispatch]);

    const moveToBottomListMsg = () => {
        scrollableDivContentConvRef.scrollTop = scrollableDivContentConvRef.offsetHeight * -1;

        scrollableDivContentConvRef.scroll({
            top: 0,
            behavior: "smooth"
        });
    };

    const handleWheelBtnMoveBottom = (event) => {
        const delta = event.deltaY || event.detail || event.wheelDelta;

        if (delta > 0) {
            // Scroll down
            scrollableDivContentConvRef.scrollTop += 50;
        } else {
            // Scroll up
            scrollableDivContentConvRef.scrollTop -= 50;
        }
    };

    return (
        <Box>
            {Object.entries(msgGroupDays).map(([day, msgList]) => {
                let dayText;
                if (parseInt(day) === today) {
                    dayText = i18n.t('message.today');
                } else if (parseInt(day) === yesterday) {
                    dayText = i18n.t('message.yesterday');
                } else {
                    dayText = formatDateTime(day * oneDaySecond, "DD-MM-YYYY");
                }

                return (
                    <div
                        key={day}
                        className="conversation-message-days"
                        style={{display: 'flex', flexDirection: 'column-reverse'}}
                    >
                        {
                            msgList.map((msg) => {
                                const actionMessage = msg.action;
                                if (actionMessage) {
                                    return <div className="conversation-message-item" key={msg.id}>
                                        <div className="conversation-message-content">
                                            <ActionMessage messageData={msg}/>
                                        </div>
                                    </div>;
                                }

                                // 1: text
                                // 2: image
                                // 3: video
                                // 4: audio
                                // 5: file
                                // 11: sticker
                                const isUserMessage = [1, 2, 3, 4, 5, 11].includes(msg.type);

                                return <div className="conversation-message-item" key={msg.id}>
                                    <div className="conversation-message-content">
                                        {
                                            isUserMessage ?
                                                <UserMessage messageData={msg}/> : <SystemMessage messageData={msg}/>
                                        }

                                    </div>
                                </div>;
                            })
                        }
                        <div className="conversation-message-item">
                            <div className="dotted-line">
                                <div className="content">{dayText}</div>
                            </div>
                        </div>
                    </div>
                );
            })}

            {scrollableDivContentConvRef &&
                <button
                    onClick={moveToBottomListMsg}
                    onWheel={handleWheelBtnMoveBottom}
                    className={`btn-move-bottom ${(
                        isEnableMoveToBottom || (unreadMsgCount && scrollableDivContentConvRef.scrollTop <= -5)
                    ) ? 'visible' : ''} ${isCustomer ? 'is-customer' : ''}`}
                    style={{top: (scrollableDivContentConvRef.offsetHeight + (isCustomer ? 50 : -70)) + "px"}}
                >
                    {unreadMsgCount > 0 && <span className="unread-msg-count">{unreadMsgCount}</span>}
                    <ExpandMore sx={{fontSize: isCustomer ? "2rem" : "2.5rem"}}/>
                </button>
            }
        </Box>
    );
}

export default ConversationMessageList;
