import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useRef, useState} from "react";
import {
    selectConversationMessageList,
    selectScrollToBottomMessageList,
    setConversationMessageList, setScrollToBottomMessageList, unshiftItemToConversationMessageList
} from "../../store/conversationMessageList";
import MessageField from "./message_field_area/MessageField";
import {
    selectChatConversationSelected,
    selectChatConversationSelectedInfo, selectIsOnlyReloadConvInfo,
    selectIsReloadConvContent, selectIsUpdateLastMsgInConvList,
    selectNextConversation,
    selectPrevConversation,
    setConversationSelectedInfo,
    setIsOnlyReloadConvInfo,
    setIsReloadConvContent, setIsUpdateLastMsgInConvList,
    setNextConversation,
    setPrevConversation, setUnreadMsgCount
} from "../../store/chatConversationSelected";
import {selectStringeeClient} from "../../store/chatStringeeClient";
import InfiniteScroll from "react-infinite-scroll-component";
import {CircularProgress} from "@mui/material";
import {selectAuthUser} from "../../store/chatAuthUser";
import {chatStatusDefine} from "../../common/constants";
import ConversationNavigation from "./messages/ConversationNavigation";
import {getChatStorage, removeChatStorage, stringify} from "../../common/common";
import ConversationMessageList from "./messages/ConversationMessageList";
import TypingIndicator from "./messages/TypingIndicator";
import {selectIsCustomer, selectIsPopup} from "../../store/chatbox";
import i18n from "i18next";
import {setIsLoading} from "../../store/chatGeneral";
import {selectSelectedFiles, setMsgInput, setSelectedFiles} from "../../store/messageField";
import {updateChatConvListWhenEndChat} from "../../store/chatConversationList";
// import ChatHeader from "./chat_panel/ChatHeader";

const ContentConversation = (props) => {
    const conversationMessageList = useSelector(selectConversationMessageList);
    const chatConversationSelected = useSelector(selectChatConversationSelected);
    const chatConversationSelectedInfo = useSelector(selectChatConversationSelectedInfo);
    const nextConversation = useSelector(selectNextConversation);
    const prevConversation = useSelector(selectPrevConversation);
    const client = useSelector(selectStringeeClient);
    const dispatch = useDispatch();
    const authUser = useSelector(selectAuthUser);
    const isReloadConvContent = useSelector(selectIsReloadConvContent);
    const isOnlyReloadConvInfo = useSelector(selectIsOnlyReloadConvInfo);
    const isUpdateLastMsgInConvList = useSelector(selectIsUpdateLastMsgInConvList);
    const isCustomer = useSelector(selectIsCustomer);
    const selectedFiles = useSelector(selectSelectedFiles);
    const isPopup = useSelector(selectIsPopup);
    const scrollToBottomMessageList = useSelector(selectScrollToBottomMessageList);

    const scrollableDivContentConvRef = useRef(null);
    const chatConversationSelectedIdRef = useRef(null);
    const participantListMap = useRef({});
    const [hasMore, setHasMore] = useState(true);
    const userDisplayName = authUser && authUser.displayName;
    const LIST_MESSAGE_LIMIT = 25;

    const StringeeChat2 = window.StringeeChat2;

    const getPrevNextConv = useCallback(async () => {
        const conv = chatConversationSelected;

        await StringeeChat2.getLiveChatConversationsForCustomer(client, {
            customerId: conv.creator,
            createdSmaller: conv.created,
            limit: 1
        }).then(function(convs) {
            console.log('convsation prev res: ', convs);

            const conv = (convs && convs.length) ? convs[0] : null;
            dispatch(setPrevConversation(conv));
        }).catch(({error}) => {
            console.log("++++ err convsation prev", error);
            dispatch(setPrevConversation(null));
        });

        await StringeeChat2.getLiveChatConversationsForCustomer(client, {
            customerId: conv.creator,
            createdGreater: conv.created,
            limit: 1,
            order: "ASC"
        }).then(function(convs) {
            console.log('convsation next res: ', convs);

            const conv = (convs && convs.length) ? convs[0] : null;
            dispatch(setNextConversation(conv));
        }).catch(({error}) => {
            console.log("++++ err convsation next", error);
            dispatch(setNextConversation(null));
        });
    }, [chatConversationSelected, dispatch, client, StringeeChat2]);

    const getConversationsInfo = useCallback(async () => {
        if (isCustomer || client.isInternal) {
            await StringeeChat2.getConversationsByIds(client, [chatConversationSelected.id])
                .then(function(listConvs) {
                    console.log('chatbox convsationInfo: ', listConvs);

                    const convInfo = listConvs[0];
                    dispatch(setConversationSelectedInfo(convInfo));
                });
        } else {
            await StringeeChat2.getLiveChatConversationsInfo(client, [chatConversationSelected.id])
                .then(function(res) {
                    console.log('convsationInfo: ', res);

                    if (res.r === 0 && res.listConvs && res.listConvs.length) {
                        const convInfo = res.listConvs[0];

                        dispatch(setConversationSelectedInfo(convInfo));

                        if (isUpdateLastMsgInConvList) {
                            dispatch(setIsUpdateLastMsgInConvList(false));
                            dispatch(updateChatConvListWhenEndChat(convInfo));
                        }

                        // Send `message` to the parent using the postMessage method on the window.parent reference.
                        const postMessageData = {
                            action: 'stringeeChatSelectedConversation',
                            data: JSON.parse(stringify(convInfo))
                        };
                        window.parent.postMessage(postMessageData, "*");
                    }
                });
        }
    }, [chatConversationSelected, isCustomer, dispatch, client, StringeeChat2, isUpdateLastMsgInConvList]);

    const fetchSenderInfo = useCallback((messages, isLoadMore = false) => {
        if (messages && messages.length) {
            const userIdsNotFound = [];
            messages.map((msg) => {
                if (typeof participantListMap.current[msg.sender] !== "undefined") {
                    const p = participantListMap.current[msg.sender];
                    msg.user_name = p.name;
                    msg.avatar_url = p.avatar;
                } else {
                    if (!userIdsNotFound.includes(msg.sender)) {
                        userIdsNotFound.push(msg.sender);
                    }
                }

                return msg;
            });

            if (userIdsNotFound.length) {
                StringeeChat2.getUsersInfo(client, userIdsNotFound, function(status, code, message, users) {
                    if (users && users.length) {
                        users.forEach((user) => {
                            participantListMap.current[user.userId] = user;
                        });

                        messages.map((msg) => {
                            if (typeof msg.user_name === 'undefined') {
                                if (typeof participantListMap.current[msg.sender] !== "undefined") {
                                    const p = participantListMap.current[msg.sender];
                                    msg.user_name = p.name;
                                    msg.avatar_url = p.avatar;
                                }
                            }

                            return msg;
                        });

                        if (isLoadMore) {
                            dispatch(setConversationMessageList(conversationMessageList.concat(messages)));
                        } else {
                            dispatch(setConversationMessageList([...messages]));
                        }
                    }
                });
            } else {
                if (isLoadMore) {
                    dispatch(setConversationMessageList(conversationMessageList.concat(messages)));
                } else {
                    dispatch(setConversationMessageList([...messages]));
                }
            }
        } else {
            if (!isLoadMore) {
                dispatch(setConversationMessageList([]));
            }
        }
    }, [dispatch, StringeeChat2, client, conversationMessageList]);

    const showMessages = useCallback(async () => {
        const conv = chatConversationSelected;
        dispatch(setConversationMessageList([]));

        const postBody = {
            seqGreater: 0,
            seqSmaller: Number.MAX_SAFE_INTEGER,
            limit: LIST_MESSAGE_LIMIT,
            order: 'DESC'
        };

        if (isCustomer) {
            postBody.customerId = authUser.userId || authUser.id;
            postBody.sort = "DESC";

            await conv.getLiveChatCustomerMessages(postBody)
                .then(function(res) {
                    if (res.r !== 0) return;
                    const messages = res.messages;
                    console.log('messages list: ', messages);

                    if (messages && messages.length) {
                        fetchSenderInfo(messages);

                        // danh dau da doc (seen): truyen len msg co seq lon nhat
                        console.log('messages last: ', messages[0]);
                        const lastMsg = messages[0];

                        const showWaitMsg = getChatStorage('CusWaitMsg');
                        if (showWaitMsg) {
                            conv.markReceived(lastMsg);

                            // push message: tin nhắn đang chờ
                            const created = Date.now();
                            dispatch(unshiftItemToConversationMessageList({
                                action: "SHOW_WAIT_MESSAGE",
                                id: "msg-wait-message" + created,
                                created
                            }));
                            removeChatStorage('CusWaitMsg');
                        } else {
                            conv.markRead(lastMsg);
                        }
                    }
                });
        } else if (client.isInternal) {
            const seqGreater = 0;
            const seqSmaller = Number.MAX_SAFE_INTEGER;
            const limit = LIST_MESSAGE_LIMIT;
            const sort = 'DESC';

            await conv.getMessages(seqGreater, seqSmaller, limit, sort)
                .then(function(messages) {
                    console.log('messages list:', messages);

                    if (messages && messages.length) {
                        dispatch(setConversationMessageList(messages));

                        // danh dau da doc (seen): truyen len msg co seq lon nhat
                        console.log('messages last: ', messages[0]);
                        const lastMsg = messages[0];
                        conv.markRead(lastMsg);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                });
        } else {
            await conv.getLiveChatMessages(postBody)
                .then(function(messages) {
                    console.log('messages list: ', messages);

                    if (messages && messages.length) {
                        fetchSenderInfo(messages);
                        // dispatch(setConversationMessageList(messages));

                        // danh dau da doc (seen): truyen len msg co seq lon nhat
                        console.log('messages last: ', messages[0]);
                        const lastMsg = messages[0];
                        conv.markRead(lastMsg);
                    }
                })
                .catch(function(err) {
                    console.log(err);
                });
        }
    }, [dispatch, chatConversationSelected, authUser, isCustomer, fetchSenderInfo, client]);

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setIsReloadConvContent(false));
            dispatch(setIsLoading(true));
            await getConversationsInfo();
            await showMessages();

            dispatch(setIsLoading(false));
        };

        if (isReloadConvContent) {
            console.log('isReloadConvContent+++++', isReloadConvContent);

            fetchData();
        }
    }, [isReloadConvContent, getConversationsInfo, showMessages, dispatch]);

    useEffect(() => {
        if (isOnlyReloadConvInfo) {
            console.log('isOnlyReloadConvInfo+++++', isOnlyReloadConvInfo);

            dispatch(setIsOnlyReloadConvInfo(false));
            getConversationsInfo();
        }
    }, [isOnlyReloadConvInfo, getConversationsInfo, dispatch]);

    useEffect(() => {
        // khi sửa tên trong "Chỉnh sửa thông tin cá nhân" ở chatbox
        // -> update participantListMap để hiện system message theo tên mới

        if (authUser && authUser.displayName) {
            if (typeof participantListMap.current[authUser.userId] !== "undefined") {
                participantListMap.current[authUser.userId].name = authUser.displayName;
            } else {
                participantListMap.current[authUser.userId] = {
                    id: authUser.userId,
                    name: authUser.displayName,
                    avatar: ''
                };
            }
        }
    }, [authUser, userDisplayName]);

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setIsLoading(true));

            dispatch(setMsgInput(''));
            dispatch(setSelectedFiles([]));
            dispatch(setUnreadMsgCount(0));

            await getConversationsInfo();
            await showMessages();
            if (!isCustomer && !client.isInternal) {
                await getPrevNextConv();
            }
            dispatch(setIsLoading(false));
        };

        if (chatConversationSelected && chatConversationSelected.id) {
            if (chatConversationSelected.id !== chatConversationSelectedIdRef.current) {
                chatConversationSelectedIdRef.current = chatConversationSelected.id;

                if (chatConversationSelected.participants) {
                    chatConversationSelected.participants.forEach((participant) => {
                        participantListMap.current[participant.user] = participant;
                    });
                }

                fetchData();
            }
        } else {
            chatConversationSelectedIdRef.current = null;
        }
    }, [chatConversationSelected, getConversationsInfo, showMessages, getPrevNextConv, isCustomer, client, dispatch]);

    useEffect(() => {
        if (scrollToBottomMessageList) {
            dispatch(setScrollToBottomMessageList(false));

            if (scrollableDivContentConvRef.current.scrollTop !== 0) {
                scrollableDivContentConvRef.current.scrollTop = 0;
            }
        }
    }, [scrollToBottomMessageList, dispatch]);

    useEffect(() => {
        if (chatConversationSelected && chatConversationSelected.id) {
            if (conversationMessageList && conversationMessageList.length) {
                setHasMore(true);
            }
        }
    }, [conversationMessageList, chatConversationSelected]);

    const loadMoreMessages = useCallback(() => {
        if (!chatConversationSelected || !chatConversationSelected.id) {
            return false;
        }

        if (isCustomer) {
            let seqSmaller = 0;
            let createdSmaller = Number.MAX_SAFE_INTEGER;
            if (conversationMessageList && conversationMessageList.length) {
                createdSmaller = conversationMessageList[conversationMessageList.length - 1].created;
                seqSmaller = conversationMessageList[conversationMessageList.length - 1].seq;
            }
            console.log("createdSmaller: ", createdSmaller, "seqSmaller: ", seqSmaller);

            if (seqSmaller <= 1) {
                setHasMore(false);
                return true;
            }

            const postBody = {
                customerId: authUser.userId || authUser.id,
                createdSmaller: createdSmaller,
                createdGreater: 0,
                limit: LIST_MESSAGE_LIMIT,
                sort: 'DESC'
            };

            chatConversationSelected
                .getLiveChatCustomerMessages(postBody)
                .then(function(res) {
                    console.log('messages list load more: ', res);

                    if (res.r === 0) {
                        fetchSenderInfo(res.messages, true);
                        setHasMore(false);
                    }
                });
        } else if (client.isInternal) {
            const limit = LIST_MESSAGE_LIMIT;
            const sort = 'DESC';
            const seqGreater = 0;

            let seqSmaller = Number.MAX_SAFE_INTEGER;
            if (conversationMessageList && conversationMessageList.length) {
                seqSmaller = conversationMessageList[conversationMessageList.length - 1].seq;
            }

            if (seqSmaller <= 1) {
                setHasMore(false);
                return true;
            }

            chatConversationSelected
                .getMessages(seqGreater, seqSmaller, limit, sort)
                .then(function(messages) {
                    console.log('messages list load more: ', messages);

                    dispatch(setConversationMessageList(conversationMessageList.concat(messages)));
                    setHasMore(false);
                })
                .catch(function(err) {
                    console.log(err);
                });
        } else {
            let seqSmaller = Number.MAX_SAFE_INTEGER;
            if (conversationMessageList && conversationMessageList.length) {
                seqSmaller = conversationMessageList[conversationMessageList.length - 1].seq;
            }
            console.log("seqSmaller: ", seqSmaller);

            if (seqSmaller <= 1) {
                setHasMore(false);
                return true;
            }

            const postBody = {
                seqGreater: 0,
                seqSmaller: seqSmaller,
                limit: LIST_MESSAGE_LIMIT,
                order: 'DESC'
            };

            chatConversationSelected
                .getLiveChatMessages(postBody)
                .then(function(messages) {
                    console.log('messages list load more: ', messages);

                    fetchSenderInfo(messages, true);
                    setHasMore(false);
                })
                .catch(function(err) {
                    console.log(err);
                });
        }
    }, [chatConversationSelected, conversationMessageList, isCustomer, fetchSenderInfo, authUser,
        dispatch, client]);

    const getHeightConvMsgList = useCallback(() => {
        let height = "100vh";

        if (isCustomer) {
            height += " - 158px - 58px - 40px - 5px";

            if (isPopup) {
                height += " - 30px";
            }
        } else {
            height += " - 170px";

            if (selectedFiles && selectedFiles.length) {
                height += ' - 85px';
            }
        }

        return `calc(${height})`;
    }, [isCustomer, selectedFiles, isPopup]);

    const allowedChat = useCallback(() => {
        if (isCustomer) {
            return true;
        }

        if ((client.isInternal && chatConversationSelectedInfo.participants) ||
            [chatStatusDefine.ANSWERING, chatStatusDefine.INACTIVE].includes(chatConversationSelectedInfo.chatStatus)) {
            const userId = authUser.id || authUser.userId;
            return chatConversationSelectedInfo.participants.some((el) => (el.user === userId));
        }

        return false;
    }, [chatConversationSelectedInfo, isCustomer, authUser, client]);

    if (!chatConversationSelected || !chatConversationSelected.id) {
        return <div className="not-selected-conversation">
            {i18n.t('content.choose_a_conv')}
        </div>;
    }

    return (
        <>
            {/* <ChatHeader/>*/}

            <div
                id="scrollableDivContentConv"
                className={"conversation-message-list" + (allowedChat() ? '' : ' hide-input-message')}
                style={{height: getHeightConvMsgList()}}
                ref={scrollableDivContentConvRef}
            >
                <InfiniteScroll
                    dataLength={conversationMessageList.length}
                    next={loadMoreMessages}
                    style={{display: 'flex', flexDirection: 'column-reverse'}}
                    inverse={true}
                    hasMore={hasMore}
                    loader={
                        <div style={{textAlign: "center"}}><CircularProgress /></div>
                    }
                    scrollableTarget="scrollableDivContentConv"
                >
                    <ConversationNavigation conv={prevConversation}/>

                    <TypingIndicator/>

                    <ConversationMessageList scrollableDivContentConvRef={scrollableDivContentConvRef.current}/>

                    <ConversationNavigation conv={nextConversation} isNext={true}/>
                </InfiniteScroll>
            </div>

            {
                allowedChat() && <MessageField />
            }
        </>
    );
};

export default ContentConversation;
