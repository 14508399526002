import {DEFAULT_AGENT_AVATAR} from "../../common/config";
import {Button} from "@mui/material";
import {ratingTypeDefine} from "../../common/constants";
import {ThumbDown, ThumbDownOffAltOutlined, ThumbUp, ThumbUpOffAltOutlined} from "@mui/icons-material";
import {red} from "@mui/material/colors";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAgentReceivesConvName, selectChatProfile, selectRating, setRating} from "../../store/chatbox";
import {useEffect, useState} from "react";
import "./../../styles/scss/chat_box_header.scss";
import {selectAllowEndChat, selectChatConversationSelected} from "../../store/chatConversationSelected";
import {unshiftItemToConversationMessageList} from "../../store/conversationMessageList";
import {useTranslation} from "react-i18next";

function ChatBoxHeader() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const chatProfile = useSelector(selectChatProfile);
    const chatConversationSelected = useSelector(selectChatConversationSelected);
    const currentRating = useSelector(selectRating);
    const agentReceivesConvName = useSelector(selectAgentReceivesConvName);
    const allowEndChat = useSelector(selectAllowEndChat);
    const disableRating = [ratingTypeDefine.GOOD, ratingTypeDefine.BAD].includes(currentRating);

    const [chatLogoUrl, setChatLogoUrl] = useState(chatProfile.logo_url);

    useEffect(() => {
        setChatLogoUrl(chatProfile.logo_url);
    }, [chatProfile.logo_url]);
    function setDefaultAvatar() {
        setChatLogoUrl(DEFAULT_AGENT_AVATAR);
    }

    const onSelectRating = (rating) => {
        if (chatConversationSelected && chatConversationSelected.id) {
            chatConversationSelected.rateChat(rating, "").then((res) => {
                console.log('rateChat res++++++++', res);
                dispatch(setRating(rating));

                // push messge: Thêm bình luận
                const created = Date.now();
                dispatch(unshiftItemToConversationMessageList({
                    action: "REQUEST_CHAT_COMMENT",
                    id: "msg-add-comment-" + created,
                    created
                }));
            }).catch((err) => {
                console.log('rateChat err++++++++', err);
            });
        }
    };

    return <div className="chat-box-header">
        <div className="chat-box-header__left">
            <div className="agent_avatar">
                <img
                    className="agent_avatar"
                    alt=""
                    src={chatLogoUrl}
                    onError={setDefaultAvatar}
                />
            </div>
            <div className="agent_name">
                <p className="name">{agentReceivesConvName}</p>
                <p className="support">{t("chatbox.cus_support")}</p>
            </div>
        </div>
        <div className="chat-box-header__right">
            {allowEndChat && (
                <>
                    <Button
                        disabled={disableRating}
                        component="label"
                        onClick={() => onSelectRating(ratingTypeDefine.GOOD)}
                        sx={{minWidth: "unset"}}
                    >
                        {currentRating === ratingTypeDefine.GOOD ?
                            <ThumbUp sx={{color: "#1976d2"}}/> :
                            <ThumbUpOffAltOutlined color="disabled"/>
                        }
                    </Button>
                    <Button
                        disabled={disableRating}
                        component="label"
                        onClick={() => onSelectRating(ratingTypeDefine.BAD)}
                        sx={{minWidth: "unset"}}
                    >
                        {currentRating === ratingTypeDefine.BAD ?
                            <ThumbDown sx={{color: red[400]}}/> :
                            <ThumbDownOffAltOutlined color="disabled"/>
                        }
                    </Button>
                </>
            )}
        </div>
    </div>;
}

export default ChatBoxHeader;
