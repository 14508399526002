import {formatDateTime} from "../../../common/common";
import {chatTypeDefine, fileIcons} from "../../../common/constants";
import "./../../../styles/scss/message_body.scss";
import {Check, CloudDownloadSharp, DoneAll} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {
    selectChatCustomerAccessToken,
    selectCurrentPlayerPlay,
    selectPublicApiStringee,
    setCurrentPlayerPlay
} from "../../../store/chatGeneral";
import {
    selectChatConversationSelectedInfo
} from "../../../store/chatConversationSelected";
import {green} from "@mui/material/colors";
import {Link} from "@mui/material";
import Linkify from 'react-linkify';
import FullscreenImage from "./FullscreenImage";
import {useCallback} from "react";

import emojiRegex from 'emoji-regex';
const regex = emojiRegex();

const MessageBody = (props) => {
    const messageData = props.messageData;
    const currentUserSendMsg = props.currentUserSendMsg;

    const dispatch = useDispatch();
    const currentPlayerPlay = useSelector(selectCurrentPlayerPlay);
    const chatCustomerAccessToken = useSelector(selectChatCustomerAccessToken);
    const publicApiStringee = useSelector(selectPublicApiStringee);
    const chatConversationSelectedInfo = useSelector(selectChatConversationSelectedInfo);

    function getUrlFile(file) {
        if (file.file_id) {
            const url = new URLSearchParams({
                file_id: file.file_id,
                access_token: chatCustomerAccessToken
            });

            return publicApiStringee + 'v1/file/download?' + url.toString();
        }

        return file.filePath;
    }

    function stopOthers(currentFileId) {
        if (currentPlayerPlay && currentPlayerPlay !== currentFileId) {
            const playerDom = document.getElementById('player-' + currentPlayerPlay);
            if (playerDom) {
                playerDom.pause();
            }
        }

        dispatch(setCurrentPlayerPlay(currentFileId));
    }

    function checkTextType(text) {
        if (regex.test(text)) {
            const emojis = text.match(regex);
            if (emojis.length === 1 && text === emojis[0]) {
                return "one_emoji";
            } else if (emojis.length <= 3 && emojis.join('') === text) {
                return "multiple_emoji";
            }
        }

        return "text";
    }

    const showMsgTime = useCallback(() => {
        return <div className="message-time">
            <span style={{marginRight: (currentUserSendMsg ? "5px" : null)}}>
                {formatDateTime(messageData.created, "HH:mm")}
            </span>

            <span style={{verticalAlign: "middle"}}>
                {currentUserSendMsg && (
                    chatConversationSelectedInfo.lastMsgSeqSeen >= messageData.seq ?
                        <DoneAll sx={{color: green[500], fontSize: "16px"}}/> :
                        chatConversationSelectedInfo.lastMsgSeqReceived >= messageData.seq ?
                            <Check sx={{color: green[500], fontSize: "16px"}}/> :
                            chatConversationSelectedInfo.lastMsgSeqReceived < messageData.seq ?
                                <Check color='disabled' sx={{fontSize: "16px"}}/> : null
                )}
            </span>
        </div>;
    }, [currentUserSendMsg, chatConversationSelectedInfo, messageData]);

    function showTextContent() {
        if (!messageData.content || !messageData.content.content) {
            return "NO CONTENT";
        }

        const textTrim = messageData.content.content.replace(/\s/g, '');
        const textType = checkTextType(textTrim);

        switch (textType) {
            case 'one_emoji':
                return <span className="message-text-emoji">
                    <span style={{fontSize: "50px", display: "block"}}>
                        {textTrim}
                    </span>
                    {showMsgTime()}
                </span>;
            case 'multiple_emoji':
                return <span className="message-text-emoji">
                    <span style={{fontSize: "26px", display: "block"}}>
                        {textTrim}
                    </span>
                    {showMsgTime()}
                </span>;
            default: // text
                return <span className="message-text">
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a href={decoratedHref} key={key} target="_blank" rel="noreferrer">
                                {decoratedText}
                            </a>
                        )}
                    >
                        {messageData.content.content}
                    </Linkify>
                    {showMsgTime()}
                </span>;

                // Code này xử lý replace để tăng kích thước emoji

                // const renderHtml = [];
                // let textContent = messageData.content.content;
                // const emojis = textContent.match(regex);
                //
                // if (emojis) {
                //     emojis.forEach((emoji) => {
                //         const emojiIdx = textContent.indexOf(emoji);
                //         if (emojiIdx) {
                //             renderHtml.push({
                //                 type: 'text',
                //                 content: textContent.slice(0, emojiIdx)
                //             });
                //         }
                //
                //         const emojiSpan = `<span style="font-size: 16px;">${emoji}</span>`;
                //         renderHtml.push({
                //             type: 'emoji',
                //             content: emojiSpan
                //         });
                //         textContent = textContent.slice(emojiIdx + emoji.length);
                //     });
                // }
                //
                // if (textContent) {
                //     renderHtml.push({
                //         type: 'text',
                //         content: textContent
                //     });
                // }
                //
                // return renderHtml.map((item) => {
                //     if (item.type === 'emoji') {
                //         return <span dangerouslySetInnerHTML={{__html: item.content}} />;
                //     } else { // text
                //         return <Linkify
                //             componentDecorator={(decoratedHref, decoratedText, key) => (
                //                 <a href={decoratedHref} key={key} target="_blank" rel="noreferrer">
                //                     {decoratedText}
                //                 </a>
                //             )}
                //         >
                //             {item.content}
                //         </Linkify>;
                //     }
                // });
        }
    }

    function showImageContent() {
        if (!messageData.content.photo) {
            return null;
        }

        const imgSrc = getUrlFile(messageData.content.photo);

        return <FullscreenImage imgSrc={imgSrc} messageData={messageData}/>;
    }

    function showVideoContent() {
        if (!messageData.content.video) {
            return null;
        }

        return (
            <div>
                <video
                    id={"player-" + messageData.content.video.file_id}
                    preload="metadata"
                    className="message-video"
                    controls
                    onPlay={() => stopOthers(messageData.content.video.file_id)}
                >
                    <source
                        src={getUrlFile(messageData.content.video)}
                        type={'video/' + messageData.content.video.format}
                    />
                    <source
                        src={getUrlFile(messageData.content.video)}
                        type="video/ogg"
                    />
                    Your browser does not support the video tag.
                </video>
            </div>
        );
    }

    function showAudioContent() {
        if (!messageData.content.audio) {
            return false;
        }

        return <div>
            <audio
                id={"player-" + messageData.content.audio.file_id}
                preload="metadata"
                className="message-audio"
                controls
                onPlay={() => stopOthers(messageData.content.audio.file_id)}
            >
                <source
                    src={getUrlFile(messageData.content.audio)}
                    type={'audio/' + messageData.content.audio.format}
                />
                <source
                    src={getUrlFile(messageData.content.audio)}
                    type="audio/ogg"
                />
                Your browser does not support the audio tag.
            </audio>
        </div>;
    }

    function showFileContent() {
        if (!messageData.content.file) {
            return null;
        }

        function downloadWithAxios(file) {
            const downloadUrl = getUrlFile(file);
            window.open(downloadUrl, "_blank");
        }

        return <div className="file_doc">
            <Link href="#" target="_blank">
                <img
                    className="file-icon"
                    src={fileIcons[messageData.content.file.format] || fileIcons['no_icon']}
                    alt={messageData.content.file_id}
                />
            </Link>

            <div
                className="download-icon"
                onClick={() => downloadWithAxios(messageData.content.file)}
            >
                <CloudDownloadSharp
                    className="icon download"
                />
            </div>
        </div>;
    }

    function showStickerContent() {
        if (!messageData.content.sticker) {
            return null;
        }

        const imgSrc = getUrlFile(messageData.content.sticker);

        return <div>
            <img
                src={imgSrc}
                className="chat-sticker"
                alt={messageData.user_name}
            />
        </div>;
    }

    function showMsgContent() {
        if (!messageData.content) {
            return null;
        }

        switch (messageData.type) {
            case chatTypeDefine.TEXT:
                return <>
                    {showTextContent()}
                </>;
            case chatTypeDefine.IMAGE:
                return <span className="message-image-wrapper">
                    {showImageContent()}
                    {showMsgTime()}
                </span>;
            case chatTypeDefine.VIDEO:
                return <span className="message-video-wrapper">
                    {showVideoContent()}
                    {showMsgTime()}
                </span>;
            case chatTypeDefine.AUDIO:
                return <span className="message-audio-wrapper">
                    {showAudioContent()}
                    {showMsgTime()}
                </span>;
            case chatTypeDefine.FILE:
                return <span className="message-file-wrapper">
                    {showFileContent()}
                    {showMsgTime()}
                </span>;
            case chatTypeDefine.STICKER:
                return <span className="message-sticker-wrapper">
                    {showStickerContent()}
                    {showMsgTime()}
                </span>;
            default:
                return null;
        }
    }

    return (
        <span className="message-body">
            {showMsgContent()}
        </span>
    );
};

export default MessageBody;
