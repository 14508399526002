import {configureStore} from "@reduxjs/toolkit";
import pageIsErrorReducer from "./pageIsError";
import chatConversationListReducer from "./chatConversationList";
import conversationMessageListReducer from "./conversationMessageList";
import chatConversationSelectedReducer from "./chatConversationSelected";
import chatAuthUserReducer from "./chatAuthUser";
import chatStringeeClientReducer from "./chatStringeeClient";
import chatGeneralReducer from "./chatGeneral";
import chatboxReducer from "./chatbox";
import notifyMessageReducer from "./notifyMessage";
import chatTemplateReducer from "./chatTemplate";
import messageFieldReducer from "./messageField";

export const store = configureStore({
    reducer: {
        pageIsError: pageIsErrorReducer,
        chatConversationList: chatConversationListReducer,
        conversationMessageList: conversationMessageListReducer,
        chatConversationSelected: chatConversationSelectedReducer,
        chatAuthUser: chatAuthUserReducer,
        chatStringeeClient: chatStringeeClientReducer,
        chatGeneral: chatGeneralReducer,
        chatbox: chatboxReducer,
        notifyMessage: notifyMessageReducer,
        chatTemplate: chatTemplateReducer,
        messageField: messageFieldReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});
