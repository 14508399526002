import {useCallback, useRef} from 'react';
import {formatDateTime} from "../../../common/common";
import {useSelector} from "react-redux";
import {selectIsCustomer} from "../../../store/chatbox";
import {selectStringeeClient} from "../../../store/chatStringeeClient";

const FullscreenImage = ({imgSrc, messageData}) => {
    const imgRef = useRef(null);

    const isCustomer = useSelector(selectIsCustomer);
    const client = useSelector(selectStringeeClient);

    const showFullImg = useCallback(() => {
        if (isCustomer || client.isInternal) {
            if (!document.fullscreenElement) {
                // Enter fullscreen
                const elem = imgRef.current;
                if (elem.requestFullscreen) {
                    elem.requestFullscreen().catch((err) => {
                        console.error(`Error attempting to enable fullscreen: ${err.message}`);
                    });
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else {
                    alert("Sorry, your browser is too old and doesn't support fullscreen :-(");
                }
            } else {
                // Exit fullscreen
                if (document.exitFullscreen) {
                    document.exitFullscreen().catch((err) => {
                        console.error(`Error attempting to exit fullscreen: ${err.message}`);
                    });
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        } else {
            const postMessageData = {
                action: 'handleShowImage',
                data: {
                    src: imgSrc,
                    description: formatDateTime(messageData.created, 'dddd, MMMM Do YYYY, h:mm:ss A')
                }
            };
            window.parent.postMessage(postMessageData, "*");
        }
    }, [isCustomer, client, imgSrc, messageData]);

    return <div className="message-image" onClick={showFullImg}>
        <img
            ref={imgRef}
            src={imgSrc}
            alt={messageData.id}
            className="message-image__content"
        />
        {isCustomer && <span className="fullscreen-icon">
            <img alt="" src="https://v2.stringee.com/stringeexchat/sdk/product//css/assets/icon-fullscreen.svg"/>
        </span>}
    </div>;
};

export default FullscreenImage;
