import {useDispatch, useSelector} from "react-redux";
import "./../../styles/scss/list_conversation.scss";
import {
    selectChatConversationList,
    selectIsLoadingConvList,
    setChatConversationList
} from "../../store/chatConversationList";
import {selectChatConversationSelected, setConversationSelected} from "../../store/chatConversationSelected";
import ConversationListItem from "./conversation_list_panel/ConversationListItem";
import {CircularProgress, List} from "@mui/material";
import {useCallback, useEffect} from "react";
import {selectStringeeClient} from "../../store/chatStringeeClient";
import InfiniteScroll from "react-infinite-scroll-component";
import {BASE_URL} from "../../common/config";
import i18n from "../../common/i18n";
// import ConversationListHeader from "./conversation_list_panel/ConversationListHeader";

const NoDataIcon = BASE_URL + '/assets/images/no-data.svg';

const ConversationList = ({filter, setFilter, hasMore, setHasMore}) => {
    const chatConversationList = useSelector(selectChatConversationList);
    const client = useSelector(selectStringeeClient);
    const chatConversationSelected = useSelector(selectChatConversationSelected);
    const isLoadingConvList = useSelector(selectIsLoadingConvList);
    const dispatch = useDispatch();
    const StringeeChat2 = window.StringeeChat2;

    async function onSelectConv(conv) {
        if (chatConversationSelected && conv.id === chatConversationSelected.id) {
            return false;
        }

        console.log("selected conversation: ", conv);
        dispatch(setConversationSelected(conv));
    }

    const fetchMoreConversations = useCallback(async () => {
        let newConversations;

        if (client.isInternal) {
            const lastUpdateGreater = 0;
            const lastUpdateSmaller = filter.lastUpdateSmaller;
            newConversations = await StringeeChat2.getListConversations(
                client,
                lastUpdateGreater,
                lastUpdateSmaller,
                10
            );
        } else {
            if (filter.customerId) {
                newConversations = await StringeeChat2.getLiveChatConversationsForCustomer(client, filter);
            } else {
                newConversations = await StringeeChat2.getLiveChatConversations(client, filter);
            }
        }

        console.log("load more convs: ", newConversations);
        if (newConversations && newConversations.length !== 0) {
            dispatch(setChatConversationList(chatConversationList.concat(newConversations)));
        } else {
            setHasMore(false);
        }
    }, [dispatch, chatConversationList, client, StringeeChat2, filter, setHasMore]);

    useEffect(() => {
        const lastConversation = chatConversationList[chatConversationList.length - 1];
        setFilter((prevFilter) => {
            const newFilter = {...prevFilter};
            if (lastConversation) {
                newFilter.lastUpdateSmaller = lastConversation.lastUpdate;
                newFilter.createdSmaller = lastConversation.created;
            }
            return newFilter;
        });
    }, [chatConversationList, setFilter]);

    if (isLoadingConvList) {
        return <div className="conversation-list">
            <div className="text-center">
                <CircularProgress/>
            </div>
        </div>;
    }

    if (chatConversationList.length === 0) {
        return <div className="conversation-list">
            <div className="no-data">
                <img className="no-data__icon" src={NoDataIcon} alt=""/>
                <div className="no-data__label">{i18n.t('list.no_data')}</div>
            </div>
        </div>;
    }

    return (
        <>
            {/* <ConversationListHeader/> */}

            <List disablePadding className="conversation-list" id="scrollableListConv">
                <InfiniteScroll
                    dataLength={chatConversationList.length}
                    next={fetchMoreConversations}
                    hasMore={hasMore}
                    loader={
                        <div style={{textAlign: "center"}}><CircularProgress /></div>
                    }
                    scrollableTarget="scrollableListConv"
                    scrollThreshold={0.9}
                >
                    {
                        chatConversationList.map((conv) => {
                            return <ConversationListItem
                                conversation={conv}
                                key={conv.id}
                                onSelectConv={onSelectConv}
                            />;
                        })
                    }
                </InfiniteScroll>
            </List>
        </>
    );
};

export default ConversationList;
