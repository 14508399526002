import {Box, Tooltip} from "@mui/material";
import "./../../../styles/scss/system_message.scss";
import {useCallback, useEffect, useMemo, useState} from "react";
import {
    actorPlatformDefine, actorTypeDefine,
    CHAT_STATUS_MAP,
    chatContentTypeDefine,
    convStatusChangeReason
} from "../../../common/constants";
import {formatDateTime} from "../../../common/common";
import {useSelector} from "react-redux";
import {selectStringeeClient} from "../../../store/chatStringeeClient";
import {useTranslation} from "react-i18next";

function SystemMessage(props) {
    const {t} = useTranslation();
    const msgData = useMemo(() => props.messageData, [props.messageData]);
    const isListItem = props.isListItem || false;
    const [msgContent, setMsgContent] = useState('');
    const [isShowContentHtml, setIsShowContentHtml] = useState(false);
    const client = useSelector(selectStringeeClient);

    const getUsersInfo = useCallback((userId, textAction) => {
        window.StringeeChat2.getUsersInfo(client, [userId],
            function(status, code, message, users) {
                if (users && users.length) {
                    users.forEach((user) => {
                        if (user.userId === userId) {
                            setMsgContent(user.name + ' ' + textAction);
                        } else {
                            setMsgContent(userId + ' ' + textAction);
                        }
                    });
                } else {
                    setMsgContent(userId + ' ' + textAction);
                }
            }
        );
    }, [client]);

    const getConvStatusChangeContent = useCallback(() => {
        let result = '';
        const content = msgData.content;
        const newStatusData = CHAT_STATUS_MAP[content.newStatus] ?? {};
        const oldStatusData = CHAT_STATUS_MAP[content.oldStatus] ?? {};
        const platform = actorPlatformDefine[content.actorPlatform] || actorPlatformDefine[3];

        switch (msgData.content.reason) {
            case convStatusChangeReason.REROUTE_CHAT_AGENT_NO_REPLY_TIME_OUT:
                result = t('message.REROUTE_CHAT_AGENT_NO_REPLY_TIME_OUT', {
                    newStatus: t(newStatusData.label),
                    actorName: content.actorName,
                    platform,
                    timeout: content.timeout
                });
                // result = "Chuyển sang trạng thái <b>" + newStatusData.label + "</b> do <b>" + content.actorName +
                //     "</b> sử dụng " + platform + " đã tiếp nhận hội thoại quá " + content.timeout +
                //     " phút nhưng không phản hồi.";
                break;
            case convStatusChangeReason.REROUTE_CHAT_AGENT_DISCONNECT_TIME_OUT:
                result = t('message.REROUTE_CHAT_AGENT_DISCONNECT_TIME_OUT', {
                    newStatus: t(newStatusData.label),
                    actorName: content.actorName,
                    platform,
                    timeout: content.timeout
                });
                // result = "Chuyển sang trạng thái <b>" + newStatusData.label + "</b> do <b>" + content.actorName +
                //     "</b> sử dụng " + platform + " đã mất kết nối quá " + content.timeout + " phút.";
                break;
            case convStatusChangeReason.NEW_MSG_FROM_CUSTOMER_TO_INACTIVE_CONV:
            case convStatusChangeReason.NEW_MSG_FROM_AGENT_TO_INACTIVE_CONV:
                result = t('message.NEW_MSG_TO_INACTIVE_CONV', {
                    newStatus: t(newStatusData.label),
                    oldStatus: t(oldStatusData.label)
                });
                // result = "Chuyển sang trạng thái <b>" + newStatusData.label+ "</b> do hội thoại ở trạng thái <b>" +
                //     oldStatusData.label + "</b> có tin nhắn mới.";
                break;
            case convStatusChangeReason.CUSTOMER_DISCONNECT_TIMEOUT:
                if (content.actorType === actorTypeDefine.CUSTOMER) {
                    result = t('message.CUSTOMER_DISCONNECT_TIMEOUT', {
                        newStatus: t(newStatusData.label),
                        platform,
                        timeout: content.timeout
                    });
                    // result = "Chuyển sang trạng thái <b>" + newStatusData.label + "</b> do khách hàng sử dụng " +
                    //     platform + " mất kết nối quá " + content.timeout + " phút.";
                }

                break;
            case convStatusChangeReason.NO_MESSAGE_TO_CONVERSATION_TIMEOUT:
                result = t('message.NO_MESSAGE_TO_CONVERSATION_TIMEOUT', {
                    newStatus: t(newStatusData.label),
                    timeout: content.timeout
                });
                // result = "Chuyển sang trạng thái <b>" + newStatusData.label +
                //     "</b> do hội thoại không có tin nhắn mới quá " + content.timeout + " phút.";
                break;
            case convStatusChangeReason.ROUTE_CHAT_IN_QUEUE_TIME_OUT:
                result = t('message.ROUTE_CHAT_IN_QUEUE_TIME_OUT', {
                    timeout: content.timeout
                });
                // result = "Quá " + content.timeout + " giây đổ chuông vào hàng đợi";
                break;
            case convStatusChangeReason.END_CHAT_BY_INACTIVE_TIMEOUT:
                result = t('message.END_CHAT_BY_INACTIVE_TIMEOUT', {
                    newStatus: t(newStatusData.label),
                    oldStatus: t(oldStatusData.label),
                    timeout: content.timeout
                });
                // result = "Chuyển sang trạng thái <b>" + newStatusData.label + "</b> do hội thoại ở trạng thái <b>" +
                //      oldStatusData.label + "</b> quá " + content.timeout + " phút.";
                break;
            default:
                break;
        }

        setIsShowContentHtml(true);
        setMsgContent(result);
    }, [msgData, t]);

    useEffect(() => {
        if (msgData.content) {
            if (msgData.content.creator) {
                if (msgData.user_name) {
                    setMsgContent(msgData.user_name + ' '+ t('message.join_chat'));
                } else {
                    getUsersInfo(msgData.content.creator, t('message.join_chat'));
                }
            } else if (msgData.content.addedby) {
                msgData.content.participants.forEach((part) => {
                    if (msgData.is_user === 1) {
                        if (msgData.agent_name) {
                            setMsgContent(msgData.agent_name + ' ' + t('message.join_chat'));
                        }
                    } else if (part.displayName) {
                        setMsgContent(part.displayName + ' ' + t('message.join_chat'));
                    }
                });
            } else if (msgData.content.removedBy) {
                if (msgData.content.participants && msgData.content.participants.length) {
                    msgData.content.participants.forEach((part) => {
                        if (msgData.is_user === 1) {
                            if (msgData.agent_name) {
                                setMsgContent(msgData.agent_name + ' ' + t('message.leave_chat'));
                            }
                        } else if (part.displayName) {
                            setMsgContent(part.displayName + ' ' + t('message.leave_chat'));
                        } else if (msgData.user_name) {
                            setMsgContent(msgData.user_name + ' ' + t('message.leave_chat'));
                        }
                    });
                } else {
                    if (msgData.is_user) {
                        if (msgData.agent_name) {
                            setMsgContent(msgData.agent_name + ' ' + t('message.leave_chat'));
                        }
                    } else if (msgData.user_name) {
                        setMsgContent(msgData.user_name + ' ' + t('message.leave_chat'));
                    } else {
                        getUsersInfo(msgData.content.removedBy, t('message.leave_chat'));
                    }
                }
            } else if (msgData.content.endedby) {
                if (msgData.content.participants && msgData.content.participants.length) {
                    msgData.content.participants.forEach((part) => {
                        if (msgData.is_user === 1) {
                            if (msgData.agent_name) {
                                setMsgContent(msgData.agent_name + ' ' + t('message.end_chat'));
                            }
                        } else if (part.displayName) {
                            setMsgContent(part.displayName + ' ' + t('message.end_chat'));
                        }
                    });
                } else {
                    if (msgData.is_user) {
                        if (msgData.agent_name) {
                            setMsgContent(msgData.agent_name + ' ' + t('message.end_chat'));
                        }
                    } else if (msgData.user_name) {
                        setMsgContent(msgData.user_name + ' ' + t('message.end_chat'));
                    } else {
                        getUsersInfo(msgData.content.endedby, t('message.end_chat'));
                    }
                }
            } else if (msgData.type === 14) {
                setMsgContent(msgData.user_name + ' ' +
                    (msgData.content.rating ? t('message.rate_chat_good') : t('message.rate_chat_not_good')));
            } else if (msgData.type === 100) {
                if (msgData.content.type === chatContentTypeDefine.CHAT_CONVERSATION_STATUS_CHANGE) {
                    getConvStatusChangeContent();
                } else if (msgData.content.type === chatContentTypeDefine.CHAT_TRANSFER) {
                    const oldAgent = msgData.content.oldAgent || {};
                    const newAgent = msgData.content.newAgent || {};
                    setMsgContent(t('message.CHAT_TRANSFER', {
                        oldName: oldAgent.name,
                        newName: newAgent.name
                    }));
                    // setMsgContent(oldAgent.name + ' đã transfer hội thoại cho ' + newAgent.name);
                } else if (msgData.content.type === chatContentTypeDefine.CHAT_CUSTOMER_COMMENT_RATING) {
                    setMsgContent(
                        msgData.user_name + " " + t('message.comment') + " '" + msgData.content.content + "'"
                    );
                }
            }
        }
    }, [msgData, getUsersInfo, getConvStatusChangeContent, t]);

    if (isListItem) {
        return (
            <Box className="chat-item__last-msg">
                {isShowContentHtml ?
                    <span dangerouslySetInnerHTML={{__html: msgContent}} /> :
                    <span>{msgContent}</span>
                }
            </Box>
        );
    }

    if (!msgContent) {
        return '----------------------';
    }

    return (
        <Box className="system-message-wrapper">
            <Tooltip
                title={formatDateTime(msgData.created, "HH:mm")}
                placement="right-end"
            >
                {isShowContentHtml ?
                    <span dangerouslySetInnerHTML={{__html: msgContent}} /> :
                    <span>{msgContent}</span>
                }
            </Tooltip>
        </Box>
    );
}

export default SystemMessage;
