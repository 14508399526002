import {useTranslation} from "react-i18next";
import {BASE_URL} from "../../../common/config";
import {useDispatch} from "react-redux";
import {setIsShowInnerBusinessHour} from "../../../store/chatbox";

const SendMsgIcon = BASE_URL + "/assets/images/sent-msg.png";

function InnerBusinessHour() {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const hide = () => {
        dispatch(setIsShowInnerBusinessHour(false));
    };

    return <>
        <div className="inner-business-hour">
            <div className="sc-dnqmqq fmIRHN">
                <div className="sc-iwsKbI fcOiXF">
                    <img src={SendMsgIcon} alt=""/>
                </div>
                <div>
                    <h2 className="reaching-out">{t('chatbox.reaching_out')}</h2>
                    <span className="contact-someone">{t('chatbox.contact_someone')}</span>
                </div>
                <div>
                    <a href="#" className="go-back-intial-form" onClick={hide}>
                        {t('chatbox.go_back')}
                    </a>
                </div>
            </div>
        </div>
    </>;
}

export default InnerBusinessHour;
