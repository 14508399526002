import {createSlice} from "@reduxjs/toolkit";

// Redux toolkit cho phép định nghĩa state (VD: "pageIsError"), định nghĩa các reducers/action (VD: setPageIsError)
// ngay trong 1 file JS, cho phép code dễ bảo trì hơn

// định nghĩa giá trị khởi tạo của state
// value=true tức là trang đang render có lỗi
// r/msg: thông tin chi tiết về lỗi
const initialState = {
    value: false,
    r: -1,
    msg: ""
};

// định nghĩa state name, reducers, actions
const slice = createSlice({
    name: "pageIsError",
    initialState,
    reducers: {
        // action public ra để dùng là: setPageIsError(true/false)
        setPageIsError: (state, action) => {
            state.value = action.payload.value;
            state.r = action.payload.r;
            state.msg = action.payload.msg;
        }
    }
});

// export state, actions, reducer để các components sử dụng
// lưu ý: quy định đặt tên giống như sample này để dễ hiểu, dễ bảo trì code
export const selectPageIsError = (state) => state.pageIsError;
export const {setPageIsError} = slice.actions;
export default slice.reducer;
