import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import {Provider} from "react-redux";
import {store} from "./store/index";
import './common/i18n';

ReactDOM.createRoot(document.getElementById("root"))
    .render(
        <Provider store={store}>
            <App/>
        </Provider>
    );
