import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";
import {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectChatConversationSelected, setIsReloadConvContent} from "../../../store/chatConversationSelected";
import ModalChatReview from "./ModalChatReview";
import {selectRating} from "../../../store/chatbox";
import {ratingTypeDefine} from "../../../common/constants";
import {useTranslation} from "react-i18next";

const ModalConfirmEndChat = forwardRef((props, ref) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);

    const currentRating = useSelector(selectRating);
    const chatConversationSelected = useSelector(selectChatConversationSelected);
    const dispatch = useDispatch();

    const modalChatReviewRef = useRef(null);

    useImperativeHandle(ref, () => ({
        show() {
            setOpen(true);
        }
    }));

    const handleEndChat = () => {
        if (currentRating === ratingTypeDefine.GOOD || currentRating === ratingTypeDefine.BAD) {
            chatConversationSelected.endChat().then((res) => {
                console.log('end chat res: ', res);

                if (res.r === 0) {
                    dispatch(setIsReloadConvContent(true));
                    setOpen(false);
                }
            });
        } else {
            setOpen(false);
            modalChatReviewRef.current?.show();
        }
    };

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle sx={{fontSize: "16px"}}>{t('chatbox.chat_end_confirm')}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t('chatbox.btn_cancel')}</Button>
                    <Button onClick={handleEndChat}>{t('chatbox.btn_exit')}</Button>
                </DialogActions>
            </Dialog>

            <ModalChatReview ref={modalChatReviewRef}/>
        </>
    );
});

export default ModalConfirmEndChat;
