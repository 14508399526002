import {createSlice} from "@reduxjs/toolkit";
import {chatStatusDefine} from "../common/constants";
import {getChatCreatorData} from "../common/common";

// Redux toolkit cho phép định nghĩa state (VD: "pageIsError"), định nghĩa các reducers/action (VD: setPageIsError)
// ngay trong 1 file JS, cho phép code dễ bảo trì hơn

// định nghĩa giá trị khởi tạo của state
// các state chứa dữ liệu lấy từ API (server) về thì cần có state.status:
// init (-1): mới khởi tạo; success (0): lấy thành công dữ liệu từ API; error (1): có lỗi lấy dữ liệu
// state.data: dữ liệu lấy từ server về (nếu thành công)
const initialState = {
    conversationSelected: {}, // XCHAT_LOAD_CONVERSATION: 207
    conversationSelectedInfo: {}, // XCHAT_LOAD_CONVERSATION_INFO: 209
    prevConversation: null,
    nextConversation: null,
    isReloadConvContent: false,
    isOnlyReloadConvInfo: false,
    isUpdateLastMsgInConvList: false,
    isEndChat: false,
    allowEndChat: false,
    unreadMsgCount: 0
};

// định nghĩa state name, reducers, actions
const slice = createSlice({
    name: "chatConversationSelected",
    initialState,
    reducers: {
        setConversationSelected: (state, action) => {
            const conv = action.payload;
            getChatCreatorData(conv);

            state.conversationSelected = conv;
        },
        setConversationSelectedInfo: (state, action) => {
            const conversationSelectedInfo = action.payload;
            state.conversationSelectedInfo = conversationSelectedInfo;
            state.isEndChat =
                conversationSelectedInfo.chatStatus === chatStatusDefine.ENDED ||
                conversationSelectedInfo.chatStatus === chatStatusDefine.MISSED;
            state.allowEndChat =
                conversationSelectedInfo.chatStatus === chatStatusDefine.ANSWERING ||
                conversationSelectedInfo.chatStatus === chatStatusDefine.INACTIVE;
        },
        setPrevConversation: (state, action) => {
            state.prevConversation = action.payload;
        },
        setNextConversation: (state, action) => {
            state.nextConversation = action.payload;
        },
        setIsReloadConvContent: (state, action) => {
            state.isReloadConvContent = action.payload;
        },
        setIsOnlyReloadConvInfo: (state, action) => {
            state.isOnlyReloadConvInfo = action.payload;
        },
        setIsUpdateLastMsgInConvList: (state, action) => {
            state.isUpdateLastMsgInConvList = action.payload;
        },
        setUnreadMsgCount: (state, action) => {
            const unreadCount = action.payload || 0;

            if (state.conversationSelected && state.conversationSelected.id) {
                if (window.parent) {
                    window.parent.postMessage({
                        action: "updateUnreadConvPinCounter",
                        data: {
                            convId: state.conversationSelected.id,
                            unread: unreadCount
                        }
                    }, '*');
                }
            }

            state.unreadMsgCount = unreadCount;
        }
    }
});

// export state, actions, reducer để các components sử dụng
// lưu ý: quy định đặt tên giống như sample này để dễ hiểu, dễ bảo trì code
export const selectChatConversationSelected = (state) => state.chatConversationSelected.conversationSelected;
export const selectChatConversationSelectedInfo = (state) => state.chatConversationSelected.conversationSelectedInfo;
export const selectPrevConversation = (state) => state.chatConversationSelected.prevConversation;
export const selectNextConversation = (state) => state.chatConversationSelected.nextConversation;
export const selectIsReloadConvContent = (state) => state.chatConversationSelected.isReloadConvContent;
export const selectIsOnlyReloadConvInfo = (state) => state.chatConversationSelected.isOnlyReloadConvInfo;
export const selectIsUpdateLastMsgInConvList = (state) => state.chatConversationSelected.isUpdateLastMsgInConvList;
export const selectIsEndChat = (state) => state.chatConversationSelected.isEndChat;
export const selectAllowEndChat = (state) => state.chatConversationSelected.allowEndChat;
export const selectUnreadMsgCount = (state) => state.chatConversationSelected.unreadMsgCount;
export const {
    setConversationSelected,
    setConversationSelectedInfo,
    setPrevConversation,
    setNextConversation,
    setIsReloadConvContent,
    setIsOnlyReloadConvInfo,
    setIsUpdateLastMsgInConvList,
    setUnreadMsgCount
} = slice.actions;
export default slice.reducer;
