import {useCallback, useRef, useState} from "react";
import {Box, Button, CircularProgress, Link, Tooltip} from "@mui/material";
import EmojiPicker from "emoji-picker-react";
import ReplyBar from "./ReplyBar";
import "./../../../styles/scss/message_field.scss";
import {selectChatConversationSelected} from "../../../store/chatConversationSelected";
import {useDispatch, useSelector} from "react-redux";
import {
    audioExtensions,
    chatTypeDefine,
    fileExtensions,
    imgExtensions,
    videoExtensions
} from "../../../common/constants";
import {apiStringee} from "../../../api/ApiStringee";
import {
    setScrollToBottomMessageList,
    unshiftItemToConversationMessageList
} from "../../../store/conversationMessageList";
import MessageBottomChatBox from "./MessageBottomChatBox";
import {selectIsCustomer} from "../../../store/chatbox";
import {BASE_URL} from "../../../common/config";
import {updateChatConvListWhenSendMsg} from "../../../store/chatConversationList";
import {useTranslation} from "react-i18next";
import i18n from "../../../common/i18n";
import ChatTemplate from "./ChatTemplate";
import {
    concatMsgInput,
    pushSelectedFiles,
    removeSelectedFileWithIndex,
    selectSelectedFiles, setSelectedFiles
} from "../../../store/messageField";
import {
    selectShowTemplate, setFocusedIndex,
    setIsLoadingChatTemplate,
    setListChatTemplate,
    setShowTemplate
} from "../../../store/chatTemplate";
import {apiStringeeX} from "../../../api/ApiStringeeX";
import {selectStringeeClient} from "../../../store/chatStringeeClient";
import {Clear, Description} from "@mui/icons-material";
import {selectChatCustomerAccessToken, selectPublicApiStringee} from "../../../store/chatGeneral";

const MessageSendFileErrorMap = {
    '1': i18n.t("message_field.not_exist_conversation"),
    '7': i18n.t("message_field.user_not_in_conversation"),
    '30': i18n.t("message_field.post_param_invalid"),
    '31': i18n.t("message_field.fb_page_setting_is_not_active"),
    '32': i18n.t("message_field.24h_reply"),
    '33': i18n.t("message_field.person_is_not_available"),
    '34': i18n.t("message_field.error_channel_reply"),
    '35': i18n.t("message_field.is_controlled_by_another_app"),
    '40': i18n.t("message_field.post_param_invalid"),
    '41': i18n.t("message_field.portal_not_have_integration_zalo"),
    '42': i18n.t("message_field.portal_dont_active_or_miss_queue"),
    '44': i18n.t("message_field.error_channel_reply"),
    '403': i18n.t("message_field.forbidden"),
    '-213': i18n.t("message_field.user_has_not_followed_oa")
};

export default function MessageField() {
    const {t} = useTranslation();
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const replyBarRef = useRef();
    const listPreviewFilesRef = useRef();
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const chatConversationSelected = useSelector(selectChatConversationSelected);
    const isCustomer = useSelector(selectIsCustomer);
    const showTemplate = useSelector(selectShowTemplate);
    const client = useSelector(selectStringeeClient);
    const selectedFiles = useSelector(selectSelectedFiles);
    const chatCustomerAccessToken = useSelector(selectChatCustomerAccessToken);
    const publicApiStringee = useSelector(selectPublicApiStringee);

    const AttachFile = BASE_URL + "/assets/images/attach-file.svg";
    const EmojiIcon = BASE_URL + "/assets/images/emoji-icon.svg";
    const MessageTemplate = BASE_URL + "/assets/images/message-template.svg";

    const checkAllowedZaloFile = useCallback((fileExtension, fileSize) => {
        const allowedExtensionZalo = ['jpg', 'png', 'gif', 'pdf', 'doc', 'docx'];

        if (!allowedExtensionZalo.includes(fileExtension)) {
            alert(t('message_field.zalo_support_file_type'));
            return false;
        }

        if (['jpg', 'png'].includes(fileExtension)) {
            if (fileSize > 1024 * 1000) {
                alert(t('message_field.photo_size_max_1mb'));
                return false;
            }
        } else {
            if (fileSize > 5120 * 1000) {
                alert(t('message_field.file_size_max_5mb'));
                return false;
            }
        }

        return true;
    }, [t]);

    const checkAllowedFacebookFile = useCallback((fileName, fileSize) => {
        const isImage = (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(fileName);
        const isVideo = (/\.(mp4|avi|mov|wmv|webm)$/i).test(fileName);

        if (isImage && fileSize > 8192000) {
            alert(t('message_field.photo_size_max_8mb'));
            return false;
        }

        if (fileSize > 20 * 1024 * 1024) {
            alert(t('message_field.file_size_max_20mb'));
            return false;
        }

        if (isVideo && fileSize > 20480000) {
            alert(t('message_field.video_size_max_20mb'));
            return false;
        }

        return true;
    }, [t]);

    const handleSendFile = useCallback(async (file) => {
        let fileId = file.file_id || null;
        const fileName = file.name;
        const fileSize = file.size;
        const fileExt = fileName.split('.').pop().toLowerCase();

        if (chatConversationSelected.channelType === 3) { // zalo
            if (!checkAllowedZaloFile(fileExt, fileSize)) {
                return false;
            }
        }

        if (chatConversationSelected.channelType === 2) { // facebook
            if (!checkAllowedFacebookFile(fileName, fileSize)) {
                return false;
            }
        }

        if (fileSize >= (20 * 1024 * 1024)) {
            alert(t('message_field.format_size_20_incorrect'));
            return false;
        }

        const allExtensions = imgExtensions.concat(videoExtensions, audioExtensions, fileExtensions);
        if (!allExtensions.includes(fileExt)) {
            alert(t('message_field.format_file_incorrect'));
            return false;
        }

        setLoading(true);

        if (!fileId) {
            const formData = new FormData();
            formData.append("file", file);

            const data = await apiStringee.upload(formData);
            console.log('upload file res+++++++', data);

            if (data.r === 0) {
                fileId = data.file_id;
            } else {
                alert(t('message_field.upload_file_error'));
                setLoading(false);
                return false;
            }
        }

        let type = null;
        let message = null;

        if (imgExtensions.includes(fileExt)) {
            type = chatTypeDefine.IMAGE;
            message = {
                content: "",
                metadata: "",
                photo: {
                    file_id: fileId,
                    thumbnail: "", // thumbnail's url
                    ratio: "" // image's ratio
                }
            };
        } else if (videoExtensions.includes(fileExt)) {
            type = chatTypeDefine.VIDEO;
            message = {
                content: "",
                metadata: "",
                video: {
                    filename: fileName,
                    file_id: fileId,
                    thumbnail: "", // thumbnail file's url.
                    ratio: "", // number type
                    format: fileExt,
                    duration: 0 // number type, value in milliseconds.
                }
            };
        } else if (audioExtensions.includes(fileExt)) {
            type = chatTypeDefine.AUDIO;
            message = {
                content: "",
                metadata: "",
                audio: {
                    filename: fileName,
                    file_id: fileId,
                    format: fileExt,
                    duration: 0 // file's length (byte)
                }
            };
        } else if (fileExtensions.includes(fileExt)) {
            type = chatTypeDefine.FILE;
            message = {
                content: "",
                metadata: "",
                file: {
                    filename: fileName,
                    file_id: fileId,
                    format: fileExt,
                    length: fileSize // file's length (byte)
                }
            };
        }

        await chatConversationSelected
            .sendMessage(type, message)
            .then((msg1) => {
                console.log('sent success', msg1);
                setLoading(false);

                if (msg1.id) {
                    dispatch(unshiftItemToConversationMessageList(msg1));
                    dispatch(setScrollToBottomMessageList(true));

                    dispatch(updateChatConvListWhenSendMsg({
                        msgData: msg1,
                        convSelectedId: chatConversationSelected.id
                    }));
                } else {
                    const _messageError = MessageSendFileErrorMap[msg1.r] || msg1.message;
                    alert(_messageError);
                }
            })
            .catch((e) => {
                setLoading(false);
                console.log('sendMessageFile err', e);
            });
    }, [dispatch, chatConversationSelected, t, checkAllowedZaloFile, checkAllowedFacebookFile]);

    function handlePasteInput(e) {
        const imageFiles = [];
        for (const clipboardItem of e.clipboardData.files) {
            if (clipboardItem.type.startsWith('image/')) {
                imageFiles.push(clipboardItem);
            }
        }
        if (imageFiles.length) {
            dispatch(setSelectedFiles([...selectedFiles, ...imageFiles]));
        }
    }
    function onEmojiClick(data) {
        dispatch(concatMsgInput(data.emoji));
    }

    function toggleMsgTemplate() {
        if (!showTemplate) {
            dispatch(setIsLoadingChatTemplate(true));

            apiStringeeX.getListChatTemplate()
                .then((res) => {
                    console.log("+++ getListChatTemplate res", res);

                    if (res.r === 0) {
                        dispatch(setListChatTemplate(res.template.templates));

                        if (res.template.templates && res.template.templates.length) {
                            dispatch(setFocusedIndex(0));
                        }
                    }

                    dispatch(setIsLoadingChatTemplate(false));
                })
                .catch((err) => {
                    console.log("+++ getListChatTemplate error", err);
                    dispatch(setIsLoadingChatTemplate(false));
                });
        }

        dispatch(setShowTemplate(!showTemplate));
    }

    const handleWheelScroll = (e) => {
        if (listPreviewFilesRef.current) {
            listPreviewFilesRef.current.scrollLeft += e.deltaY;
            e.cancelable && e.preventDefault();
        }
    };

    const onFileSelected = useCallback((event) => {
        if (!event.target.files || !event.target.files.length) {
            // alert('Vui lòng chọn file');
            return false;
        }

        const files = event.target.files;
        dispatch(pushSelectedFiles(files));
        return true;
    }, [dispatch]);

    const removeFileSelected = (e, index) => {
        e.stopPropagation();
        dispatch(removeSelectedFileWithIndex(index));
    };

    const onShowFullImage = (imgSrc, fileName) => {
        const postMessageData = {
            action: 'handleShowImage',
            data: {
                src: imgSrc,
                description: fileName
            }
        };

        window.parent.postMessage(postMessageData, "*");
    };

    function getUrlFile(fileId) {
        const url = new URLSearchParams({
            file_id: fileId,
            access_token: chatCustomerAccessToken
        });

        return publicApiStringee + 'v1/file/download?' + url.toString();
    }

    if (loading) {
        return <div style={{textAlign: "center"}}><CircularProgress /></div>;
    }

    const previewSelectedFile = () => {
        if (!selectedFiles || !selectedFiles.length) {
            return null;
        }

        return <div className="list-preview-files" ref={listPreviewFilesRef} onWheel={handleWheelScroll}>
            {selectedFiles.map((file, index) => {
                const fileName = file.name;
                const fileExt = fileName.split('.').pop().toLowerCase();
                const isImage = imgExtensions.includes(fileExt);
                const imgSrc = isImage ?
                    (file.file_id ? getUrlFile(file.file_id) : URL.createObjectURL(file)) : null;

                return <div
                    className={"preview-file__item" + (isImage ? ' is-img' : '')}
                    key={index}
                    onClick={() => onShowFullImage(imgSrc, file.name)}
                >
                    <div className="preview-file__item-remove" onClick={(e) => removeFileSelected(e, index)}>
                        <Tooltip title="Remove Attachment" placement="top">
                            <Clear sx={{fontSize: 13}}/>
                        </Tooltip>
                    </div>
                    <Tooltip title={file.name} placement="top">
                        {isImage ?
                            <img src={imgSrc} alt={file.name}/> :
                            <div className="preview-file__item-content">
                                <div className="preview-file__item-icon">
                                    <Description sx={{fontSize: 30}}/>
                                </div>
                                <div className="preview-file__item-ext">{fileExt}</div>
                            </div>
                        }
                    </Tooltip>
                </div>;
            })}
        </div>;
    };

    const msgFieldBottomChatWidget = () => {
        return <Box className="message-field-bottom">
            <Box className="message-field-bottom__left">
                <Button component="label">
                    <img src={AttachFile} alt=""/>
                    <input type="file" multiple hidden onChange={onFileSelected}/>
                </Button>
                {!client.isInternal && <Button onClick={toggleMsgTemplate}>
                    <img src={MessageTemplate} alt=""/>
                </Button>}
            </Box>
            <Box className="message-field-bottom__right">
                <Link
                    href="#"
                    onMouseEnter={() => setShowEmojiPicker(true)}
                    onMouseLeave={() => setShowEmojiPicker(false)}
                    className="message-emoji"
                >
                    <img src={EmojiIcon} alt=""/>
                    {showEmojiPicker && <span className="message-emoji__picker">
                        <EmojiPicker
                            onEmojiClick={onEmojiClick}
                            lazyLoadEmojis={true}
                        />
                    </span>}
                </Link>
                <Button
                    className="btn-send-msg"
                    variant="contained"
                    color="success"
                    onClick={(e) => replyBarRef.current?.sendMsg(e, false)}
                >{t('message_field.btn_send')}</Button>
            </Box>
        </Box>;
    };

    return <Box className="message-field-wrapper">
        {(!isCustomer && !client.isInternal) && <ChatTemplate />}

        {!isCustomer && previewSelectedFile()}

        <ReplyBar ref={replyBarRef} handleSendFile={handleSendFile} handlePasteInput={handlePasteInput}/>

        {
            isCustomer ?
                <MessageBottomChatBox
                    onFileSelected={onFileSelected}
                    handleSendFile={handleSendFile}
                    onEmojiClick={onEmojiClick}
                /> :
                msgFieldBottomChatWidget()
        }
    </Box>;
}
