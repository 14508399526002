import ApiBase from "./ApiBase";
import axios from "axios";
import ApiError from "./ApiError";
import {API_STRINGEE_COM_BASE_URL} from "../common/config";

class ApiStringee extends ApiBase {
    constructor() {
        super();
        this.baseUrl = API_STRINGEE_COM_BASE_URL;
    }

    setAuthToken(accessToken) {
        this.authToken = accessToken;
    }

    httpRawRequest(uri, method, data) {
        return new Promise((resolve, reject) => {
            axios({
                method,
                baseURL: this.baseUrl,
                url: uri,
                data
            }).then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject(new ApiError(
                    -12,
                    "",
                    err.response ?? err.message
                ));
            });
        });
    }

    upload(formData) {
        console.log("+++++++++ apiUploadChat posting data to API Server");
        return this.httpPost("/v2/file/upload?uploadType=multipart", formData);
    }
}

export const apiStringee = new ApiStringee();
