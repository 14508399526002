import moment from "moment-timezone";
import 'moment/locale/vi';
import i18n from "./i18n";

moment.tz.setDefault('Asia/Ho_Chi_Minh');

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name) {
    if (!name) {
        return null;
    }

    const nameSplit = name.split(' ');
    let children = nameSplit[0][0];

    if (typeof nameSplit[1] !== 'undefined') {
        children += nameSplit[1][0];
    }

    return {
        sx: {
            bgcolor: stringToColor(name)
        },
        children: children
    };
}

export function formatDateTime(timestamp, format = 'DD/MM/YYYY HH:mm:ss') {
    if (timestamp) {
        moment.locale(i18n.language);
        return moment(timestamp).format(format);
    }
}

export function formatDateTimeInConvList(timestamp) {
    if (!timestamp) {
        return null;
    }

    moment.locale(i18n.language);

    const momentTime = moment(timestamp);
    const isToday = momentTime.isSame(new Date(), "day");
    const isYesterday = momentTime.isSame(moment().subtract(1, 'd'), 'day');

    if (isToday) {
        return momentTime.format('HH:mm A');
    } else if (isYesterday) {
        return i18n.t('message.yesterday');
    } else {
        return momentTime.format('L');
    }
}

function stripTags(text) {
    if (typeof text === "undefined") {
        return "";
    }

    return text.replace(/(<([^>]+)>)/ig, "");
}

export function linkify(text) {
    // eslint-disable-next-line
    const urlRegex = /(\b(https?|ftp|file|gopher|telnet|notes|ms-help):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    return stripTags(text).replace(urlRegex, function(url) {
        return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });
}

export function stringify(obj) {
    let cache = [];
    const str = JSON.stringify(obj, function(key, value) {
        if (typeof value === "object" && value !== null) {
            if (cache.indexOf(value) !== -1) {
                // Circular reference found, discard key
                return;
            }
            // Store value in our collection
            cache.push(value);
        }
        return value;
    });
    cache = null; // reset the cache
    return str;
}

export function getChatCreatorData(conv) {
    if (!conv) {
        return null;
    }

    if (!conv.creatorData) {
        if (conv.participants && conv.participants.length) {
            const creatorData = conv.participants.find(
                (el) => (el.user === conv.creator)
            );

            if (creatorData) {
                conv.creatorData = creatorData;

                return creatorData;
            }
        }
    }

    return null;
}

export function cloneObjectWithPrototype(obj) {
    return Object.create(
        Object.getPrototypeOf(obj),
        Object.getOwnPropertyDescriptors(obj)
    );
}

export function isEmpty(obj) {
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) return false;
    }

    return true;
}

export function groupDay(obj, timestamp= 'created', period= 'day') {
    const objPeriod = {};
    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds

    obj.forEach((item) => {
        let d = new Date(item[timestamp]);

        if (period === "day") {
            d = Math.floor(d.getTime() / oneDay);
        } else if (period === "week") {
            d = Math.floor(d.getTime() / (oneDay * 7));
        } else if (period === "month") {
            d = (d.getFullYear() - 1970) * 12 + d.getMonth();
        } else if (period === "year") {
            d = d.getFullYear();
        } else {
            console.log(
                "groupByTimePeriod: You have to set a period! day | week | month | year"
            );
        }

        // define object key
        if (!objPeriod[d]) {
            objPeriod[d]= [];
        }
        objPeriod[d].push(item);
    });

    return objPeriod;
}

export const validateEmail = (email) => {
    return String(email).toLowerCase().match(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhone = (phone) => {
    const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
    return String(phone).match(regexPhoneNumber);
};

export const cloneObject = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

export function setChatStorage(setItem, setValue) {
    return localStorage.setItem("stringeeChat" + setItem, setValue);
}

export function removeChatStorage(setItem) {
    return localStorage.removeItem("stringeeChat" + setItem);
}

export function getChatStorage(setItem) {
    return localStorage.getItem("stringeeChat" + setItem);
}

export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function replaceTemplateVariables(template, data, contextCF) {
    // eslint-disable-next-line max-len
    return template.replace(/{{\s*([^}\s]+)\s*}}/g, (match, p1) => {
        if (p1) {
            const arr = p1.split('.');

            if (typeof arr[0] !== 'undefined' && arr[0] === 'contact') {
                if (typeof arr[1] !== 'undefined') {
                    if (contextCF[arr[1]] &&
                        (contextCF[arr[1]].field_type === 'drop_down' ||
                            contextCF[arr[1]].field_type === 'multi_select') &&
                        ((parseInt(arr[2]) >= 0 && parseInt(arr[2]) <= contextCF[arr[1]].options.length) ||
                            arr[2] === 'all')
                    ) {
                        if (arr[2] === 'all') {
                            let strAllValue = '';
                            contextCF[arr[1]].options.forEach((element, index) => {
                                strAllValue += "\n  " + (index + 1) + ". " + element.value;
                            });

                            return strAllValue;
                        } else {
                            return contextCF[arr[1]].options[parseInt(arr[2]) - 1].value;
                        }
                    } else {
                        const propertyValue = data[arr[1]];
                        return typeof propertyValue !== 'undefined' ? propertyValue : match;
                    }
                }
            }
        }

        return match;
    });
}

export function checkBusinessHour(startTime, endTime) {
    console.log("===> checkBusinessHour", startTime, endTime);

    const currentDate = new Date();
    if (startTime && endTime) {
        const startDate = new Date(currentDate.getTime());
        startDate.setHours(startTime.split(":")[0]);
        startDate.setMinutes(startTime.split(":")[1]);

        const endDate = new Date(currentDate.getTime());
        endDate.setHours(endTime.split(":")[0]);
        endDate.setMinutes(endTime.split(":")[1]);

        const valid = startDate <= currentDate && endDate >= currentDate;
        console.log("===> checkBusinessHour res: ", valid);
        return valid;
    } else {
        return false;
    }
}

export function buildTreeCustomField(list) {
    const map = {};
    const roots = [];
    let node;
    let i;

    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i;
        list[i].children = [];
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        node.title = node.value;
        node.value = node.id;

        if (node.custom_field_option_parent) {
            list[map[node.custom_field_option_parent]].children.push(node);
        } else {
            roots.push(node);
        }
    }

    return roots;
}

