import {useState, useEffect, useCallback, useRef, useMemo} from "react";
import ConversationList from "../../common_components/chats/ConversationList";
import ContentConversation from "./../../common_components/chats/ContentConversation";
import {useDispatch, useSelector} from "react-redux";
import {
    selectChatConversationList,
    setChatConversationList, setIsLoadingConvList,
    unshiftItemToChatConversationList
} from "../../store/chatConversationList";
import {
    selectChatConversationSelected, selectChatConversationSelectedInfo,
    setConversationSelected,
    setIsReloadConvContent, setIsUpdateLastMsgInConvList
} from "../../store/chatConversationSelected";
import {Container, Divider, Grid} from "@mui/material";
import {selectAuthUser, setAuthUser} from "../../store/chatAuthUser";
import {selectStringeeClient, setStringeeClient} from "../../store/chatStringeeClient";
import {
    selectDisplayMode,
    selectIsLoading,
    setChatCustomerAccessToken, setDisplayMode,
    setPublicApiStringee
} from "../../store/chatGeneral";
import {API_STRINGEE_COM_BASE_URL, STRINGEE_SERVER_ADDRS} from "../../common/config";
import {CHAT_CUSTOMER_WIDGET_KEY} from "../../common/sample";
import {apiStringeeX} from "../../api/ApiStringeeX";
import {stringify} from "../../common/common";
import {selectConnected, setConnected} from "../../store/chatbox";
import ChatEventHandle from "./ChatEventHandle";
import i18n from "../../common/i18n";
import LoadingOverlay from "../../common_components/chats/chat_panel/LoadingOverlay";
import {setIsReloadContactData} from "../../store/chatTemplate";
// import {StringeeClient, StringeeChat2, StringeeServiceType} from "stringee";

export default function Chat() {
    const [filter, setFilter] = useState({});
    const [hasMore, setHasMore] = useState(true);

    const convSelectedRef = useRef(null);
    const convSelectedInfoRef = useRef(null);
    const authUserRef = useRef({});

    const dispatch = useDispatch();
    const client = useSelector(selectStringeeClient);
    const authUser = useSelector(selectAuthUser);
    const connected = useSelector(selectConnected);
    const chatConversationSelected = useSelector(selectChatConversationSelected);
    const chatConversationSelectedInfo = useSelector(selectChatConversationSelectedInfo);
    const chatConversationList = useSelector(selectChatConversationList);
    const isLoading = useSelector(selectIsLoading);
    const displayMode = useSelector(selectDisplayMode);

    const isInboxLayout = useMemo(() => (displayMode === 'full'), [displayMode]);

    const StringeeChatWidget = window.parent.StringeeChatWidget || {};
    const StringeeClient = window.StringeeClient;
    const StringeeChat2 = window.StringeeChat2;
    const StringeeServiceType = window.StringeeServiceType;

    useEffect(() => {
        convSelectedRef.current = chatConversationSelected;
    }, [chatConversationSelected]);

    useEffect(() => {
        convSelectedInfoRef.current = chatConversationSelectedInfo;
    }, [chatConversationSelectedInfo]);

    useEffect(() => {
        authUserRef.current = authUser;
    }, [authUser]);

    useEffect(() => {
        dispatch(setDisplayMode(window.displayMode || 'full'));
    }, []);

    useEffect(() => {
        const unselectConversation = (e) => {
            if (isInboxLayout && e.key === 'Escape' && chatConversationSelected) {
                dispatch(setConversationSelected(null));
                const postMessageData = {
                    action: 'stringeeChatSelectedConversation',
                    data: null
                };
                window.parent.postMessage(postMessageData, "*");
            }
        };
        document.addEventListener('keydown', unselectConversation, true);
        return () => {
            document.removeEventListener('keydown', unselectConversation, true);
        };
    }, [dispatch, chatConversationSelected, isInboxLayout]);


    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (StringeeChatWidget.client) {
            console.log('++++++ StringeeClient được truyền vào', StringeeChatWidget.client);
            dispatch(setStringeeClient(StringeeChatWidget.client));
        } else {
            console.log('++++++ tự sinh StringeeClient');

            const client = new StringeeClient(STRINGEE_SERVER_ADDRS, API_STRINGEE_COM_BASE_URL);
            client.isInternal = true;

            dispatch(setStringeeClient(client));
        }

        if (StringeeChatWidget.language) {
            i18n.changeLanguage(StringeeChatWidget.language);
        }

        // fix tạm lỗi StringeeServiceType trên sdk
        window.StringeeServiceType = StringeeServiceType;
    }, []);
    /* eslint-enable react-hooks/exhaustive-deps */

    useEffect(() => {
        if (client) {
            if (client.isInternal) {
                client.connect(StringeeChatWidget.accessToken);

                client.on("connect", () => {
                    console.log("++++++++++++++ connected");
                });
                client.on("disconnect", () => {
                    dispatch(setConnected(false));
                    console.log("++++++++++++++ disconnected");
                });

                client.on('authen', function(res) {
                    dispatch(setConnected(true));
                    dispatch(setAuthUser(res));
                    console.log('authen', res);

                    if (window.StringeeChatWidget) {
                        StringeeChatWidget._callOnEvent('StringeeChatAuthen', res);
                    }
                });
            } else {
                dispatch(setAuthUser(StringeeChatWidget.accountInfo));
                dispatch(setConnected(!!client.hasConnected));
                apiStringeeX.setAuthToken(StringeeChatWidget._XStringeeAuth);
            }
        }
    }, [dispatch, client]);

    useEffect(() => {
        if (chatConversationList.length < 10) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    }, [chatConversationList]);

    const showListConversations = useCallback(async (postBody = {}) => {
        dispatch(setIsLoadingConvList(true));
        setFilter(postBody);

        const listConvElement = document.querySelector('#scrollableListConv');
        if (listConvElement) {
            listConvElement.scrollTop = 0;
        }

        let conversations;
        if (postBody.customerId) {
            const params = {
                customerId: postBody.customerId,
                limit: 10,
                lastUpdateGreater: 0,
                lastUpdateSmaller: Number.MAX_SAFE_INTEGER
            };
            conversations = await StringeeChat2
                .getLiveChatConversationsForCustomer(client, params);
        } else if (client.isInternal) {
            const lastUpdateGreater = 0;
            const lastUpdateSmaller = Number.MAX_SAFE_INTEGER;
            conversations = await StringeeChat2.getListConversations(
                client,
                lastUpdateGreater,
                lastUpdateSmaller,
                10
            );
        } else {
            conversations = await StringeeChat2.getLiveChatConversations(client, postBody);
        }

        console.log('getListConversations', conversations);
        dispatch(setChatConversationList(conversations));
        dispatch(setIsLoadingConvList(false));
    }, [client, dispatch, StringeeChat2]);

    const settingsChat = useCallback(() => {
        if (client) {
            if (client.isInternal) {
                console.log("StringeeChat2 init ++++++++++++++++");
                StringeeChat2.init(client);
            }

            if (displayMode === 'full' || displayMode === 'list') {
                showListConversations();
            }
        }
    }, [client, showListConversations, StringeeChat2, displayMode]);

    useEffect(() => {
        if (connected) {
            settingsChat();
        }
    }, [connected, settingsChat]);

    useEffect(() => {
        if (authUser && (authUser.id || authUser.userId)) {
            apiStringeeX.getChatCustomerData({
                key: StringeeChatWidget.widgetKey || CHAT_CUSTOMER_WIDGET_KEY,
                userId: authUser.id || authUser.userId,
                username: authUser.displayName || authUser.name
            })
                .then(function(data) {
                    console.log("+++ getTokenRestApi res", data);
                    if (data.r === 0) {
                        dispatch(setChatCustomerAccessToken(data.access_token));
                        dispatch(setPublicApiStringee(data.public_api_stringee));
                    }
                })
                .catch((err) => {
                    console.log("+++ getTokenRestApi error", err);
                });
        }
    }, [authUser, dispatch]);

    const onMessageReceivedFromIframe = useCallback((event) => {
        // Checking if the message is from an allowed source
        if (event.origin !== window.location.origin) return;

        const postMessageData = event.data;

        if (typeof event.data !== "object") {
            return false;
        }

        if (typeof postMessageData.action !== 'undefined') {
            if (postMessageData.action === 'handleTransferChat') {
                const toUserId = postMessageData.data;
                const creatorData = convSelectedRef.current.creatorData;
                console.log('handleTransferChat', postMessageData.data, convSelectedRef.current, creatorData);
                convSelectedRef.current.transfer(toUserId, {
                    customerId: creatorData.user,
                    customerName: creatorData.name
                }).then((res) => {
                    console.log("transferChat res", res);

                    if (res && res.r === 0) {
                        dispatch(setIsReloadConvContent(true));
                    }

                    window.parent.postMessage({
                        action: "resTransferChat",
                        data: res
                    }, "*");
                });
            } else if (postMessageData.action === 'handleEmailChat') {
                const data = postMessageData.data;

                if (typeof data !== 'object' || !data.email || !data.domain) {
                    console.log("sendEmailChat err: Missing params");
                    return false;
                }

                convSelectedRef.current.sendChatTranscriptTo(data.email, data.domain).then((res) => {
                    console.log("sendEmailChat res", res);

                    window.parent.postMessage({
                        action: "resEmailChat",
                        data: res
                    }, "*");
                });
            } else if (postMessageData.action === 'handleJoinChat') {
                console.log('handleJoinChat++++', (authUserRef.current.id || authUserRef.current.userId));
                convSelectedRef.current.addParticipants([(authUserRef.current.id || authUserRef.current.userId)])
                    .then((res) => {
                        console.log("handleJoinChat++++ res: ", res);

                        window.parent.postMessage({
                            action: "resJoinChat",
                            data: res
                        }, "*");

                        if (res.r === 0) {
                            dispatch(setIsReloadConvContent(true));
                        }
                    })
                    .catch((err) => {
                        console.log("handleJoinChat++++ err: ", err);
                        window.parent.postMessage({
                            action: "resJoinChat",
                            data: err
                        }, "*");
                    });
            } else if (postMessageData.action === 'handleEndChat') {
                console.log('handleEndChat++++');
                convSelectedRef.current.endChat().then((res) => {
                    console.log('end chat res: ', res);

                    if (res.r === 0) {
                        dispatch(setIsUpdateLastMsgInConvList(true));
                        dispatch(setIsReloadConvContent(true));
                    }

                    window.parent.postMessage({
                        action: "resEndChat",
                        data: res
                    }, "*");
                });
            } else if (postMessageData.action === 'handleContinueChat') {
                const chatClient = window.parent.StringeeChatWidget.client;

                if (convSelectedInfoRef.current && chatClient) {
                    StringeeChat2.createLiveChatConversation(
                        chatClient,
                        convSelectedInfoRef.current.name,
                        null,
                        {
                            oaId: convSelectedInfoRef.current.oaId,
                            channelType: convSelectedInfoRef.current.channelType,
                            creatorId: convSelectedInfoRef.current.creator,
                            localDbId: convSelectedInfoRef.current.localDbId
                        }
                    ).then(async (res) => {
                        console.log("+++++++++++handleContinueChat res", res);

                        window.parent.postMessage({
                            action: "resContinueChat",
                            data: JSON.parse(stringify(res))
                        }, "*");

                        if (res.id) {
                            dispatch(unshiftItemToChatConversationList(res));
                            dispatch(setConversationSelected(res));

                            // send custom message AGENT_CONTINUE_CHAT
                            const toUser = convSelectedInfoRef.current.creator;
                            const message = ({
                                action: 'AGENT_CONTINUE_CHAT',
                                convId: res.id
                            });
                            const postBody = {
                                toUser,
                                message
                            };
                            const resSendCustomMessage = await chatClient.sendCustomMessage2(postBody);
                            console.log("sendCustomMessage res++++++", resSendCustomMessage);
                        }
                    }).catch((e) => {
                        console.log("+++++++++++handleContinueChat err", e);

                        window.parent.postMessage({
                            action: "resContinueChat",
                            data: e
                        }, "*");
                    });
                }
            }
            if (postMessageData.action === 'onChangeFilter') {
                showListConversations(postMessageData.postBody);
            }
            if (postMessageData.action === 'onSelectConversation') {
                const convId = postMessageData.data;

                if (convSelectedRef.current && convId === convSelectedRef.current.id) {
                    dispatch(setIsReloadConvContent(true));
                }

                StringeeChat2.getLiveChatConversationsInfo(client, [convId])
                    .then((res) => {
                        if (res.r === 0 && res.listConvs && res.listConvs.length) {
                            const conversationInfo = res.listConvs[0];
                            dispatch(setConversationSelected(conversationInfo));
                            if (!chatConversationList.find((el) => el.id === convId)) {
                                dispatch(unshiftItemToChatConversationList(conversationInfo));
                            }
                        }
                    });
            }

            if (postMessageData.action === 'unselectConversation') {
                dispatch(setConversationSelected(null));
            }

            if (postMessageData.action === 'onUpdateContact') {
                dispatch(setIsReloadContactData(true));
            }
        }
    }, [StringeeChat2, dispatch, showListConversations, client]);

    useEffect(() => {
        window.addEventListener("message", onMessageReceivedFromIframe);
        return () =>
            window.removeEventListener("message", onMessageReceivedFromIframe);
    }, [onMessageReceivedFromIframe]);

    return (
        <Container maxWidth={false} className="stringee-chat__container">
            <Grid container spacing={3} className="stringee-chat__grid">
                {
                    (displayMode === 'full' || displayMode === 'list') &&
                    <Grid item className="list-conversation-grid" xs={displayMode === 'list'}>
                        <ConversationList
                            filter={filter}
                            setFilter={setFilter}
                            hasMore={hasMore}
                            setHasMore={setHasMore}
                        />
                    </Grid>
                }

                {
                    displayMode === 'full' &&
                    <Divider orientation="vertical" flexItem/>
                }

                {
                    (displayMode === 'full' || displayMode === 'chat-box') &&
                    <Grid item xs>
                        <ContentConversation/>
                    </Grid>
                }
            </Grid>
            <ChatEventHandle/>
            {isLoading && <LoadingOverlay/>}
        </Container>
    );
}
